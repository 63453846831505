<template>
  <v-card>
    <v-card-text class="pa-0">
      <v-tabs v-model="tabTono">
        <v-tab
          key="tonos-servicio"
        >
          Tonos Servicio
        </v-tab>
        <v-tab key="tonos-compannias">
          Tonos Compañías
        </v-tab>
        <v-tab key="tonos-global">
          Global Tonos
        </v-tab>
        <v-tab key="biblioteca-tonos">
          Biblioteca Tonos
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tabTono">
        <v-tab-item key="tonos-servicio">
          <TonosServicioTab />
        </v-tab-item>
        <v-tab-item key="tonos-compannia">
          <TonosCompanniaTab />
        </v-tab-item>
        <v-tab-item key="tonos-global">
          <TonosGlobalTab />
        </v-tab-item>
        <v-tab-item key="biblioteca-tonos">
          <BibliotecaTonosTab />
        </v-tab-item>
      </v-tabs-items>
    </v-card-text>
  </v-card>
</template>

<script>
import BibliotecaTonosTab from '@/components/AdminCuerpo/TonosTab/BibliotecaTonosTab'
import TonosServicioTab from '@/components/AdminCuerpo/TonosTab/TonosServicioTab'
import TonosCompanniaTab from '@/components/AdminCuerpo/TonosTab/TonosCompanniaTab'
import TonosGlobalTab from '@/components/AdminCuerpo/TonosTab/TonosGlobalTab'
export default {
  name: 'TonosTab',
  components: {
    TonosGlobalTab,
    TonosServicioTab,
    BibliotecaTonosTab,
    TonosCompanniaTab
  },
  data () {
    return {
      tabTono: null
    }
  },
  watch: {
    tabTono (val) {
      this.$router.replace(
        {
          query: Object.assign({ ...this.$route.query }, { tabTono: val })
        },
        () => {}
      )
    }
  },
  mounted () {
    const tabTono = this.$route.query.tabTono
    if (tabTono && parseInt(tabTono) < 3) {
      this.tabTono = parseInt(tabTono)
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <v-row>
    <v-col
      cols="12"
      class="pb-0"
    >
      <GmapMap
        ref="gmap"
        :zoom="map.zoom"
        :center="{ lat: despachoLocalLat, lng: despachoLocalLng}"
        style="width: inherit; height: 350px"
        @tilesloaded="map.tilesLoaded = true"
        @zoom_changed="map.zoom=$event"
      >
        <div v-if="layers.compannias">
          <GmapMarkerCia
            v-for="(compannia, index) in compannias"
            :key="`compannia-${index}`"
            :compannia="compannia"
          />
        </div>
        <div v-if="layers.vehiculos">
          <GmapMarkerVehiculo
            v-for="(vehiculo, index) in vehiculosSeleccionados"
            :key="`vehiculo-${index}`"
            :vehiculo="vehiculo"
          />
        </div>
        <GmapLayerGrifos
          v-if="$refs.gmap"
          ref="gmapLayerGrifos"
          :render="layers.grifos && map.zoom >= map.zoomGrifos"
          :grifos="grifos"
          :map="$refs.gmap"
        />
        <GmapMarker
          :position="{ lat: despachoLocalLat, lng: despachoLocalLng}"
          :draggable="true"
          @update:position="setMarker($event)"
        />
      </GmapMap>
    </v-col>
    <v-col
      cols="12"
      class="py-0"
    >
      <v-row justify="space-around">
        <v-col cols="auto">
          <v-checkbox
            v-model="layers.compannias"
            label="Compañías"
            class="my-0"
            color="white"
          />
        </v-col>
        <v-col cols="auto">
          <v-checkbox
            v-model="layers.vehiculos"
            label="Vehículos"
            class="my-0"
            color="red"
          />
        </v-col>
        <v-col cols="auto">
          <v-tooltip bottom>
            <template #activator="{ on }">
              <span v-on="on">
                <v-checkbox
                  v-model="layers.grifos"
                  :disabled="map.zoom < map.zoomGrifos"
                  label="Grifos"
                  class="my-0"
                  color="yellow"
                />
              </span>
            </template>
            Los grifos se habilitan cuando se tiene un zoom igual o mayor a {{ map.zoomGrifos }}, actual: {{ map.zoom }}
          </v-tooltip>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import GmapLayerGrifos from '@/components/gmaps/GmapLayerGrifos'
import GmapMarkerCia from '@/components/gmaps/GmapMarkerCia'
import GmapMarkerVehiculo from '@/components/gmaps/GmapMarkerVehiculo'
import { getGoogleMapsAPI } from 'gmap-vue'

export default {
  name: 'MapSection',
  components: {
    GmapLayerGrifos,
    GmapMarkerCia,
    GmapMarkerVehiculo
  },
  props: {
    despachoLocalLat: {
      type: Number,
      default: -33.436260
    },
    despachoLocalLng: {
      type: Number,
      default: -70.643320
    },
    grifos: {
      type: Array,
      default: () => []
    },
    vehiculosSeleccionados: {
      type: Array,
      default: () => []
    },
    compannias: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      map: {
        zoom: 13,
        zoomGrifos: 10,
        tilesLoaded: false
      },
      layers: {
        grifos: false,
        compannias: true,
        vehiculos: true
      }
    }
  },
  computed: {
    google: getGoogleMapsAPI
  },
  mounted () {
    this.addTrafficLayer()
  },
  methods: {
    addTrafficLayer () {
      const map = this.$refs.gmap
      map.$mapPromise.then((map) => {
        const trafficLayer = new this.google.maps.TrafficLayer()
        trafficLayer.setMap(map)
      })
    },
    setMarker (marker) {
      this.$emit('set-marker', marker)
    }
  }
}
</script>

<style scoped>

</style>

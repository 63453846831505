import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import gmapVue from '@/plugins/gmapVue'
import axios from '@/plugins/axios'
import '@/assets/scss/custom.scss'
import NumberField from '@/components/global/NumberField'
import VueMask from 'v-mask'

Vue.prototype.$axios = axios

Vue.component('GNumberField', NumberField)

router.beforeEach((to, from, next) => {
  if (to.meta.authRequired && !store.getters['main/conPerfil']) {
    next({ path: '/login' })
  }
  store.commit('main/setTitle', to.meta.title)
  next()
})
Vue.use(VueMask)

new Vue({
  router,
  store,
  vuetify,
  gmapVue,
  render: h => h(App)
}).$mount('#app')

<template>
  <div id="app">
    <v-app dark>
      <router-view />
    </v-app>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { createEcho } from '@/plugins/laravelEcho'

export default {
  name: 'App',
  computed: {
    ...mapGetters('main', ['conPerfil']),
    ...mapState('main', ['darkTheme', 'token'])
  },
  async mounted () {
    this.setTheme()
    if (this.conPerfil) {
      await this.syncPerfil()
      this.syncEmergenciasInfo()
      this.createEcho()
      this.initMainWebSockets()
      this.initEmergenciasWebSockets()
    }
  },
  methods: {
    createEcho () {
      createEcho(this.token)
    },
    async syncPerfil () {
      await this.$store.dispatch('main/syncPerfil')
    },
    syncEmergenciasInfo () {
      this.$store.dispatch('emergencias/getInitInfo', true)
    },
    initMainWebSockets () {
      this.$store.dispatch('main/initWebSockets')
    },
    initEmergenciasWebSockets () {
      this.$store.dispatch('emergencias/initWebSockets')
    },
    setTheme () {
      this.$vuetify.theme.dark = this.darkTheme
    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>

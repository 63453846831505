<template>
  <div v-if="render">
    <GmapInfoWindow
      v-if="menuInfo.open"
      :options="menuInfo.options"
      :position="menuInfo.position"
      :opened="menuInfo.open"
      :content="menuInfo.content"
      @closeclick="menuInfo.open=false"
    />
  </div>
</template>

<script>

import { getGoogleMapsAPI } from 'gmap-vue'
import { mdiFireHydrant } from '@mdi/js'

export default {
  name: 'GmapLayerGrifos',
  props: {
    grifos: {
      type: Array,
      default: () => {}
    },
    render: {
      type: Boolean,
      default: false
    },
    map: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      grifosMarkers: [],
      menuInfo: {
        open: false,
        grifoId: 0,
        position: {
          lat: 0,
          lng: 0
        },
        content: '',
        options: {
          pixelOffset: {
            width: 0,
            height: 0
          }
        }
      },
      grifoMarkerIcon: {
        path: mdiFireHydrant,
        scale: 1,
        strokeWeight: 1,
        strokeColor: 'black',
        strokeOpacity: 1,
        fillColor: 'yellow',
        fillOpacity: 1,
        labelOrigin: {
          x: 12,
          y: 12
        }
      }
    }
  },
  computed: {
    google: getGoogleMapsAPI
  },
  watch: {
    grifos (val) {
      this.grifosMarkers = val.map(grifo => {
        const marker = new this.google.maps.Marker({
          position: {
            lat: grifo.lat,
            lng: grifo.lng
          },
          map: null,
          title: grifo.ubicacion,
          optimized: true,
          icon: this.grifoMarkerIcon
        })
        marker.addListener('click', () => this.showInfo(grifo))
        return {
          ...grifo,
          marker
        }
      })
      this.boundsChanged()
    },
    render (val) {
      if (val) {
        this.boundsChanged()
      } else {
        this.grifosMarkers.forEach((grifo) => {
          if (grifo.marker.getMap()) {
            grifo.marker.setMap(null)
          }
        })
      }
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.map.$mapPromise.then((map) => {
        if (this.grifos && this.grifos.length > 0) {
          this.grifosMarkers = this.grifos.map(grifo => {
            const marker = new this.google.maps.Marker({
              position: {
                lat: grifo.lat,
                lng: grifo.lng
              },
              map: null,
              title: grifo.ubicacion,
              optimized: true,
              icon: this.grifoMarkerIcon
            })
            marker.addListener('click', () => this.showInfo(grifo))
            return {
              ...grifo,
              marker
            }
          })
          this.boundsChanged()
        }
        this.boundsChanged()
        map.addListener('bounds_changed', () => this.boundsChanged())
      })
    })
  },
  methods: {
    showInfo (grifo) {
      const { lat, lng } = grifo
      this.menuInfo.position = { lat, lng }
      this.menuInfo.content = `<ul class="black--text">
        <li>Id: ${grifo.grifoId}</li>
        <li>Ubicación: ${grifo.ubicacion}</li>
        <li>Año: ${grifo.anio}</li>
        <li>Modelo: ${grifo.modelo}</li>
        <li>Diámetro Tubo: ${grifo.diamTub}</li>
        <li>Diámetro Grifo: ${grifo.diamGrifo}</li>`
      if (grifo.grifoId === this.menuInfo.grifoId) {
        this.menuInfo.open = !this.menuInfo.open
      } else {
        if (this.menuInfo.open) this.menuInfo.open = false
        this.menuInfo.open = true
        this.menuInfo.grifoId = grifo.grifoId
      }
      this.menuInfo.open = true
    },
    boundsChanged () {
      if (this.render) {
        const map = this.map.$mapObject
        const bounds = map.getBounds()
        if (!bounds) return
        const northEast = bounds.getNorthEast()
        const southWest = bounds.getSouthWest()
        const diffLat = northEast.lat() - southWest.lat()
        const diffLng = northEast.lng() - southWest.lng()
        const minLat = southWest.lat() - (diffLat * 0.3)
        const maxLat = northEast.lat() + (diffLat * 0.3)
        const minLng = southWest.lng() - (diffLng * 0.3)
        const maxLng = northEast.lng() + (diffLng * 0.3)
        this.grifosMarkers.forEach((grifo) => {
          const lat = grifo.lat
          const lng = grifo.lng
          if (minLat < lat && lat < maxLat &&
            minLng < lng && lng < maxLng) {
            if (!grifo.marker.getMap()) {
              grifo.marker.setMap(map)
            }
          } else if (grifo.marker.getMap()) {
            grifo.marker.setMap(null)
          }
        })
      }
    }
  }
}
</script>

<style scoped>

</style>


export function playSounds (sounds) {
  const audioCtx = new (window.AudioContext || window.webkitAudioContext)()
  if (sounds.length === 0) return
  const oscillator = audioCtx.createOscillator()
  const gainNode = audioCtx.createGain()

  oscillator.connect(gainNode)
  gainNode.connect(audioCtx.destination)

  const setSound = (gainNode, oscillator, soundIndex, sounds) => {
    const { volume, frequency, type, duration } = sounds[soundIndex]
    gainNode.gain.value = volume / 100
    oscillator.frequency.value = frequency
    oscillator.type = type
    if (soundIndex === 0) {
      oscillator.start()
    }
    if (soundIndex < sounds.length - 1) {
      setTimeout(() => {
        setSound(gainNode, oscillator, soundIndex + 1, sounds)
      }, duration)
    } else {
      setTimeout(() => {
        oscillator.stop()
      }, duration)
    }
  }

  setTimeout(() => {
    setSound(gainNode, oscillator, 0, sounds)
  }, 400)
}

export function playSound ({ volume, frequency, duration, type }) {
  const audioCtx = new (window.AudioContext || window.webkitAudioContext)()
  const oscillator = audioCtx.createOscillator()
  const gainNode = audioCtx.createGain()

  oscillator.connect(gainNode)
  gainNode.connect(audioCtx.destination)

  gainNode.gain.value = volume / 100
  oscillator.frequency.value = frequency
  oscillator.type = type

  oscillator.start()
  setTimeout(() => {
    oscillator.stop()
    audioCtx.close()
  }, duration)
}

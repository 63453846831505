import Vue from 'vue'
import Vuex from 'vuex'
import main from './main'
import emergencias from './emergencias'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    main,
    emergencias
  }
})

<template>
  <v-card
    :elevation="elevation"
    :height="height"
    :loading="progressShowMessage"
    :min-height="minHeight"
  >
    <template #progress>
      <v-progress-linear
        v-model="messageProgressPercentage"
      />
    </template>
    <div class="container-overlay">
      <div
        v-show="!showMessage"
        class="content"
        style="max-width: calc(99.9vw - 1rem)"
      >
        <slot name="top" />
        <v-card-subtitle
          v-if="hasErrors || !!message"
          class="mt-n4"
        >
          <v-container class="mx-0">
            <v-row v-if="!!message">
              <v-col class="py-0">
                <h4>
                  {{ message }}
                </h4>
              </v-col>
            </v-row>
            <v-row v-if="hasErrors">
              <v-col class="py-0">
                <CardErrors :errors="errors" />
              </v-col>
            </v-row>
          </v-container>
        </v-card-subtitle>
        <slot name="bottom" />
      </div>
      <v-container
        v-if="showMessage"
        class="content"
      >
        <v-col
          cols="12"
          class="my-10"
        >
          <v-row class="justify-center ma-0 align-content-center">
            <v-icon
              :color="wasSuccessful ? 'success': 'alert'"
              large
            >
              {{ wasSuccessful ? mdiCheckboxMarkedCircleOutline : mdiAlertOutline }}
            </v-icon>
          </v-row>
          <v-row class="justify-center ma-0">
            <v-col>
              <div class="success--text text-subtitle-1 text-center">
                {{ message }}
              </div>
            </v-col>
          </v-row>
          <v-row class="justify-center ma-0">
            <v-btn
              color="primary"
              @click="hideMessage()"
            >
              <v-icon>
                {{ mdiCheck }}
              </v-icon>
              Listo
            </v-btn>
          </v-row>
        </v-col>
      </v-container>
      <CardSpinner
        v-if="isLoading"
        class="overlay"
        style="padding: inherit"
      />
    </div>
  </v-card>
</template>

<script>
import CardSpinner from './CardSpinner'
import CardErrors from './CardErrors'
import { mdiCheckboxMarkedCircleOutline, mdiAlertOutline, mdiCheck } from '@mdi/js'
export default {
  name: 'CardFormHelper',
  components: {
    CardSpinner,
    CardErrors
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false
    },
    showMessage: {
      type: Boolean,
      default: false
    },
    errors: {
      type: [Array, Object],
      default () {
        return [[]]
      }
    },
    message: {
      type: String,
      default: ''
    },
    wasSuccessful: {
      type: Boolean,
      default: false
    },
    height: {
      type: String,
      default: 'auto'
    },
    minHeight: {
      type: String,
      default: 'undefined'
    },
    elevation: {
      type: String,
      default: '0'
    },
    messageTimer: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      mdiCheckboxMarkedCircleOutline,
      mdiAlertOutline,
      mdiCheck,
      messageProgressPercentage: 0
    }
  },
  computed: {
    hasErrors () {
      if (this.errors instanceof Array && this.errors.length > 0) {
        for (let i = 0; i < this.errors.length; i++) {
          if (this.errors[i] instanceof Array && this.errors[i].length > 0) return true
        }
        return false
      } else if (this.errors instanceof Object && Object.entries(this.errors).length > 0) {
        return true
      }
      return false
    },
    progressShowMessage () {
      return this.showMessage && this.messageTimer > 0
    }
  },
  watch: {
    showMessage (val) {
      if (val && this.messageTimer > 0) {
        this.startProgressTimer()
      }
    }
  },
  methods: {
    startProgressTimer () {
      const timeSlice = this.messageTimer / 20
      const intervalId = setInterval(() => {
        if (this.messageProgressPercentage > 100) {
          clearInterval(intervalId)
          this.hideMessage()
          this.messageProgressPercentage = 0
        } else {
          this.messageProgressPercentage += 5
        }
      }, timeSlice)
    },
    hideMessage () {
      this.$emit('update:show-message', false)
      this.$emit('click-ready')
    }
  }
}
</script>

<style scoped>
</style>

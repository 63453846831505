import baseAxios from 'axios'
import store from '../store'

const axios = baseAxios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: '0'
  }
})

axios.interceptors.request.use(function (config) {
  const token = store.state.main.token
  // const sistema = config.url.split('/')[2];
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
    return config
  } else {
    return config
  }
}, function (error) {
  return Promise.reject(error)
})

axios.interceptors.response.use((response) => {
  return response
}, (error) => {
  if (error.response) {
    if (!Object.prototype.hasOwnProperty.call(error.response.data, 'message')) {
      error.response.data.message = 'Error en el servidor no identificado'
    }
    if (!Object.prototype.hasOwnProperty.call(error.response.data, 'errors')) {
      error.response.data.errors = [['Error en el servidor no identificado']]
    }
    if (error.response.status === 401) {
      store.dispatch('main/destroySession')
    }
  } else if (error.request) {
    error.message = 'Sin respuesta del servidor'
    error.errors = [['Sin respuesta del servidor']]
  } else {
    error.message = 'Error del cliente, comuniquese con el administrador'
    error.errors = [['Error del cliente, comuniquese con el administrador']]
  }
  return Promise.reject(error)
})

export default axios

<template>
  <v-dialog
    :value="value"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-card min-height="600px">
      <v-card-title>
        Preguntas
        <v-spacer />
        <v-btn
          icon
          @click="$emit('input', false)"
        >
          <v-icon>
            {{ icons.mdiClose }}
          </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="6">
            <v-row>
              <v-autocomplete
                v-model="tipoServicioCuerpoIdLocal"
                label="Código Acto Servicio"
                :items="tiposServicioCuerpo"
                item-value="tipoServicioCuerpoId"
                :item-text="(item) => `${item.codigo} - ${item.descripcion}`"
                :rules="[(v) => !!v || 'Requerido']"
              >
                <template #append-outer>
                  <v-tooltip
                    top
                  >
                    <template #activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        :disabled="!tipoActoServicioCuerpoChanged"
                        v-on="on"
                        @click="$emit('update-despacho-tipo-servicio')"
                      >
                        <v-icon>
                          {{ icons.mdiCheck }}
                        </v-icon>
                      </v-btn>
                    </template>
                    Confirma modificacion de información y sincroniza con servidor
                  </v-tooltip>
                  <v-tooltip
                    top
                  >
                    <template #activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        :disabled="!tipoActoServicioCuerpoChanged"
                        v-on="on"
                        @click="$emit('revert-change-tipo-servicio')"
                      >
                        <v-icon>
                          {{ icons.mdiUndo }}
                        </v-icon>
                      </v-btn>
                    </template>
                    Vuelve al estado original de la información
                  </v-tooltip>
                </template>
              </v-autocomplete>
            </v-row>
            <v-row>
              <TiposServicioFiltradosTableSection
                :tipos-servicio-ids-respuestas-preguntas="tiposServicioIdsRespuestasPreguntas"
                @select-codigo-acto-servicio="selectCodigoActoServicio($event)"
              />
            </v-row>
          </v-col>
          <v-col cols="6">
            <PreguntasEmergenciaSection
              edit
              :respuestas-preguntas.sync="respuestasPreguntasLocal"
              :tipos-servicio-ids-respuestas-preguntas="tiposServicioIdsRespuestasPreguntas"
              :preguntas-changed="preguntasChanged"
              @save="saveRespuestas"
              @undo="undoRespuestas"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import TiposServicioFiltradosTableSection from '@/components/TiposServicioFiltradosTableSection'
import PreguntasEmergenciaSection from '@/components/PreguntasEmergenciaSection'
import { mapState } from 'vuex'
import { mdiCheck, mdiClose, mdiUndo } from '@mdi/js'
export default {
  name: 'PreguntasDialog',
  components: { PreguntasEmergenciaSection, TiposServicioFiltradosTableSection },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    tipoServicioCuerpoId: {
      type: Number,
      default: null
    },
    respuestasPreguntasCuerpo: {
      type: Array,
      default: () => []
    },
    despachoGuardado: {
      type: Object,
      default: () => {
        return {}
      }
    },
    tipoActoServicioCuerpoChanged: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      icons: {
        mdiClose,
        mdiCheck,
        mdiUndo
      }
    }
  },
  computed: {
    ...mapState('emergencias', ['tiposServicioCuerpo', 'preguntas']),
    tipoServicioCuerpoIdLocal: {
      get () {
        return this.tipoServicioCuerpoId
      },
      set (val) {
        this.$emit('update:tipo-servicio-cuerpo-id', val)
      }
    },
    respuestasPreguntasLocal: {
      get () {
        return this.respuestasPreguntasCuerpo
      },
      set (val) {
        this.$emit('update:respuestas-preguntas-cuerpo', val)
      }
    },
    preguntasChanged () {
      return JSON.stringify(this.respuestasPreguntasLocal) !== JSON.stringify(this.despachoGuardado.respuestasPreguntasCuerpo)
    },
    tiposServicioIdsRespuestasPreguntas () {
      const tiposServicioCuerpoIds = this.tiposServicioCuerpo.map(({ tipoServicioCuerpoId }) => tipoServicioCuerpoId)
      const respuestasPreguntas = this.respuestasPreguntasCuerpo
      return this.preguntas.reduce((acc, { preguntaId, respuestas }) => {
        const respuestaIdCurrentPregunta = respuestasPreguntas.find((respuestaPregunta) => {
          return respuestaPregunta.preguntaId === preguntaId
        })?.respuestaId
        if (!respuestaIdCurrentPregunta) return acc
        const respuestaCurrentPregunta = respuestas.find(({ respuestaId }) => respuestaId === respuestaIdCurrentPregunta)
        return acc.filter(val => {
          return respuestaCurrentPregunta.tiposServicioCuerpoIds.includes(val)
        })
      }, tiposServicioCuerpoIds)
    }
  },
  methods: {
    undoRespuestas () {
      this.respuestasPreguntasLocal = JSON.parse(JSON.stringify(this.despachoGuardado.respuestasPreguntasCuerpo))
    },
    saveRespuestas () {
      this.$emit('save-respuestas')
    },
    selectCodigoActoServicio ({ tipoServicioCuerpoId }) {
      this.$emit('update:tipo-servicio-cuerpo-id', tipoServicioCuerpoId)
    }
  }
}
</script>

<style scoped>

</style>

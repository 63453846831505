<template>
  <GmapMarker
    :position="position"
    :icon="companniaMarkerIcon"
    :label="{
      text: compannia.numero.toString(),
      color: 'white',
      fontSize: '11px'
    }"
  />
</template>

<script>
import { mdiShield } from '@mdi/js'
import { getGoogleMapsAPI } from 'gmap-vue'

export default {
  name: 'GmapMarkerCia',
  props: {
    compannia: {
      type: Object,
      default: () => {
        return {
          numero: 0,
          latitud: 0,
          longitud: 0
        }
      }
    }
  },
  data () {
    return {
      companniaMarkerIcon: {
        path: mdiShield,
        scale: 1,
        strokeWeight: 0.4,
        strokeColor: 'black',
        strokeOpacity: 1,
        fillColor: 'navy',
        fillOpacity: 1,
        labelOrigin: {
          x: 12,
          y: 12
        }
      },
      position: {
        lat: 0,
        lng: 0
      }
    }
  },
  computed: {
    google: getGoogleMapsAPI
  },
  created () {
    this.position = {
      lat: parseFloat(this.compannia.latitud),
      lng: parseFloat(this.compannia.longitud)
    }
  }
}
</script>

<style scoped>

</style>

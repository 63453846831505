import { v4 as uuidv4 } from 'uuid'

import store from '../store'
import { playSounds } from '@/utils/sounds'

function playSoundsDespacho (newVehiculosDespacho, oldVehiculosDespacho, tipoServicioCuerpoId) {
  const vehiculos = store.state.emergencias.vehiculos
  const tonos = store.state.emergencias.tonos
  const companniasTonos = store.state.emergencias.companniasTonos
  const compannias = store.state.main.compannias
  const tiposServicioCuerpoTonos = store.state.emergencias.tiposServicioCuerpoTonos
  const oldVehiculosDespachadosIds = oldVehiculosDespacho.reduce((acc, vehiculoDespacho) => {
    if (vehiculoDespacho.fechaHoraDespacho && vehiculoDespacho.estadoVehiculoDespacho === 'activo') {
      acc.push(vehiculoDespacho.vehiculoId)
    }
    return acc
  }, [])
  const newVehiculosDespachadosCompanniasIds = newVehiculosDespacho.reduce((acc, vehiculoDespacho) => {
    if (vehiculoDespacho.fechaHoraDespacho &&
      vehiculoDespacho.estadoVehiculoDespacho === 'activo' &&
      !oldVehiculosDespachadosIds.includes(vehiculoDespacho.vehiculoId)
    ) {
      const vehiculo = vehiculos.find((vehiculo) => vehiculo.vehiculoId === vehiculoDespacho.vehiculoId)
      if (vehiculo) {
        const companniaId = vehiculo.companniaId
        if (!acc.includes(companniaId)) {
          acc.push(companniaId)
        }
      }
    }
    return acc
  }, [])
  const newCompanniasTonos = compannias
    .filter(({ companniaId }) => newVehiculosDespachadosCompanniasIds.includes(companniaId))
    .sort((a, b) => a.numero > b.numero)
    .map(({ companniaId }) => {
      const companniaTonos = companniasTonos.find((companniaTono) => companniaTono.companniaId === companniaId)
      if (!companniaTonos) return []
      return companniaTonos.tonosIds.map((tonoId) => {
        return tonos.find((tono) => tono.tonoId === tonoId)
      })
    })
  if (newCompanniasTonos.length > 0) {
    const whiteTono = {
      type: 'sine',
      frequency: 20,
      volume: 0,
      duration: 500
    }
    const playCompanniasTonos = newCompanniasTonos.reduce((acc, companniaTonos) => {
      return [
        ...acc,
        ...companniaTonos,
        whiteTono
      ]
    }, [])
    const tipoServicioCuerpoTonosIds = tiposServicioCuerpoTonos.find((tipoServicioCuerpoTono) => {
      return tipoServicioCuerpoTono.tipoServicioCuerpoId === tipoServicioCuerpoId
    })
    const tipoServicioCuerpoTonos = tipoServicioCuerpoTonosIds.tonosIds.map((tonoId) => {
      return tonos.find((tono) => tono.tonoId === tonoId)
    })
    const playCompanniasTipoServicioTonos = [
      ...playCompanniasTonos,
      ...tipoServicioCuerpoTonos
    ]
    playSounds(playCompanniasTipoServicioTonos)
  }
}

export function updateDespachoUtil (payload, storedDespacho, informacionBomberilId) {
  const accion = payload.accion
  const inDespacho = payload.despacho
  const despacho = JSON.parse(JSON.stringify(inDespacho))
  storedDespacho = JSON.parse(JSON.stringify(storedDespacho))
  let data = {}
  const {
    tipoUbicacionId,
    direccion,
    calle1,
    calle2,
    ruta,
    rutaKm,
    lat,
    lng,
    tipoServicioCuerpoId,
    descripcionUbicacion,
    detalleEmergencia,
    vehiculosDespacho,
    respuestasPreguntasCuerpo,
    controlada,
    fechaHoraTermino
  } = despacho
  let descripcion = accion
  switch (accion) {
    case 'ubicacion':
      data = { tipoUbicacionId, direccion, calle1, calle2, ruta, rutaKm, lat, lng }
      break
    case 'tipoServicioCuerpo':
      data = { tipoServicioCuerpoId }
      break
    case 'descripcionUbicacion':
      data = { descripcionUbicacion }
      break
    case 'detalleEmergencia':
      data = { detalleEmergencia }
      break
    case 'vehiculosDespacho': {
      const newVehiculosDespacho = JSON.parse(JSON.stringify(vehiculosDespacho))
      const oldVehiculosDespacho = JSON.parse(JSON.stringify(storedDespacho.vehiculosDespacho))
      playSoundsDespacho(newVehiculosDespacho, oldVehiculosDespacho, tipoServicioCuerpoId)
      data = { vehiculosDespacho: newVehiculosDespacho }
      break
    }
    case 'respuestasPreguntas': {
      const newRespuestasPreguntasCuerpo = JSON.parse(JSON.stringify(respuestasPreguntasCuerpo))
      data = { respuestasPreguntasCuerpo: newRespuestasPreguntasCuerpo }
      break
    }
    case 'historiaDescripcion': {
      data = { descripcion: payload.historia }
      descripcion = payload.historia
      break
    }
    case 'emergenciaControlada': {
      data = { controlada }
      break
    }
    case 'finalizarEmergencia': {
      data = { fechaHoraTermino }
    }
  }
  if (accion !== 'historiaDescripcion') {
    storedDespacho = {
      ...storedDespacho,
      ...data
    }
  }
  const createdAt = new Date().toISOString()
  const historialDespachoId = uuidv4()
  const historialDespacho = {
    historialDespachoId,
    tipoHistorialDespacho: accion,
    data,
    informacionBomberilId,
    descripcion,
    createdAt
  }
  storedDespacho.historialDespacho.push(historialDespacho)
  return {
    storedDespacho,
    historialDespacho
  }
}

export function listenUpdateDespachoUtil (storedDespacho, historialDespacho) {
  if (!storedDespacho) return
  storedDespacho = JSON.parse(JSON.stringify(storedDespacho))
  const existsHistorial = storedDespacho.historialDespacho.some(({ historialDespachoId }) => {
    return historialDespachoId.historialDespachoId === historialDespachoId
  })
  if (existsHistorial) return
  storedDespacho.historialDespacho.push(historialDespacho)
  const historialesMismoTipo = storedDespacho.historialDespacho.filter(({ tipoHistorialDespacho }) => {
    return tipoHistorialDespacho === historialDespacho.tipoHistorialDespacho
  })
  const masRecienteMismoTipo = historialesMismoTipo.reduce((acc, historial) => {
    if (!acc) {
      return historial
    } else {
      const accDate = new Date(acc.createdAt)
      const historialDate = new Date(historial.createdAt)
      return historialDate.getTime() > accDate.getTime() ? historial : acc
    }
  }, null)
  if (masRecienteMismoTipo.historialDespachoId === historialDespacho.historialDespachoId) {
    if (historialDespacho.tipoHistorialDespacho === 'vehiculoDespachoConductor') {
      const { vehiculoId, conductorId } = historialDespacho.data
      const vehiculosDespacho = JSON.parse(JSON.stringify(storedDespacho.vehiculosDespacho))
      vehiculosDespacho.forEach((vehiculoDepacho) => {
        if (vehiculoDepacho.vehiculoId === vehiculoId) {
          vehiculoDepacho.conductorId = conductorId
        }
      })
      storedDespacho = {
        ...storedDespacho,
        vehiculosDespacho
      }
    } else {
      storedDespacho = {
        ...storedDespacho,
        ...historialDespacho.data
      }
    }
  }
  return storedDespacho
}

<template>
  <v-card>
    <GeneradorTonosDialog
      v-model="generadorDialog.dialog"
      :tono-input="generadorDialog.tonoInput"
    />
    <v-card-text>
      <v-speed-dial
        fixed
        bottom
        right
      >
        <template #activator>
          <v-btn
            fab
            color="primary"
            @click="addTono()"
          >
            <v-icon>
              {{ icons.mdiPlus }}
            </v-icon>
          </v-btn>
        </template>
      </v-speed-dial>
      <v-data-table
        :headers="table.headers"
        :items="tonos"
      >
        <template #[`item.acciones`]="{item}">
          <v-row justify="space-around">
            <v-col>
              <v-btn
                fab
                small
                color="primary"
                depressed
                @click="editTono(item)"
              >
                <v-icon>
                  {{ icons.mdiPencil }}
                </v-icon>
              </v-btn>
            </v-col>
            <v-col>
              <v-btn
                fab
                small
                depressed
                @click="playSound(item)"
              >
                <v-icon>
                  {{ icons.mdiPlay }}
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import GeneradorTonosDialog from '@/components/AdminCuerpo/TonosTab/GeneradorTonosDialog'
import { mdiPencil, mdiPlay, mdiPlus } from '@mdi/js'
import { mapState } from 'vuex'
import { playSound } from '@/utils/sounds'
export default {
  name: 'BibliotecaTonosTab',
  components: { GeneradorTonosDialog },
  data () {
    return {
      icons: {
        mdiPlus,
        mdiPencil,
        mdiPlay
      },
      table: {
        headers: [
          { text: 'Tono', value: 'tono' },
          { text: 'Tipo', value: 'type' },
          { text: 'Frecuencia', value: 'frequency' },
          { text: 'Volumen', value: 'volume' },
          { text: 'Duración', value: 'duration' },
          { text: 'Acciones', value: 'acciones' }
        ]
      },
      generadorDialog: {
        dialog: false,
        tonoInput: {}
      },
      playSound
    }
  },
  computed: {
    ...mapState('emergencias', ['tonos'])
  },
  methods: {
    addTono () {
      this.generadorDialog.tonoInput = {
        tonoId: null,
        tono: '',
        frequency: 600,
        type: 'sine',
        volume: 10,
        duration: 500
      }
      this.generadorDialog.dialog = true
    },
    editTono (tono) {
      this.generadorDialog.tonoInput = tono
      this.generadorDialog.dialog = true
    }
  }
}
</script>

<style scoped>

</style>

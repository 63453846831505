<template>
  <v-row justify="center">
    <v-col cols="12">
      <v-card>
        <v-card-text>
          <v-row align="center">
            <v-col cols="4">
              <v-menu
                ref="menu1"
                v-model="menu1"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    label="Fecha Inicio"
                    persistent-hint
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="date"
                  no-title
                  @input="menu1 = false"
                />
              </v-menu>
            </v-col>
            <v-col cols="4">
              <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template #activator="{ on, attrs }">
                  <v-text-field
                    v-model="date2"
                    label="Fecha Termino"
                    persistent-hint
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <v-date-picker
                  v-model="date2"
                  no-title
                  @input="menu2 = false"
                />
              </v-menu>
            </v-col>
            <v-col cols="4">
              <v-btn
                color="success"
                block
              >
                <v-icon left>
                  mdi-magnify
                </v-icon>
                Buscar
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-tabs v-model="tab">
                <v-tab key="lista">
                  Lista
                </v-tab>
                <v-tab key="mapa">
                  Mapa
                </v-tab>
              </v-tabs>
              <v-tabs-items v-model="tab">
                <v-tab-item key="lista">
                  <v-data-table
                    :headers="table.headers"
                    :items="despachosTerminados"
                  >
                    <template #[`item.fechaHoraTermino`]="{item}">
                      {{ new Date(item.fechaHoraTermino).toLocaleString() }}
                    </template>
                    <template #[`item.codigo`]="{item}">
                      {{ getCodigoTipoServicioCuerpo(item.tipoServicioCuerpoId) }}
                    </template>
                    <template #[`item.vehiculos`]="{item}">
                      <span :set="vehiculosDespacho = vehiculosSeleccionadosDespacho(item)">
                        <span
                          v-for="(vehiculo, index) in vehiculosDespacho"
                          :key="index"
                          :class="{ 'error--text': vehiculo.estadoId === 2, 'warning--text': vehiculo.estadoId === 1}"
                        >
                          {{ vehiculo.denominacion }}{{ index < vehiculosDespacho.length - 1 ? ', ' : ' ' }}
                        </span>
                      </span>
                    </template>
                    <template #[`item.ubicacion`]="{item}">
                      {{ getUbicacion(item) }}
                    </template>
                    <template #[`item.ver`]="{item}">
                      <v-btn
                        :to="`/historial-emergencia/${item.despachoId}`"
                        fab
                        color="primary"
                        small
                        depressed
                      >
                        <v-icon>
                          {{ icons.mdiEye }}
                        </v-icon>
                      </v-btn>
                    </template>
                  </v-data-table>
                </v-tab-item>
                <v-tab-item key="mapa">
                  <GmapMap
                    ref="maps"
                    :zoom="13"
                    :center="ubicacion"
                    style="width: inherit; height: 400px"
                  />
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

import { mapState } from 'vuex'
import { mdiEye } from '@mdi/js'

export default {
  name: 'EmergenciasActivas',
  data () {
    return {
      icons: {
        mdiEye
      },
      menu1: false,
      date: '',
      dateFormatted: '',
      menu2: false,
      date2: '',
      dateFormatted2: '',
      tab: 'lista',
      ubicacion: {
        lat: -33.436260,
        lng: -70.643320
      },
      table: {
        headers: [
          { text: 'Fecha/Hora de Inicio', value: 'fechaHoraInicio' },
          { text: 'Fecha/Hora de Termino', value: 'fechaHoraTermino' },
          { text: 'Código', value: 'codigo' },
          { text: 'Vehículos', value: 'vehiculos' },
          { text: 'Ubicación', value: 'ubicacion' },
          { text: 'Ver', value: 'ver' }
        ]
      }
    }
  },
  computed: {
    ...mapState('emergencias', {
      tiposServicioCuerpo: state => state.tiposServicioCuerpo,
      vehiculos: state => state.vehiculos
    }),
    ...mapState('main', ['compannias']),
    despachosTerminados () {
      return this.$store.state.emergencias.despachos.map((data) => {
        return {
          ...data,
          fechaHoraTermino: data.fechaHoraInicio
        }
      })
    }
  },
  methods: {
    vehiculosSeleccionadosDespacho (despacho) {
      return despacho.vehiculosDespacho.map((vehiculoSeleccionado) => {
        const vehiculo = this.vehiculos.find(({ vehiculoId }) => vehiculoId === vehiculoSeleccionado.vehiculoId)
        return {
          ...vehiculoSeleccionado,
          ...vehiculo
        }
      })
    },
    getCodigoTipoServicioCuerpo (searchTipoServicioCuerpoId) {
      return this.tiposServicioCuerpo.find(({ tipoServicioCuerpoId }) => tipoServicioCuerpoId === searchTipoServicioCuerpoId)?.codigo
    },
    getUbicacion ({ tipoUbicacionId, direccion, calle1, calle2, ruta, rutaKm }) {
      switch (tipoUbicacionId) {
        case 1:
          return direccion
        case 2:
          return `${calle1} con ${calle2}`
        case 'ruta':
          return `${ruta} con ${rutaKm}`
      }
    }
  }

}
</script>

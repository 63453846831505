import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import es from 'vuetify/lib/locale/es'
import LogoBomberos from '@/components/icons/LogoBomberos'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    dark: true,
    themes: {
      light: {
        primary: '#B5272E',
        secondary: '#052a62',
        warning: '#bb9800'
      },
      dark: {
        primary: '#B5272E',
        secondary: '#052a62',
        warning: '#bb9800'
      }
    }
  },
  lang: {
    locales: { es },
    current: 'es'
  },
  icons: {
    iconfont: 'mdiSvg',
    values: {
      logoBomberos: {
        component: LogoBomberos
      }
    }
  }
})

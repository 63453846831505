<template>
  <v-card flat>
    <v-card-title>
      Material Mayor
      <v-spacer />
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          class="pt-0"
        >
          <v-simple-table>
            <thead>
              <tr>
                <th class="text-center table-fit-content px-0">
                  Compañía
                </th>
                <th class="text-left">
                  Vechículos
                </th>
              </tr>
            </thead>
            <tbody class="table-no-hover">
              <tr
                v-for="companniaVehiculo in companniasVehiculos"
                :key="companniaVehiculo.companniaId"
              >
                <th class="text-center table-fit-content px-0">
                  {{ companniaVehiculo.numero }}
                </th>
                <td class="px-0">
                  <v-btn
                    v-for="vehiculo in companniaVehiculo.vehiculos"
                    :key="vehiculo.vehiculoId"
                    class="ma-2 px-3"
                    outlined
                  >
                    <v-row
                      align="center"
                      justify="start"
                    >
                      <v-col>
                        {{ vehiculo.denominacion }}
                      </v-col>
                      <v-divider vertical />
                      <v-col class="text-caption">
                        <v-row>
                          <v-col class="py-0 text-start text-capitalize">
                            <span>Despacho: </span><span>{{ vehiculo.fechaHoraDespacho }}</span>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col class="py-0 text-start text-capitalize">
                            <span>Llegada: </span><span>{{ vehiculo.fechaHoraLlegada? vehiculo.fechaHoraLlegada: 'No determinado' }}</span>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-btn>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>

import { mapState } from 'vuex'

export default {
  name: 'MaterialMayorSection',
  props: {
    compannias: {
      type: Array,
      default: () => []
    },
    despacho: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    ...mapState('emergencias', ['vehiculos']),
    vehiculosDespachoCompannia () {
      return this.despacho.vehiculosDespacho.map((vehiculoDespacho) => {
        const vehiculo = this.vehiculos.find((vehiculo) => vehiculo.vehiculoId === vehiculoDespacho.vehiculoId)
        return {
          ...vehiculoDespacho,
          denominacion: vehiculo.denominacion,
          companniaId: vehiculo.companniaId
        }
      })
    },
    companniasVehiculos () {
      return this.compannias.map((compannia) => {
        const vehiculosCompannia = this.vehiculosDespachoCompannia.filter((vehiculo) => {
          return vehiculo.companniaId === compannia.companniaId &&
            vehiculo.fechaHoraDespacho
        })
        return {
          ...compannia,
          vehiculos: vehiculosCompannia
        }
      })
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <v-card
    flat
    class="mx-1"
  >
    <v-card-title class="py-1">
      Preguntas
      <v-spacer />
      <v-tooltip
        v-if="edit && !create"
        top
      >
        <template #activator="{ on, attrs }">
          <v-btn
            small
            depressed
            tile
            v-bind="attrs"
            :disabled="!preguntasChanged"
            color="primary"
            class="mx-2"
            v-on="on"
            @click="$emit('save')"
          >
            <v-icon>
              {{ icons.mdiCheck }}
            </v-icon>
          </v-btn>
        </template>
        Confirma cambios en preguntas
      </v-tooltip>
      <v-tooltip
        v-if="edit && !create"
        top
      >
        <template #activator="{ on, attrs }">
          <v-btn
            small
            tile
            depressed
            color="primary"
            v-bind="attrs"
            :disabled="!preguntasChanged"
            class="mx-2"
            v-on="on"
            @click="$emit('undo')"
          >
            <v-icon>
              {{ icons.mdiUndo }}
            </v-icon>
          </v-btn>
        </template>
        Vuelve al estado original de las pregutnas
      </v-tooltip>
    </v-card-title>
    <v-card-text style="max-height: 500px; overflow-y: auto">
      <v-row
        v-for="(pregunta) in preguntasRelevantes"
        :key="pregunta.preguntaId"
      >
        <v-col
          cols="12"
          class="pb-0 pt-2"
        >
          <div class="text-h6">
            {{ pregunta.pregunta }}
            <v-btn
              v-if="getRespuestaIdPregunta(pregunta.preguntaId) && edit"
              icon
              small
              @click="cleanRespuestaPregunta(pregunta.preguntaId)"
            >
              <v-icon>
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </div>
          <v-radio-group
            dense
            row
            class="mt-0 justify-center"
            hide-details
            :value="getRespuestaIdPregunta(pregunta.preguntaId)"
            :disabled="!edit"
            @change="selectRespuesta($event, pregunta.preguntaId)"
          >
            <v-radio
              v-for="respuesta in pregunta.respuestas"
              :key="respuesta.respuestaId"
              dense
              :label="respuesta.respuesta"
              :value="respuesta.respuestaId"
            />
          </v-radio-group>
        </v-col>
        <v-col
          cols="12"
          class="pb-0"
        >
          <v-divider />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import { mdiCheck, mdiClose, mdiUndo } from '@mdi/js'

export default {
  name: 'PreguntasEmergenciaSection',
  props: {
    respuestasPreguntas: {
      type: Array,
      default: () => []
    },
    tiposServicioIdsRespuestasPreguntas: {
      type: Array,
      default: () => []
    },
    edit: {
      type: Boolean,
      default: false
    },
    create: {
      type: Boolean,
      default: false
    },
    preguntasChanged: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      icons: {
        mdiClose,
        mdiUndo,
        mdiCheck
      }
    }
  },
  computed: {
    ...mapState('emergencias', ['preguntas']),
    preguntasRelevantes () {
      if (this.respuestasPreguntas.length === 0) {
        return this.preguntas
      }
      const tiposServicioIdsRespuestasPreguntas = this.tiposServicioIdsRespuestasPreguntas
      return this.preguntas.filter(({ preguntaId, respuestas }) => {
        const preguntaRespondida = this.respuestasPreguntas.some(respuestasPreguntas => {
          return preguntaId === respuestasPreguntas.preguntaId
        })
        if (preguntaRespondida) return true
        return respuestas.some(({ noAplica, tiposServicioCuerpoIds }) => {
          if (noAplica) return false
          return tiposServicioCuerpoIds.some((tipoServicioCuerpoId) => {
            return tiposServicioIdsRespuestasPreguntas.includes(tipoServicioCuerpoId)
          })
        })
      })
    }
  },
  methods: {
    cleanRespuestaPregunta (limpiarPreguntaId) {
      const preguntaIndex = this.respuestasPreguntas.findIndex(({ preguntaId }) => limpiarPreguntaId === preguntaId)
      const newRespuestasPreguntas = [
        ...this.respuestasPreguntas.slice(0, preguntaIndex),
        ...this.respuestasPreguntas.slice(preguntaIndex + 1, this.respuestasPreguntas.length)
      ]
      this.$emit('update:respuestas-preguntas', newRespuestasPreguntas)
    },
    selectRespuesta (selectRespuestaId, selectPreguntaId) {
      const respuestaPregunta = {
        preguntaId: selectPreguntaId,
        respuestaId: selectRespuestaId
      }
      const preguntaIndex = this.respuestasPreguntas.findIndex(({ preguntaId }) => preguntaId === selectPreguntaId)
      let newRespuestasPreguntas = []
      if (preguntaIndex >= 0) {
        newRespuestasPreguntas = [
          ...this.respuestasPreguntas.slice(0, preguntaIndex),
          respuestaPregunta,
          ...this.respuestasPreguntas.slice(preguntaIndex + 1, this.respuestasPreguntas.length)
        ]
      } else {
        newRespuestasPreguntas = [
          ...this.respuestasPreguntas,
          respuestaPregunta
        ]
      }
      this.$emit('update:respuestas-preguntas', newRespuestasPreguntas)
    },
    getRespuestaIdPregunta (getPreguntaId) {
      const pregunta = this.respuestasPreguntas.find(({ preguntaId }) => preguntaId === getPreguntaId)
      if (pregunta) {
        return pregunta.respuestaId
      }
      return null
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <v-card-text>
    <PreguntasDialog
      v-model="preguntasDialog.dialog"
      :tipo-servicio-cuerpo-id.sync="despachoLocal.tipoServicioCuerpoId"
      :respuestas-preguntas-cuerpo.sync="despachoLocal.respuestasPreguntasCuerpo"
      :despacho-guardado="despachoGuardado"
      :tipo-acto-servicio-cuerpo-changed="tipoActoServicioCuerpoChanged"
      @save-respuestas="updateDespacho('respuestasPreguntas')"
      @update-despacho-tipo-servicio="updateDespacho('tipoServicioCuerpo')"
      @revert-change-tipo-servicio="revertChange('tipoServicioCuerpo')"
    />
    <v-row v-show="false">
      <v-col cols="auto">
        <v-btn-toggle
          v-model="despachoLocal.tipoUbicacionId"
          mandatory
        >
          <v-btn :value="1">
            Dirección
          </v-btn>
          <v-btn :value="2">
            Intersección
          </v-btn>
          <v-btn :value="3">
            Ruta/Km
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
    <v-row
      v-if="despachoLocal.tipoUbicacionId === 1"
      class="mt-0"
    >
      <v-col
        cols="12"
        class="py-0"
      >
        <GmapAutocomplete
          class="introInput"
          @place_changed="setPlace"
          @keydown="preventOnEnter"
        >
          <template #default="slotProps">
            <v-text-field
              ref="input"
              v-model="despachoLocal.direccion"
              label="Dirección (*)"
              :rules="[(v) => !!v || 'Requerido']"
              :messages="`Lat: ${despachoLocal.lat}, Lng: ${despachoLocal.lng}`"
              @keydown="preventOnEnter"
              @listeners="slotProps.listeners"
              @attrs="slotProps.attrs"
            >
              <template
                #append-outer
              >
                <v-tooltip top>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      :disabled="!ubicacionChanged"
                      v-on="on"
                      @click="updateDespacho('ubicacion')"
                    >
                      <v-icon>
                        {{ icons.mdiCheck }}
                      </v-icon>
                    </v-btn>
                  </template>
                  Confirma modificacion de información y sincroniza con servidor
                </v-tooltip>
                <v-tooltip top>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      :disabled="!ubicacionChanged"
                      v-on="on"
                      @click="revertChange('ubicacion')"
                    >
                      <v-icon>
                        {{ icons.mdiUndo }}
                      </v-icon>
                    </v-btn>
                  </template>
                  Vuelve al estado original de la información
                </v-tooltip>
              </template>
            </v-text-field>
          </template>
        </GmapAutocomplete>
      </v-col>
    </v-row>
    <v-row
      v-else-if="despachoLocal.tipoUbicacionId === 2"
      class="align-baseline"
    >
      <v-col
        cols="5"
        class="py-0"
      >
        <v-text-field
          v-model="despachoLocal.calle1"
          label="Calle 1"
          :rules="[(v) => !!v || 'Requerido']"
        />
      </v-col>
      <v-col
        cols="2"
        class="py-0"
      >
        con
      </v-col>
      <v-col
        cols="5"
        class="py-0"
      >
        <v-text-field
          v-model="despachoLocal.calle2"
          label="Calle 2"
          :rules="[(v) => !!v || 'Requerido']"
        />
      </v-col>
    </v-row>
    <v-row
      v-else-if="despachoLocal.tipoUbicacionId === 3"
      class="align-baseline"
    >
      <v-col
        cols="6"
        class="py-0"
      >
        <v-text-field
          v-model="despachoLocal.ruta"
          label="Ruta"
          :rules="[(v) => !!v || 'Requerido']"
        />
      </v-col>
      <v-col
        cols="6"
        class="py-0"
      >
        <v-text-field
          v-model="despachoLocal.rutaKm"
          label="Kilometro"
          :rules="[(v) => !!v || 'Requerido']"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        class="pb-0"
      >
        <v-autocomplete
          v-model="despachoLocal.tipoServicioCuerpoId"
          label="Código Acto Servicio"
          :items="tiposServicioCuerpo"
          item-value="tipoServicioCuerpoId"
          :item-text="(item) => `${item.codigo} - ${item.descripcion}`"
          :rules="[(v) => !!v || 'Requerido']"
        >
          <template #append-outer>
            <v-tooltip
              top
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  :disabled="!tipoActoServicioCuerpoChanged"
                  v-on="on"
                  @click="updateDespacho('tipoServicioCuerpo')"
                >
                  <v-icon>
                    {{ icons.mdiCheck }}
                  </v-icon>
                </v-btn>
              </template>
              Confirma modificacion de información y sincroniza con servidor
            </v-tooltip>
            <v-tooltip
              top
            >
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  :disabled="!tipoActoServicioCuerpoChanged"
                  v-on="on"
                  @click="revertChange('tipoServicioCuerpo')"
                >
                  <v-icon>
                    {{ icons.mdiUndo }}
                  </v-icon>
                </v-btn>
              </template>
              Vuelve al estado original de la información
            </v-tooltip>
            <v-btn
              color="primary"
              depressed
              tile
              small
              @click="preguntasDialog.dialog = true"
            >
              <v-icon left>
                {{ icons.mdiHelp }}
              </v-icon>
              Preguntas
            </v-btn>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col
        cols="12"
        class="py-1"
      >
        <v-textarea
          v-model="despachoLocal.descripcionUbicacion"
          rows="2"
          label="Descripción Ubicación"
          hide-details
        >
          <template
            #append-outer
          >
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  :disabled="!descripcionUbicacionChanged"
                  v-on="on"
                  @click="updateDespacho('descripcionUbicacion')"
                >
                  <v-icon>
                    {{ icons.mdiCheck }}
                  </v-icon>
                </v-btn>
              </template>
              Confirma modificacion de información y sincroniza con servidor
            </v-tooltip>
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  :disabled="!descripcionUbicacionChanged"
                  v-on="on"
                  @click="revertChange('descripcionUbicacion')"
                >
                  <v-icon>
                    {{ icons.mdiUndo }}
                  </v-icon>
                </v-btn>
              </template>
              Vuelve al estado original de la información
            </v-tooltip>
          </template>
        </v-textarea>
      </v-col>
      <v-col
        cols="12"
        class="pb-1"
      >
        <v-textarea
          v-model="despachoLocal.detalleEmergencia"
          label="Detalle Emergencia"
          rows="2"
          hide-details
        >
          <template
            #append-outer
          >
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  :disabled="!detalleEmergenciaChanged"
                  v-on="on"
                  @click="updateDespacho('detalleEmergencia')"
                >
                  <v-icon>
                    {{ icons.mdiCheck }}
                  </v-icon>
                </v-btn>
              </template>
              Confirma modificacion de información y sincroniza con servidor
            </v-tooltip>
            <v-tooltip top>
              <template #activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  :disabled="!detalleEmergenciaChanged"
                  v-on="on"
                  @click="revertChange('detalleEmergencia')"
                >
                  <v-icon>
                    {{ icons.mdiUndo }}
                  </v-icon>
                </v-btn>
              </template>
              Vuelve al estado original de la información
            </v-tooltip>
          </template>
        </v-textarea>
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
import { mdiCheck, mdiHelp, mdiHelpCircleOutline, mdiUndo } from '@mdi/js'
import PreguntasDialog from '@/components/EmergenciaActiva/PreguntasDialog'

export default {
  name: 'DetalleEmergenciaSection',
  components: { PreguntasDialog },
  props: {
    despachoLocalInput: {
      type: Object,
      default: () => {
        return {}
      }
    },
    despachoGuardado: {
      type: Object,
      default: () => {
        return {}
      }
    },
    tiposServicioCuerpo: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      icons: {
        mdiCheck,
        mdiHelp,
        mdiHelpCircleOutline,
        mdiUndo
      },
      preguntasDialog: {
        dialog: false
      }
    }
  },
  computed: {
    despachoLocal: {
      get () {
        return this.despachoLocalInput
      },
      set (val) {
        this.$emit('update:despacho-local-input', val)
      }
    },
    ubicacionChanged () {
      return this.despachoLocal.tipoUbicacionId !== this.despachoGuardado.tipoUbicacionId ||
        this.despachoLocal.direccion !== this.despachoGuardado.direccion ||
        this.despachoLocal.calle1 !== this.despachoGuardado.calle1 ||
        this.despachoLocal.calle2 !== this.despachoGuardado.calle2 ||
        this.despachoLocal.ruta !== this.despachoGuardado.ruta ||
        this.despachoLocal.rutaKm !== this.despachoGuardado.rutaKm ||
        this.despachoLocal.lat !== this.despachoGuardado.lat ||
        this.despachoLocal.lng !== this.despachoGuardado.lng
    },
    tipoActoServicioCuerpoChanged () {
      return this.despachoLocal.tipoServicioCuerpoId !== this.despachoGuardado.tipoServicioCuerpoId
    },
    descripcionUbicacionChanged () {
      return this.despachoLocal.descripcionUbicacion !== this.despachoGuardado.descripcionUbicacion
    },
    detalleEmergenciaChanged () {
      return this.despachoLocal.detalleEmergencia !== this.despachoGuardado.detalleEmergencia
    }
  },
  methods: {
    revertChange (change) {
      this.$emit('revert-change', change)
    },
    updateDespacho (change) {
      this.$emit('update-despacho', change)
    },
    preventOnEnter (event) {
      if (event.key === 'Enter') {
        event.preventDefault()
      }
    },
    setPlace (place) {
      this.$emit('set-place', place)
    }
  }
}
</script>

<style scoped>

</style>

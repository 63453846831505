<template>
  <v-row justify="center">
    <v-snackbar
      v-model="snackbar.open"
      tile
      :color="snackbar.color"
      :timeout="snackbar.timeout"
    >
      {{ snackbar.text }}

      <template #action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar.open = false"
        >
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
    <v-col cols="12">
      <v-card>
        <v-card flat>
          <v-card-subtitle>
            <v-row>
              <v-col cols="auto">
                Iniciado: {{ new Date(despachoLocal.fechaHoraInicio).toLocaleString() }}
              </v-col>
              <v-col cols="auto">
                Teléfono Contacto: {{ despachoLocal.telefono }}
              </v-col>
              <v-col class="pr-0">
                <v-switch
                  :value="despachoLocal.controlada"
                  :input-value="despachoLocal.controlada"
                  label="Emergencia Controlada"
                  hide-details
                  class="mt-0 pt-0"
                  dense
                  @change="changeEmergenciaControlada"
                />
              </v-col>
              <v-col>
                <v-menu offset-y>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      depressed
                      tile
                      block
                      small
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon left>
                        {{ icons.mdiCheck }}
                      </v-icon>
                      Finalizar Emergencia
                    </v-btn>
                  </template>
                  <v-card
                    tile
                    flat
                  >
                    <v-card-title>
                      Confirmar Finalización
                    </v-card-title>
                    <v-card-actions>
                      <v-btn
                        small
                        depressed
                        tile
                      >
                        <v-icon left>
                          {{ icons.mdiClose }}
                        </v-icon>
                        Cancelar
                      </v-btn>
                      <v-btn
                        color="primary"
                        small
                        depressed
                        tile
                        @click="finalizarEmergencia"
                      >
                        <v-icon left>
                          {{ icons.mdiCheck }}
                        </v-icon>
                        Finalizar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-menu>
              </v-col>
              <v-col>
                <v-btn
                  color="warning"
                  depressed
                  tile
                  block
                  small
                >
                  <v-icon left>
                    {{ icons.mdiAlert }}
                  </v-icon>
                  Falsa Alarma
                </v-btn>
              </v-col>
            </v-row>
          </v-card-subtitle>
          <v-divider />
          <v-row>
            <v-col cols="6">
              <DetalleEmergenciaSection
                :despacho-local-input.sync="despachoLocal"
                :despacho-guardado="despachoGuardado"
                :tipos-servicio-cuerpo="tiposServicioCuerpo"
                @revert-change="revertChange"
                @update-despacho="updateDespacho"
                @set-place="setPlace"
              />
            </v-col>
            <v-col
              cols="6"
              class="pl-0 pb-0"
            >
              <MapSection
                :despacho-local-lat="despachoLocal.lat"
                :despacho-local-lng="despachoLocal.lng"
                :grifos="grifos"
                :vehiculos-seleccionados="vehiculosSeleccionados"
                :compannias="compannias"
                @set-marker="setMarker"
              />
            </v-col>
          </v-row>
        </v-card>
        <v-row>
          <v-col>
            <v-divider />
          </v-col>
        </v-row>
        <v-divider />
        <v-row>
          <v-col
            cols="7"
            class="pr-1"
          >
            <MaterialMayorSection
              :compannias="compannias"
              :despacho-local="despachoLocal"
              :despacho-guardado="despachoGuardado"
              :loading-matrix="loadingMatrix"
              :vehiculos-seleccionados="vehiculosSeleccionados"
              :vehiculos-matrix="vehiculosMatrix"
              :tipo-servicio-cuerpo-id="despachoLocal.tipoServicioCuerpoId"
              @revert-change="revertChange"
              @update-despacho="updateDespacho"
              @change-vehiculos-seleccionados="changeVehiculosSeleccionados"
              @update-matrix="getVehiculosDistanceMatrix"
            />
            <v-divider />
            <v-card flat>
              <v-card-title>
                Personal
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-btn color="warning">
                      <v-icon left>
                        {{ icons.mdiAlert }}
                      </v-icon>
                      Notificar a Todo el Personal - Emergencia Mayor
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-divider vertical />
          <v-col
            cols="5"
            class="pl-1"
          >
            <HistorialSection
              :historial-despacho="despachoGuardado.historialDespacho"
              @agregar-historia="agregarHistoria"
            />
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import { getGoogleMapsAPI } from 'gmap-vue'
import { mdiCheck, mdiHelp, mdiAlert, mdiHelpCircleOutline, mdiUndo, mdiSend, mdiClose } from '@mdi/js'
import MapSection from '@/components/EmergenciaActiva/MapSection'
import { v4 as uuidv4 } from 'uuid'
import DetalleEmergenciaSection from '@/components/EmergenciaActiva/DetalleEmergenciaSection'
import MaterialMayorSection from '@/components/EmergenciaActiva/MaterialMayorSection'
import HistorialSection from '@/components/EmergenciaActiva/HistorialSection'

export default {
  name: 'EmergenciaActivaPage',
  components: {
    HistorialSection,
    MaterialMayorSection,
    DetalleEmergenciaSection,
    MapSection
  },
  data () {
    return {
      icons: {
        mdiClose,
        mdiCheck,
        mdiHelp,
        mdiAlert,
        mdiHelpCircleOutline,
        mdiUndo,
        mdiSend
      },
      place: null,
      despachoGuardado: {},
      despachoLocal: {
        despachoId: '',
        tipoServicioCuerpoId: null,
        telefono: '',
        descripcionUbicacion: '',
        detalleEmergencia: '',
        tipoUbicacionId: 1,
        direccion: '',
        calle1: '',
        calle2: '',
        ruta: '',
        rutaKm: 0,
        lat: -33.436260,
        lng: -70.643320,
        respuestasPreguntasCuerpo: [],
        vehiculosDespacho: [],
        historialDespacho: [],
        fechaHoraInicio: null,
        controlada: false,
        cuerpoId: null,
        fechaHoraTermino: null
      },
      preguntas: [],
      vehiculosDistanceMatrix: [],
      loadingMatrix: true,
      snackbar: {
        open: false,
        text: '',
        timeout: 3000,
        color: 'secondary'
      }
    }
  },
  computed: {
    ...mapState('emergencias', ['vehiculos', 'tiposServicioCuerpo', 'grifos']),
    ...mapState('main', ['compannias']),
    preguntasVisibles () {
      return this.preguntas
    },
    vehiculosMatrix () {
      return this.vehiculos.map(vehiculo => {
        let matrix = this.vehiculosDistanceMatrix.find(({ vehiculoId }) => vehiculoId === vehiculo.vehiculoId)
        if (!matrix) {
          matrix = {
            distance: {
              text: '',
              value: Number.POSITIVE_INFINITY
            },
            duration: {
              text: '',
              value: Number.POSITIVE_INFINITY
            }
          }
        }
        return {
          ...vehiculo,
          matrix
        }
      })
    },
    google: getGoogleMapsAPI,
    vehiculosSeleccionados () {
      return this.despachoLocal.vehiculosDespacho
        .filter(({ estadoVehiculoDespacho }) => estadoVehiculoDespacho === 'activo')
        .map((vehiculoSeleccionado) => {
          const vehiculo = this.vehiculos.find(({ vehiculoId }) => vehiculoId === vehiculoSeleccionado.vehiculoId)
          return {
            ...vehiculoSeleccionado,
            ...vehiculo
          }
        })
    }
  },
  created () {
    this.preguntas = this.$store.state.emergencias.preguntas
  },
  async mounted () {
    await this.$gmapApiPromiseLazy()
    await this.getDespacho()
    this.getVehiculosDistanceMatrix()
  },
  methods: {
    finalizarEmergencia () {
      this.despachoLocal.fechaHoraTermino = new Date().toISOString()
      const despachoId = this.$route.params.despachoId
      this.updateDespacho('finalizarEmergencia')
        .finally(() => {
          setTimeout(() => {
            this.$router.push(`/historial-emergencia/${despachoId}`)
          }, 1500)
        })
    },
    changeEmergenciaControlada (event) {
      this.despachoLocal.controlada = !!event
      this.updateDespacho('emergenciaControlada')
    },
    getVehiculosDistanceMatrix () {
      this.loadingMatrix = true
      this.$gmapApiPromiseLazy().then(() => {
        const service = new this.google.maps.DistanceMatrixService()
        const origins = this.vehiculos.map(({ lat, lng }) => {
          return { lat, lng }
        })
        const destinations = [{
          lat: this.despachoLocal.lat,
          lng: this.despachoLocal.lng
        }]
        const request = {
          origins,
          destinations,
          travelMode: this.google.maps.TravelMode.DRIVING,
          unitSystem: this.google.maps.UnitSystem.METRIC,
          avoidHighways: false,
          avoidTolls: false
        }
        service.getDistanceMatrix(request).then((response) => {
          this.vehiculosDistanceMatrix = this.vehiculos.map((vehiculo, index) => {
            return {
              vehiculoId: vehiculo.vehiculoId,
              ...response.rows[index].elements[0]
            }
          })
        }).finally(() => {
          this.loadingMatrix = false
          this.snackbar.color = 'secondary'
          this.snackbar.text = 'Distacias y tiempos a emergecia actualizados.'
          this.snackbar.open = true
        })
      })
    },
    updateDespacho (accion, historia = '') {
      const payload = {
        accion,
        despacho: this.despachoLocal,
        historia
      }
      return this.$store.dispatch('emergencias/updateDespacho', payload)
        .then(({ data, storedDespacho }) => {
          this.snackbar.color = 'success'
          this.snackbar.text = data.message
          this.snackbar.open = true
          this.despachoGuardado = JSON.parse(JSON.stringify(storedDespacho))
        }).catch(({ storedDespacho }) => {
          this.despachoGuardado = JSON.parse(JSON.stringify(storedDespacho))
        })
    },
    revertChange (accion) {
      switch (accion) {
        case 'ubicacion':
          this.despachoLocal.tipoUbicacionId = this.despachoGuardado.tipoUbicacionId
          this.despachoLocal.direccion = this.despachoGuardado.direccion
          this.despachoLocal.calle1 = this.despachoGuardado.calle1
          this.despachoLocal.calle2 = this.despachoGuardado.calle2
          this.despachoLocal.ruta = this.despachoGuardado.ruta
          this.despachoLocal.rutaKm = this.despachoGuardado.rutaKm
          this.despachoLocal.lat = this.despachoGuardado.lat
          this.despachoLocal.lng = this.despachoGuardado.lng
          break
        case 'tipoServicioCuerpo':
          this.despachoLocal.tipoServicioCuerpoId = this.despachoGuardado.tipoServicioCuerpoId
          break
        case 'descripcionUbicacion':
          this.despachoLocal.descripcionUbicacion = this.despachoGuardado.descripcionUbicacion
          break
        case 'detalleEmergencia':
          this.despachoLocal.detalleEmergencia = this.despachoGuardado.detalleEmergencia
          break
        case 'vehiculosDespacho':
          this.despachoLocal.vehiculosDespacho = JSON.parse(JSON.stringify(this.despachoGuardado.vehiculosDespacho))
          break
      }
    },
    setMarker ({ lat, lng }) {
      this.despachoLocal.lat = lat
      this.despachoLocal.lng = lng
      const geocoder = new this.google.maps.Geocoder()
      geocoder.geocode({ location: { lat, lng } })
        .then((response) => {
          this.despachoLocal.direccion = response.results[0].formatted_address
        })
    },
    setPlace (place) {
      if (!Object.prototype.hasOwnProperty.call(place, 'place_id')) {
        this.place = null
      } else {
        this.place = place
        this.despachoLocal.direccion = this.place.formatted_address
        this.despachoLocal.lat = this.place.geometry.location.lat()
        this.despachoLocal.lng = this.place.geometry.location.lng()
      }
    },
    async getDespacho () {
      await this.$store.dispatch('emergencias/getDespacho', this.$route.params.despachoId)
        .then((despacho) => {
          this.despachoGuardado = JSON.parse(JSON.stringify(despacho))
          this.despachoLocal = despacho
        })
    },
    selectCodigoActoServicio (item) {
      this.data.codigoActoServicioId = item.codigoActoServicioId
    },
    changeVehiculosSeleccionados ({ vehiculoId, clicks }) {
      const vehiculosSeleccionadosIds = this.despachoLocal.vehiculosDespacho
      const vehiculoSeleccionadoIndex = vehiculosSeleccionadosIds.findIndex(vehiculo => vehiculo.vehiculoId === vehiculoId)
      if (vehiculoSeleccionadoIndex > -1) {
        const vehiculoSeleccionado = vehiculosSeleccionadosIds[vehiculoSeleccionadoIndex]
        if (vehiculoSeleccionado.estadoVehiculoDespacho === 'inactivo') {
          if (clicks === 2) {
            vehiculoSeleccionado.fechaHoraDespacho = new Date().toISOString()
            vehiculoSeleccionado.estadoVehiculoDespacho = 'activo'
          } else if (clicks === 1) {
            vehiculoSeleccionado.fechaHoraDespacho = null
            vehiculoSeleccionado.estadoVehiculoDespacho = 'activo'
          }
        } else if (
          ((vehiculoSeleccionado.fechaHoraDespacho !== null && clicks === 2) ||
          (vehiculoSeleccionado.fechaHoraPredespacho !== null && clicks === 1))
        ) {
          vehiculoSeleccionado.estadoVehiculoDespacho = 'inactivo'
        } else if (clicks === 2) {
          vehiculoSeleccionado.fechaHoraDespacho = new Date().toISOString()
          vehiculoSeleccionado.estadoVehiculoDespacho = 'activo'
        } else if (clicks === 1) {
          vehiculoSeleccionado.estadoVehiculoDespacho = 'activo'
        }
      } else {
        const newVehiculoDespacho = {
          vehiculoDespachoId: uuidv4(),
          vehiculoId,
          despachoId: this.despachoId,
          estadoVehiculoDespacho: 'activo',
          fechaHoraPredespacho: new Date().toISOString(),
          fechaHoraDespacho: null,
          fechaHoraLlegada: null,
          coordenadasFechaHora: []
        }
        this.despachoLocal.vehiculosDespacho.push(newVehiculoDespacho)
      }
    },
    agregarHistoria (historia) {
      this.updateDespacho('historiaDescripcion', historia)
    }
  }
}
</script>

<style lang="scss" scoped>

</style>

<template>
  <v-card-text>
    <PreguntasHistorialDialog
      v-model="preguntasDialog.dialog"
      :tipo-servicio-cuerpo-id="despacho.tipoServicioCuerpoId"
      :respuestas-preguntas-cuerpo="despacho.respuestasPreguntasCuerpo"
    />
    <v-row v-show="false">
      <v-col cols="auto">
        <v-btn-toggle
          :value="despacho.tipoUbicacionId"
          mandatory
        >
          <v-btn :value="1">
            Dirección
          </v-btn>
          <v-btn :value="2">
            Intersección
          </v-btn>
          <v-btn :value="3">
            Ruta/Km
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>
    <v-row
      v-if="despacho.tipoUbicacionId === 1"
      class="mt-0"
    >
      <v-col
        cols="12"
        class="py-0"
      >
        <v-text-field
          ref="input"
          :value="despacho.direccion"
          label="Dirección (*)"
          :messages="`Lat: ${despacho.lat}, Lng: ${despacho.lng}`"
          disabled
        />
      </v-col>
    </v-row>
    <v-row
      v-else-if="despacho.tipoUbicacionId === 2"
      class="align-baseline"
    >
      <v-col
        cols="5"
        class="py-0"
      >
        <v-text-field
          :value="despacho.calle1"
          label="Calle 1"
          disabled
        />
      </v-col>
      <v-col
        cols="2"
        class="py-0"
      >
        con
      </v-col>
      <v-col
        cols="5"
        class="py-0"
      >
        <v-text-field
          :value="despacho.calle2"
          label="Calle 2"
          disabled
        />
      </v-col>
    </v-row>
    <v-row
      v-else-if="despacho.tipoUbicacionId === 3"
      class="align-baseline"
    >
      <v-col
        cols="6"
        class="py-0"
      >
        <v-text-field
          :value="despacho.ruta"
          label="Ruta"
          disabled
        />
      </v-col>
      <v-col
        cols="6"
        class="py-0"
      >
        <v-text-field
          :value="despacho.rutaKm"
          label="Kilometro"
          disabled
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        class="pb-0"
      >
        <v-row>
          <v-col cols="9">
            <v-autocomplete
              :value="despacho.tipoServicioCuerpoId"
              label="Código Acto Servicio"
              :items="tiposServicioCuerpo"
              item-value="tipoServicioCuerpoId"
              :item-text="(item) => `${item.codigo} - ${item.descripcion}`"
              disabled
            />
          </v-col>
          <v-col
            cols="3"
            align-self="center"
          >
            <v-btn
              color="primary"
              depressed
              tile
              small
              @click="preguntasDialog.dialog = true"
            >
              <v-icon left>
                {{ icons.mdiHelp }}
              </v-icon>
              Preguntas
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        class="py-1"
      >
        <v-textarea
          :value="despacho.descripcionUbicacion"
          rows="2"
          label="Descripción Ubicación"
          hide-details
          disabled
        />
      </v-col>
      <v-col
        cols="12"
        class="pb-1"
      >
        <v-textarea
          :value="despacho.detalleEmergencia"
          label="Detalle Emergencia"
          rows="2"
          hide-details
          disabled
        />
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
import { mdiHelp } from '@mdi/js'
import PreguntasHistorialDialog from '@/components/HistorialEmergencia/PreguntasHistorialDialog'

export default {
  name: 'DetalleEmergenciaHistorialSection',
  components: {
    PreguntasHistorialDialog
  },
  props: {
    despacho: {
      type: Object,
      default: () => {
        return {}
      }
    },
    tiposServicioCuerpo: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      icons: {
        mdiHelp
      },
      preguntasDialog: {
        dialog: false
      }
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <v-card>
    <v-card-subtitle class="pb-0">
      <ul>
        <li>
          Preguntas para ayudar a identificar los actos de servicio.
        </li>
        <li>
          Para cambiar orden de preguntas seleccionar icono <v-icon small>
            {{ icons.mdiDotsGrid }}
          </v-icon> y arrastrar, manteniendolo seleccionado.
        </li>
        <li>
          Al hacer click en un código se muestra su descripción.
        </li>
      </ul>
    </v-card-subtitle>
    <v-card-text class="py-0">
      <v-speed-dial
        fixed
        bottom
        right
      >
        <template #activator>
          <v-btn
            fab
            color="primary"
            @click="addPregunta()"
          >
            <v-icon>
              {{ icons.mdiPlus }}
            </v-icon>
          </v-btn>
          <v-btn
            fab
            color="primary"
            :disabled="!changeData"
            :loading="form.loading"
            @click="save()"
          >
            <v-icon>
              {{ icons.mdiContentSaveAll }}
            </v-icon>
          </v-btn>
          <v-btn
            fab
            color="primary"
            :disabled="!changeData"
            @click="undo()"
          >
            <v-icon>
              {{ icons.mdiUndo }}
            </v-icon>
          </v-btn>
        </template>
      </v-speed-dial>
      <v-menu
        v-model="selectedChip.open"
        :activator="selectedChip.element"
        offset-y
        top
        close-on-click
      >
        <v-card color="black">
          <v-card-subtitle class="pa-1 white--text">
            {{ tipoServicioCuerpoCodigoDescripcion(selectedChip.tipoServicioCuerpoId) }}
          </v-card-subtitle>
        </v-card>
      </v-menu>
      <v-row>
        <v-col>
          <v-form ref="preguntasForm">
            <v-simple-table
              id="mytable"
              dense
              fixed-header
              height="78vh"
            >
              <thead>
                <tr>
                  <th class="col-2">
                    Orden/Pregunta
                  </th>
                  <th class="col-6">
                    Respuestas y Códigos de Servicio
                  </th>
                  <th class="col-4">
                    Códigos que no aplican a la pregunta (otros)
                  </th>
                </tr>
              </thead>
              <draggable
                v-model="preguntasLocal"
                tag="tbody"
                handle=".handle"
                :group="{ name: 'preguntas' }"
              >
                <tr
                  v-for="(pregunta, index) in preguntasLocal"
                  :key="`pregunta-${pregunta.preguntaId}`"
                >
                  <td class="col-2">
                    <v-row justify="center">
                      <v-col cols="auto">
                        <v-btn
                          class="handle"
                          text
                        >
                          <v-icon left>
                            {{ icons.mdiDotsGrid }}
                          </v-icon>
                          {{ index+1 }}
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col class="pt-0">
                        <v-textarea
                          v-model="pregunta.pregunta"
                          outlined
                          placeholder="Ingrese su pregunta"
                          dense
                          hide-details
                          :rules="[(v) => !!v || 'Requerido']"
                        />
                      </v-col>
                    </v-row>
                    <v-row justify="center">
                      <v-col
                        cols="12"
                        class="pt-0"
                      >
                        <v-btn
                          elevation="0"
                          small
                          color="primary"
                          @click="addRespuesta(pregunta)"
                        >
                          <v-icon left>
                            {{ icons.mdiPlus }}
                          </v-icon>
                          Agregar Respuesta
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row justify="center">
                      <v-col
                        cols="12"
                        class="pt-0"
                      >
                        <v-btn
                          elevation="0"
                          small
                          color="primary"
                          block
                          @click="deletePregunta(pregunta)"
                        >
                          <v-icon left>
                            {{ icons.mdiDelete }}
                          </v-icon>
                          Eliminar Pregunta
                        </v-btn>
                      </v-col>
                    </v-row>
                  </td>
                  <td class="col-6">
                    <draggable
                      v-model="pregunta.respuestas"
                      handle=".handle-respuesta"
                      :group="{ name: `pregunta-${pregunta.preguntaId}-respuestas`}"
                    >
                      <v-card
                        v-for="(respuesta, respuestaIndex) in pregunta.respuestas"
                        :key="respuesta.respuestaId"
                        outlined
                        tile
                      >
                        <v-card-text>
                          <v-row
                            class="mt-0"
                          >
                            <v-col
                              cols="12"
                              class="py-0"
                            >
                              <v-text-field
                                v-model="respuesta.respuesta"
                                style="width: 100%"
                                dense
                                placeholder="Ingrese Respuesta"
                                :rules="[(v) => !!v || 'Requerido']"
                              >
                                <template
                                  #prepend
                                >
                                  <v-btn
                                    class="handle-respuesta px-0"
                                    text
                                    x-small
                                  >
                                    <v-icon left>
                                      {{ icons.mdiDotsGrid }}
                                    </v-icon>
                                    {{ respuestaIndex+1 }}
                                  </v-btn>
                                </template>
                                <template #append-outer>
                                  <v-tooltip
                                    top
                                    color="black"
                                  >
                                    <template #activator="{on}">
                                      <v-btn
                                        fab
                                        x-small
                                        color="primary"
                                        :disabled="pregunta.respuestas.length === 1"
                                        @click="deleteRespuesta(pregunta, respuesta)"
                                        v-on="on"
                                      >
                                        <v-icon>
                                          {{ icons.mdiDelete }}
                                        </v-icon>
                                      </v-btn>
                                    </template>
                                    Eliminar respuesta.
                                  </v-tooltip>
                                </template>
                              </v-text-field>
                            </v-col>
                            <v-col
                              cols="12"
                              class="pt-0"
                            >
                              <v-card
                                outlined
                              >
                                <v-card-text class="pa-0">
                                  <draggable
                                    v-model="respuesta.tiposServicioCuerpoIds"
                                    class="draggable-min"
                                    :move="onMove"
                                    :group="{ name: `pregunta-id-${pregunta.preguntaId}-codigos`}"
                                  >
                                    <v-chip
                                      v-for="tipoServicioCuerpoId in respuesta.tiposServicioCuerpoIds"
                                      :key="`respuesta-id-${respuesta.respuestaId}-tipo-servicio-cuerpo-id-${tipoServicioCuerpoId}`"
                                      color="primary"
                                      draggable
                                      small
                                      class="ma-1"
                                      @click="showChipDescription($event, tipoServicioCuerpoId)"
                                    >
                                      {{ tipoServicioCuerpoCodigo(tipoServicioCuerpoId) }}
                                    </v-chip>
                                  </draggable>
                                </v-card-text>
                              </v-card>
                            </v-col>
                          </v-row>
                        </v-card-text>
                      </v-card>
                    </draggable>
                  </td>
                  <td class="col-4">
                    <v-card outlined>
                      <v-card-text class="pa-0">
                        <draggable
                          v-model="pregunta.respuestaNoAplica.tiposServicioCuerpoIds"
                          :group="{ name: `pregunta-id-${pregunta.preguntaId}-codigos`}"
                          :move="onMove"
                        >
                          <v-chip
                            v-for="tipoServicioCuerpoId in pregunta.respuestaNoAplica.tiposServicioCuerpoIds"
                            :key="`pregunta-id-${pregunta.preguntaId}-tipo-servicio-cuerpo-id-${tipoServicioCuerpoId}`"
                            draggable
                            color="primary"
                            small
                            class="ma-1"
                            @click="showChipDescription($event, tipoServicioCuerpoId)"
                          >
                            {{ tipoServicioCuerpoCodigo(tipoServicioCuerpoId) }}
                          </v-chip>
                        </draggable>
                      </v-card-text>
                    </v-card>
                  </td>
                </tr>
              </draggable>
            </v-simple-table>
          </v-form>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import draggable from 'vuedraggable'
import { mdiContentSave, mdiContentSaveAll, mdiDelete, mdiDotsGrid, mdiPlus, mdiUndo } from '@mdi/js'
import { v4 as uuidv4 } from 'uuid'
export default {
  name: 'PreguntasPorServicioTab',
  components: {
    draggable
  },
  data () {
    return {
      form: {
        loading: false
      },
      icons: {
        mdiContentSave,
        mdiDotsGrid,
        mdiPlus,
        mdiDelete,
        mdiContentSaveAll,
        mdiUndo
      },
      selectedChip: {
        open: false,
        element: null,
        tipoServicioCuerpoId: null
      },
      preguntasLocal: [],
      preguntasGuardadasJson: '',
      selection: null,
      currentTag: null
    }
  },
  computed: {
    ...mapState('emergencias', ['preguntas', 'tiposServicioCuerpo']),
    changeData () {
      return JSON.stringify(this.preguntasLocal) !== this.preguntasGuardadasJson
    }
  },
  mounted () {
    this.$store.dispatch('emergencias/getPreguntasCuerpo')
      .then(({ data }) => {
        const preguntas = JSON.parse(JSON.stringify(data.preguntas))
        this.setPreguntasLocal(preguntas)
      })
      .catch(() => {
        const preguntas = JSON.parse(JSON.stringify(this.preguntas))
        this.setPreguntasLocal(preguntas)
      })
  },
  methods: {
    save () {
      const valid = this.$refs.preguntasForm.validate()
      if (valid) {
        this.form.loading = true
        const preguntas = this.preguntasLocal.map((preguntaLocal, index) => {
          const { pregunta, preguntaId, respuestas, respuestaNoAplica } = preguntaLocal
          const respuestasOrden = respuestas.map((respuesta, index) => {
            return {
              ...respuesta,
              orden: index + 1
            }
          })
          respuestaNoAplica.orden = (respuestasOrden.length + 1)
          const respuestasTotalOrden = [
            ...respuestasOrden,
            respuestaNoAplica
          ]
          return {
            pregunta,
            preguntaId,
            respuestas: respuestasTotalOrden,
            orden: index + 1
          }
        })
        this.$store.dispatch('emergencias/storePreguntasCuerpo', preguntas)
          .then(({ data }) => {
            this.setPreguntasLocal(data.preguntas)
          })
          .finally(() => {
            this.form.loading = false
          })
      }
    },
    undo () {
      this.preguntasLocal = JSON.parse(this.preguntasGuardadasJson)
    },
    deleteRespuesta (pregunta, respuesta) {
      pregunta.respuestas = pregunta.respuestas.filter(({ respuestaId }) => {
        return respuestaId !== respuesta.respuestaId
      })
      pregunta.respuestaNoAplica.tiposServicioCuerpoIds = [
        ...pregunta.pregunta.respuestaNoAplica.tiposServicioCuerpoIds,
        ...respuesta.tiposServicioCuerpoIds
      ]
    },
    addRespuesta (pregunta) {
      const respuestaId = uuidv4()
      const respuesta = {
        respuestaId,
        respuesta: '',
        tiposServicioCuerpoIds: []
      }
      pregunta.respuestas.push(respuesta)
    },
    deletePregunta (pregunta) {
      this.preguntasLocal = this.preguntasLocal.filter(({ preguntaId }) => {
        return preguntaId !== pregunta.preguntaId
      })
    },
    addPregunta () {
      const respuestaId = uuidv4()
      const respuestaNoAplica = {
        respuestaId,
        respuesta: 'No Aplica',
        tiposServicioCuerpoIds: this.tiposServicioCuerpo.map(({ tipoServicioCuerpoId }) => tipoServicioCuerpoId),
        noAplica: true
      }
      const pregunta = {
        preguntaId: uuidv4(),
        pregunta: '',
        respuestas: [],
        respuestaNoAplica
      }
      this.addRespuesta(pregunta)
      this.preguntasLocal.push(pregunta)
      this.$nextTick(() => {
        window.scrollTo(0, document.body.scrollHeight)
      })
    },
    showChipDescription (nativeEvent, tipoServicioCuerpoId) {
      const open = () => {
        this.selectedChip.tipoServicioCuerpoId = tipoServicioCuerpoId
        this.selectedChip.element = nativeEvent.target
        requestAnimationFrame(() => requestAnimationFrame(() => {
          this.selectedChip.open = true
        }))
      }

      if (this.selectedChip.open) {
        this.selectedChip.open = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
    onMove ({ relatedContext, draggedContext }) {
      const relatedElement = relatedContext.element
      const draggedElement = draggedContext.element
      return (
        (!relatedElement || !relatedElement.fixed) && !draggedElement.fixed
      )
    },
    tipoServicioCuerpoCodigo (searchTipoServicioCuerpoId) {
      return this.tiposServicioCuerpo.find(({ tipoServicioCuerpoId }) => tipoServicioCuerpoId === searchTipoServicioCuerpoId)?.codigo
    },
    tipoServicioCuerpoCodigoDescripcion (searchTipoServicioCuerpoId) {
      if (!searchTipoServicioCuerpoId) return ''
      const tipoServicioCuerpo = this.tiposServicioCuerpo.find(({ tipoServicioCuerpoId }) => tipoServicioCuerpoId === searchTipoServicioCuerpoId)
      return `${tipoServicioCuerpo.codigo} - ${tipoServicioCuerpo.descripcion}`
    },
    setPreguntasLocal (preguntas) {
      const tiposServicioCuerpoIds = this.tiposServicioCuerpo.map(({ tipoServicioCuerpoId }) => {
        return tipoServicioCuerpoId
      })
      this.preguntasLocal = preguntas.map((pregunta) => {
        const tiposServicioCuerpoIdsRespuestas = pregunta.respuestas.reduce((acc, respuesta) => {
          return [
            ...acc,
            ...respuesta.tiposServicioCuerpoIds
          ]
        }, [])
        const tiposServiciosCuerposIdsFree = tiposServicioCuerpoIds.filter(x => !tiposServicioCuerpoIdsRespuestas.includes(x))
        const respuestaNoAplicaIndex = pregunta.respuestas.findIndex(({ noAplica }) => noAplica)
        const respuestaNoAplica = pregunta.respuestas[respuestaNoAplicaIndex]
        pregunta.respuestas.splice(respuestaNoAplicaIndex, 1)
        respuestaNoAplica.tiposServicioCuerpoIds = [
          ...respuestaNoAplica.tiposServicioCuerpoIds,
          ...tiposServiciosCuerposIdsFree
        ]
        return {
          ...pregunta,
          respuestaNoAplica
        }
      }).sort((a, b) => {
        return a.orden > b.orden
      })
      this.preguntasGuardadasJson = JSON.stringify(this.preguntasLocal)
    }
  }
}
</script>

<style scoped>
.draggable-min {
  width: 100%;
  min-height: 30px;
  height: 100%;
}

#mytable >>> tr:hover {
  background: transparent !important;
}

</style>

<template>
  <v-card>
    <v-card-title>
      Prueba Tonos
    </v-card-title>
    <v-card-text>
      <v-slider
        v-model="frequency"
        min="40"
        max="6000"
        :label="`Frecuencia: ${frequency} Hz`"
      />
      <v-slider
        v-model="typeNumber"
        min="0"
        max="3"
        :label="`Tipo: ${type}`"
      />
      <v-slider
        v-model="volume"
        min="0"
        max="100"
        :label="`Volumen: ${volume}`"
      />
      <v-slider
        v-model="duration"
        min="100"
        max="5000"
        :label="`Duracion: ${duration} ms`"
      />
      <v-btn @click="playSound">
        Reproducir
      </v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'PruebaTonosCard',
  data () {
    return {
      frequency: 3000,
      typeNumber: 0,
      volume: 10,
      duration: 500
    }
  },
  computed: {
    type () {
      let type = ''
      switch (this.typeNumber) {
        case 0: type = 'sine'; break
        case 1: type = 'square'; break
        case 2: type = 'sawtooth'; break
        case 3: type = 'triangle'; break
      }
      return type
    }
  },
  methods: {
    playSound () {
      const audioCtx = new (window.AudioContext || window.webkitAudioContext)()
      const oscillator = audioCtx.createOscillator()
      const gainNode = audioCtx.createGain()

      oscillator.connect(gainNode)
      gainNode.connect(audioCtx.destination)

      gainNode.gain.value = this.volume / 100
      oscillator.frequency.value = this.frequency
      oscillator.type = this.type

      oscillator.start()
      setTimeout(() => {
        oscillator.stop()
      }, this.duration)
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <GmapMarker
    v-if="vehiculo.estadoVehiculoDespacho !== 'innactivo'"
    :position="position"
    :icon="carroMarkerIcon"
    :label="{
      text: vehiculo.denominacion,
      color: 'white',
      className: 'carro-numero'
    }"
  />
</template>

<script>
import { mdiFireTruck } from '@mdi/js'

export default {
  name: 'GmapMarkerVehiculo',
  props: {
    vehiculo: {
      type: Object,
      default: () => {
        return {
          estadoId: 0,
          lat: 0,
          lng: 0
        }
      }
    }
  },
  computed: {
    position () {
      return {
        lat: this.vehiculo.lat,
        lng: this.vehiculo.lng
      }
    },
    carroMarkerIcon () {
      return {
        path: mdiFireTruck,
        scale: 1.5,
        strokeWeight: 0.4,
        strokeColor: 'black',
        strokeOpacity: 1,
        fillOpacity: 1,
        fillColor: this.vehiculo.fechaHoraDespacho ? 'red' : 'orange',
        labelOrigin: {
          x: 12,
          y: 14
        }
      }
    }
  }
}
</script>

<style>
.carro-numero {
  text-shadow: 1px 0 0 #000, -1px 0 0 #000, 0 1px 0 #000, 0 -2px 0 #000, 1px 1px #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000;
}

</style>

<template>
  <div>
    <v-snackbar
      v-model="snackbar.open"
      tile
      :color="snackbar.color"
      :timeout="snackbar.timeout"
    >
      {{ snackbar.text }}

      <template #action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar.open = false"
        >
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
    <v-dialog
      :value="value"
      width="500"
      @change="$emit('input', $event)"
    >
      <v-form
        ref="generadorTonoForm"
        @submit.prevent="saveTono"
      >
        <v-card>
          <v-card-title>
            {{ edit? 'Editando': 'Creando' }} Tono
            <v-spacer />
            <v-btn
              icon
              @click="$emit('input', false)"
            >
              <v-icon>
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </v-card-title>
          <v-card-subtitle
            v-if="form.message"
            class="error--text"
          >
            {{ form.message }}
          </v-card-subtitle>
          <v-card-text>
            <v-text-field
              v-model="tono.tono"
              label="Nombre Tono (*)"
              :rules="[(v) => !!v || 'Requerido']"
            />
            <v-slider
              v-model="tono.frequency"
              min="40"
              max="6000"
              :label="`Frecuencia: ${tono.frequency} Hz`"
              step="20"
            >
              <template #append>
                <v-text-field
                  v-model="tono.frequency"
                  class="mt-0 pt-0"
                  type="number"
                  style="width: 60px"
                  min="40"
                  max="6000"
                  dense
                  hide-details
                  step="20"
                />
              </template>
            </v-slider>
            <v-slider
              v-model="typeNumber"
              min="0"
              max="3"
              :label="`Tipo: ${tono.type}`"
            />
            <v-slider
              v-model="tono.volume"
              min="0"
              max="100"
              step="5"
              :label="`Volumen: ${tono.volume}`"
            />
            <v-slider
              v-model="tono.duration"
              min="100"
              max="5000"
              step="50"
              :label="`Duracion: ${tono.duration} ms`"
            />
            <v-row justify="space-around">
              <v-btn @click="playTono">
                <v-icon left>
                  {{ icons.mdiPlay }}
                </v-icon>
                Reproducir
              </v-btn>
              <v-btn
                color="primary"
                type="submit"
                :loading="form.loading"
              >
                <v-icon left>
                  {{ icons.mdiContentSaveAll }}
                </v-icon>
                Guardar
              </v-btn>
            </v-row>
          </v-card-text>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { mdiClose, mdiContentSaveAll, mdiPlay } from '@mdi/js'
import { playSound } from '@/utils/sounds'

export default {
  name: 'GeneradorTonosDialog',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    tonoInput: {
      type: Object,
      default: () => {
        return {
          tonoId: null,
          tono: '',
          frequency: 600,
          type: 'sine',
          volume: 10,
          duration: 500
        }
      }
    }
  },
  data () {
    return {
      snackbar: {
        open: false,
        color: 'success',
        timeout: 3000,
        text: ''
      },
      form: {
        message: '',
        loading: false
      },
      icons: {
        mdiPlay,
        mdiClose,
        mdiContentSaveAll
      },
      tono: {
        tonoId: null,
        tono: '',
        frequency: 600,
        type: 'sine',
        volume: 10,
        duration: 500
      }
    }
  },
  computed: {
    edit () {
      return !!this.tono.tonoId
    },
    typeNumber: {
      get () {
        let typeNumber = 0
        switch (this.tono.type) {
          case 'sine': typeNumber = 0; break
          case 'square': typeNumber = 1; break
          case 'sawtooth': typeNumber = 2; break
          case 'triangle': typeNumber = 3; break
        }
        return typeNumber
      },
      set (val) {
        switch (val) {
          case 0: this.tono.type = 'sine'; break
          case 1: this.tono.type = 'square'; break
          case 2: this.tono.type = 'sawtooth'; break
          case 3: this.tono.type = 'triangle'; break
        }
      }
    }
  },
  watch: {
    value (val) {
      if (val) {
        this.tono = JSON.parse(JSON.stringify(this.tonoInput))
        this.$refs.generadorTonoForm?.resetValidation()
        this.form.message = ''
        this.form.loading = false
      }
    }
  },
  methods: {
    saveTono () {
      const valid = this.$refs.generadorTonoForm.validate()
      if (!valid) return
      this.form.loading = true
      this.$store.dispatch('emergencias/saveTono', this.tono)
        .then(({ data }) => {
          this.snackbar.text = data.message
          this.snackbar.color = 'success'
          this.$emit('input', false)
        })
        .catch((error) => {
          let message = ''
          if (error.response) {
            message = error.response.data.message
          } else if (error.request) {
            message = 'Problemas de conexión o servidor no responde'
          } else {
            message = 'Error, contactar administrador'
          }
          this.form.message = message
          this.snackbar.text = message
          this.snackbar.color = 'error'
        })
        .finally(() => {
          this.snackbar.open = true
          this.form.loading = false
        })
    },
    playTono () {
      playSound(this.tono)
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <v-row
    justify="center"
    class="fill-height"
  >
    <EditConductorDialog
      v-model="editConductor.dialog"
      :conductor="editConductor.conductor"
      :vehiculos="vehiculos"
      :tipos-vehiculo-bomberil="tiposVehiculoBomberil"
      :en-compannia-lista="enCompanniaLista"
    />
    <v-snackbar
      v-model="enCompanniaSnackBar.snackbar"
      :timeout="enCompanniaSnackBar.timeout"
      :color="enCompanniaSnackBar.successful? 'success': 'error'"
    >
      {{ enCompanniaSnackBar.message }}
    </v-snackbar>
    <v-col cols="12">
      <v-speed-dial
        fixed
        bottom
        right
      >
        <template #activator>
          <v-btn
            fab
            color="primary"
            @click="openEditConductor()"
          >
            <v-icon>
              {{ icons.mdiPlus }}
            </v-icon>
          </v-btn>
        </template>
      </v-speed-dial>
      <v-card class="pt-2">
        <v-row>
          <v-col>
            <v-data-table
              :headers="table.headers"
              :items="conductoresVehiculos"
            >
              <template #[`item.compannia`]="{item}">
                {{ companniaNumero(item.companniaId) }}
              </template>
              <template #[`item.vehiculos`]="{item}">
                {{ vehiculosDenominacionConductor(item.vehiculosIds) }}
              </template>
              <template #[`item.enCompannia`]="{item}">
                <v-select
                  :value="item.enCompanniaId"
                  :items="enCompanniaLista"
                  item-text="numero"
                  item-value="companniaId"
                  @change="changeEnCompannia(item.conductorId, item.informacionBomberilId, $event)"
                />
              </template>
              <template #[`item.activo`]="{item}">
                {{ item.activo? 'Sí': 'No' }}
              </template>
              <template #[`item.editar`]="{item}">
                <v-btn
                  color="primary"
                  fab
                  x-small
                  @click="openEditConductor(item)"
                >
                  <v-icon>
                    {{ icons.mdiPencil }}
                  </v-icon>
                </v-btn>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import { mdiPencil, mdiPlus } from '@mdi/js'
import EditConductorDialog from '@/components/AdminCarros/EditConductorDialog'

export default {
  name: 'ConductoresPage',
  components: { EditConductorDialog },
  data () {
    return {
      icons: {
        mdiPencil,
        mdiPlus
      },
      enCompanniaSnackBar: {
        snackbar: false,
        timeout: 3000,
        messaage: '',
        errors: [[]],
        successful: false
      },
      editConductor: {
        conductor: null,
        dialog: false
      },
      table: {
        headers: [
          { text: 'RUT', value: 'rut' },
          { text: 'Nombre', value: 'bomberoNombreCompleto' },
          { text: 'Compañía', value: 'compannia' },
          { text: 'Vehículos', value: 'vehiculos' },
          { text: 'En Compañía', value: 'enCompannia' },
          { text: 'Activo', value: 'activo' },
          { text: 'Editar', value: 'editar' }
        ]
      }
    }
  },
  computed: {
    ...mapState('emergencias', ['vehiculos', 'tiposVehiculoBomberil', 'conductoresVehiculos']),
    ...mapState('main', ['compannias']),
    enCompanniaLista () {
      return [
        { companniaId: null, numero: 'Ninguna' },
        ...this.compannias
      ]
    }
  },
  methods: {
    vehiculosDenominacionConductor (vehiculosIds) {
      return vehiculosIds.map(vehiculoId => {
        return this.vehiculos.find((vehiculo) => vehiculo.vehiculoId === vehiculoId)?.denominacion
      }).join(', ')
    },
    companniaNumero (companniaId) {
      return this.compannias.find(compannia => compannia.companniaId === companniaId)?.numero
    },
    openEditConductor (conductor = null) {
      this.editConductor.conductor = conductor
      this.$nextTick(() => {
        this.editConductor.dialog = true
      })
    },
    changeEnCompannia (conductorId, informacionBomberilId, enCompanniaId) {
      const putData = {
        conductorId,
        informacionBomberilId,
        enCompanniaId
      }
      this.$store.dispatch('emergencias/updateConductorVehiculosEnCompannia', putData)
        .then(({ data }) => {
          this.enCompanniaSnackBar.successful = true
          this.enCompanniaSnackBar.message = data.message
        }).catch(error => {
          this.enCompanniaSnackBar.successful = false
          if (error.response) {
            this.enCompanniaSnackBar.errors = error.response.data.errors
            this.enCompanniaSnackBar.message = error.response.data.message
          } else {
            this.enCompanniaSnackBar.message = error.message
            this.enCompanniaSnackBar.errors = error.errors
          }
        }).finally(() => {
          this.enCompanniaSnackBar.snackbar = true
        })
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <v-card>
    <v-card-title class="headline">
      Output
    </v-card-title>
    <v-card-text>
      Tiempos de Respuesta y Ubicaciones
      <ul>
        <li>
          Primera Cia Santiago
          <ul>
            <li>Tiempo de Respuesta [texto]: {{ primeraCiaStgoResponse.durationText }}</li>
            <li>Tiempo de Respuesta [segundos]: {{ primeraCiaStgoResponse.durationValue }}</li>
            <li>Distancia a Recorrer [texto]: {{ primeraCiaStgoResponse.distanceText }}</li>
            <li>Distancia a Recorrer [metros]: {{ primeraCiaStgoResponse.distanceValue }}</li>
            <li>Direccion: {{ primeraCiaStgoResponse.address }}</li>
          </ul>
        </li>
        <li>
          Segunda Cia Santiago
          <ul>
            <li>Tiempo de Respuesta [texto]: {{ segundaCiaStgoResponse.durationText }}</li>
            <li>Tiempo de Respuesta [segundos]: {{ segundaCiaStgoResponse.durationValue }}</li>
            <li>Distancia a Recorrer [texto]: {{ segundaCiaStgoResponse.distanceText }}</li>
            <li>Distancia a Recorrer [metros]: {{ segundaCiaStgoResponse.distanceValue }}</li>
            <li>Direccion: {{ segundaCiaStgoResponse.address }}</li>
          </ul>
        </li>
      </ul>
      <ul>
        <li>
          Junta Nacional de Cuerpos de Bomberos
          <ul>
            <li>Direccion: {{ jncbResponse.address }}</li>
          </ul>
        </li>
      </ul>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'OutputCard',
  props: {
    primeraCiaStgoResponse: {
      type: Object,
      default: () => {}
    },
    segundaCiaStgoResponse: {
      type: Object,
      default: () => {}
    },
    jncbResponse: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style scoped>

</style>

import { helpers } from 'gmap-vue'
const { MapElementFactory } = helpers

export default MapElementFactory({
  name: 'directionsRenderer',

  ctr: () => window.google.maps.DirectionsRenderer,

  events: [],

  mappedProps: {},

  props: {
    origin: { type: Object },
    destination: { type: Object },
    travelMode: { type: String }
  },
  beforeCreate (options) {},
  afterCreate (directionsRenderer) {
    const directionsService = new window.google.maps.DirectionsService()
    const { origin, destination, travelMode } = this
    directionsService.route(
      {
        origin,
        destination,
        travelMode
      },
      (response, status) => {
        if (status !== 'OK') { return }
        directionsRenderer.setDirections(response)
      }
    )
    this.$watch(
      () => [this.origin, this.destination, this.travelMode],
      () => {
        const { origin, destination, travelMode } = this
        if (!origin || !destination || !travelMode) { return }
        directionsService.route(
          {
            origin,
            destination,
            travelMode
          },
          (response, status) => {
            if (status !== 'OK') { return }
            directionsRenderer.setDirections(response)
          }
        )
      }
    )
  }
})

<template>
  <div>
    Cerrando Sesión
  </div>
</template>

<script>

export default {
  name: 'LoginPage',
  mounted () {
    this.$store.dispatch('main/logout')
      .finally(() => {
        this.$router.push('/login')
      })
  }
}
</script>

<style scoped>

</style>

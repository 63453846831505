<template>
  <v-row justify="center">
    <v-col cols="12">
      <v-card>
        <v-card flat>
          <v-card-subtitle>
            <v-row>
              <v-col cols="auto">
                Iniciado: {{ new Date(despacho.fechaHoraInicio).toLocaleString() }}
              </v-col>
              <v-col cols="auto">
                Teléfono Contacto: {{ despacho.telefono }}
              </v-col>
            </v-row>
          </v-card-subtitle>
          <v-divider />
          <v-row>
            <v-col cols="6">
              <DetalleEmergenciaHistorialSection
                :despacho="despacho"
                :tipos-servicio-cuerpo="tiposServicioCuerpo"
              />
            </v-col>
            <v-col
              cols="6"
              class="pl-0 pb-0"
            >
              <MapHistorialSection
                :despacho-local-lat="despacho.lat"
                :despacho-local-lng="despacho.lng"
                :compannias="compannias"
              />
            </v-col>
          </v-row>
        </v-card>
        <v-row>
          <v-col>
            <v-divider />
          </v-col>
        </v-row>
        <v-divider />
        <v-row>
          <v-col
            cols="6"
            class="pr-1"
          >
            <MaterialMayorHistorialSection
              :compannias="compannias"
              :despacho="despacho"
            />
          </v-col>
          <v-divider vertical />
          <v-col
            cols="6"
            class="pl-1"
          >
            <HistorialHistorialSection
              :historial-despacho="despacho.historialDespacho"
            />
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import DetalleEmergenciaHistorialSection from '@/components/HistorialEmergencia/DetalleEmergenciaHistorialSection'
import { mapState } from 'vuex'
import MapHistorialSection from '@/components/HistorialEmergencia/MapHistorialSection'
import MaterialMayorHistorialSection from '@/components/HistorialEmergencia/MaterialMayorHistorialSection'
import HistorialHistorialSection from '@/components/HistorialEmergencia/HistorialHistorialSection'
export default {
  name: 'HistorialEmergenciaPage',
  components: {
    DetalleEmergenciaHistorialSection,
    MapHistorialSection,
    MaterialMayorHistorialSection,
    HistorialHistorialSection
  },
  data () {
    return {
      despacho: {
        despachoId: '',
        tipoServicioCuerpoId: null,
        telefono: '',
        descripcionUbicacion: '',
        detalleEmergencia: '',
        tipoUbicacionId: 1,
        direccion: '',
        calle1: '',
        calle2: '',
        ruta: '',
        rutaKm: 0,
        lat: -33.436260,
        lng: -70.643320,
        respuestasPreguntasCuerpo: [],
        vehiculosDespacho: [],
        historialDespacho: [],
        fechaHoraInicio: null,
        controlada: false,
        cuerpoId: null,
        fechaHoraTermino: null
      }
    }
  },
  computed: {
    ...mapState('emergencias', ['vehiculos', 'tiposServicioCuerpo']),
    ...mapState('main', ['compannias'])
  },
  mounted () {
    this.getDespacho()
  },
  methods: {
    async getDespacho () {
      await this.$store.dispatch('emergencias/getDespacho', this.$route.params.despachoId)
        .then((despacho) => {
          this.despacho = JSON.parse(JSON.stringify(despacho))
        })
    }
  }
}
</script>

<style scoped>

</style>

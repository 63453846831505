<template>
  <v-row justify="center">
    <v-col cols="12">
      <v-card>
        <v-card-text>
          <v-expansion-panels
            flat
            :value="0"
          >
            <v-expansion-panel>
              <v-expansion-panel-header>
                <span class="text-uppercase">
                  Mapa
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pa-0">
                <GmapMap
                  ref="gmap"
                  :zoom="map.zoom"
                  :center="map.center"
                  style="width: inherit; height: 400px"
                >
                  <GmapLayerGrifosEdit
                    v-if="$refs.gmap"
                    ref="gmapLayerGrifosEdit"
                    :render="grifos && map.zoom >= map.zoomGrifos"
                    :grifos="grifos"
                    :map="$refs.gmap"
                  />
                  <GmapMarkerCia
                    v-for="(compannia, index) in compannias"
                    :key="`compannia-${index}`"
                    :compannia="compannia"
                  />
                </GmapMap>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-data-table
            :items="todasFuentesAgua"
            :headers="table.headers"
          >
            <template #[`item.centrar`]="{item}">
              <v-btn
                fab
                color="primary"
                small
                depressed
                @click="centerFuenteAgua(item)"
              >
                <v-icon>
                  {{ icons.mdiCrosshairsGps }}
                </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { mdiPoliceStation, mdiCheck, mdiEye, mdiCrosshairsGps } from '@mdi/js'
import GmapMarkerCia from '@/components/gmaps/GmapMarkerCia'
import { getGoogleMapsAPI } from 'gmap-vue'
import GmapLayerGrifosEdit from '@/components/gmaps/GmapLayerGrifosEdit'

export default {
  name: 'FuentesAguaPage',
  components: { GmapMarkerCia, GmapLayerGrifosEdit },
  data () {
    return {
      icons: {
        mdiCheck,
        mdiEye,
        mdiCrosshairsGps
      },
      tab: 'lista',
      map: {
        center: {
          lat: -33.436260,
          lng: -70.643320
        },
        zoom: 13,
        zoomGrifos: 13
      },
      companniaMarkerIcon: {
        path: mdiPoliceStation,
        scale: 1.5,
        strokeWeight: 0.4,
        strokeColor: 'black',
        strokeOpacity: 1,
        fillColor: 'blue',
        fillOpacity: 1
      },
      table: {
        headers: [
          { text: 'Ubicación', value: 'ubicacion' },
          { text: 'Año', value: 'anio' },
          { text: 'Diametro Grifo', value: 'diamGrifo' },
          { text: 'Diametro Tubo', value: 'diamTub' },
          { text: 'Año', value: 'anio' },
          { text: 'Modelo', value: 'modelo', align: 'center' },
          { text: 'Centrar', value: 'centrar', sortable: false, align: 'center' }
        ]
      }
    }
  },
  computed: {
    ...mapState('main', ['compannias']),
    ...mapState('emergencias', ['fuentesAgua', 'grifos']),
    ...mapGetters('main', ['perfilActivo']),
    google: getGoogleMapsAPI,
    todasFuentesAgua () {
      return [...this.grifos, ...this.fuentesAgua]
    }
  },
  mounted () {
    this.setInitCenter()
  },
  methods: {
    centerFuenteAgua ({ lat, lng }) {
      this.map.center = {
        lat,
        lng
      }
      this.map.zoom = 16
    },
    setInitCenter () {
      const lat = this.perfilActivo.cuerpoLat
      const lng = this.perfilActivo.cuerpoLng
      this.map.center = {
        lat,
        lng
      }
    }
  }

}
</script>

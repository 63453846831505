<template>
  <v-row
    justify="center"
    class="fill-height"
  >
    <v-col cols="12">
      <v-card class="pt-2">
        <v-data-table
          :headers="table.headers"
          :items="vehiculos"
        >
          <template #[`item.editar`]="{item}">
            <v-btn
              color="primary"
              fab
              small
              :to="`/admin-carros/${item.vehiculoId}`"
            >
              <v-icon>
                {{ icons.mdiPencil }}
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from 'vuex'
import { mdiPencil } from '@mdi/js'

export default {
  name: 'CarrosPage',
  data () {
    return {
      icons: {
        mdiPencil
      },
      table: {
        headers: [
          { text: 'Denominación', value: 'denominacion' },
          { text: 'Tipo', value: 'tipoVehiculoBomberil' },
          { text: 'Fecha Ingreso', value: 'fechaIngreso' }/*,
          { text: 'Activo', value: 'activo' },
          { text: 'Editar', value: 'editar' } */
        ]
      }
    }
  },
  computed: {
    ...mapState('emergencias', ['vehiculos', 'tiposVehiculoBomberil', 'conductoresVehiculos'])
  }
}
</script>

<style scoped>

</style>

<template>
  <div class="fill-height">
    <v-app-bar
      fixed
      app
      color="primary"
      clipped-left
      dark
      flat
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      <v-app-bar-nav-icon>
        <v-icon
          class="logo-btn"
          x-large
        >
          $logoBomberos
        </v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title
        class="pl-2"
        v-text="title"
      />
      <v-spacer />
      <div
        class="d-none d-md-block"
      >
        <v-row
          align-content="center"
          justify="end"
        >
          <v-col
            align-self="center"
            md="auto"
          >
            <v-avatar
              size="30px"
            >
              <img
                v-if="urlImagenPerfil"
                :src="urlImagenPerfil"
                alt=""
                style="position: absolute; z-index: 3"
              >
              <v-icon
                large
                style="position: absolute; z-index: 2"
              >
                {{ icons.mdiAccountCircle }}
              </v-icon>
            </v-avatar>
          </v-col>
          <v-col
            class="pr-0"
            md="auto"
          >
            <h1
              class="user-name"
            >
              {{ usuario.usuario }}
            </h1>
            <caption class="user-cargo">
              {{ perfilActivo.cargo }}
            </caption>
          </v-col>
          <v-col
            class="px-0"
            md="auto"
          >
            <v-menu
              offset-y
              open-on-hover
            >
              <template
                #activator="{ on, attrs }"
              >
                <v-btn
                  small
                  text
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>{{ icons.mdiChevronDown }}</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item to="/logout">
                  <v-list-item-title>Cerrar Sesión</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </div>
    </v-app-bar>
    <v-navigation-drawer
      v-model="drawer"
      app
      disable-resize-watcher
      clipped
      class="pa-0 text-start fill-height"
      :mini-variant="miniVariant"
      width="225"
      :mobile-breakpoint="500"
    >
      <v-container class="fill-height pa-0">
        <v-row
          no-gutters
          class="fill-height"
        >
          <v-col align-self="start">
            <v-list>
              <v-list-item
                v-for="(item, i) in items"
                :key="i"
                :to="item.to"
                router
                exact
              >
                <v-list-item-action class="mr-3">
                  <v-icon>{{ icons[item.icon] }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title v-text="item.title" />
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          <v-col align-self="end">
            <v-list class="py-0">
              <v-list-item
                v-for="globalTonos in globalesTonosSonidoSinServicio"
                :key="globalTonos.globalTonoId"
                @click="playSounds(globalTonos.tonos)"
              >
                <v-list-item-action class="mr-3">
                  <v-icon>{{ icons.mdiAlarmBell }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    {{ globalTonos.globalTono }}
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-list-item @click="miniVariant = !miniVariant">
                <v-list-item-action class="mr-3">
                  <v-icon>{{ miniVariant? icons.mdiChevronRight:icons.mdiChevronLeft }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    Reducir Menú
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-tooltip
                top
                color="black"
              >
                <template #activator="{ on, attrs }">
                  <v-list-item
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-list-item-action class="mr-3">
                      <v-icon
                        v-if="webSocketConnection.icon"
                        :color="webSocketConnection.iconColor"
                        left
                      >
                        {{ icons[webSocketConnection.icon] }}
                      </v-icon>
                      <v-progress-circular
                        v-else
                        size="18"
                        indeterminate
                        left
                        class="mr-2"
                      />
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ webSocketConnection.message }}
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
                {{ webSocketConnection.fullMessage }}
              </v-tooltip>
              <v-list-item
                class="pl-3"
                @click="darkTheme = !darkTheme"
              >
                <v-list-item-action class="mr-3">
                  <v-switch
                    :value="darkTheme"
                    :input-value="darkTheme"
                    class="mt-0"
                    dense
                  />
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    Modo Oscuro
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-container>
    </v-navigation-drawer>
    <v-main class="fill-height">
      <v-container
        fluid
        class="fill-height align-content-start"
      >
        <router-view />
      </v-container>
    </v-main>
  </div>
</template>
<script>
import {
  mdiAlert,
  mdiCarCog,
  mdiCarEmergency,
  mdiClipboardTextClock,
  mdiOfficeBuildingCog,
  mdiPlusCircle,
  mdiAccountCircle,
  mdiChevronDown,
  mdiAccountCog,
  mdiWater,
  mdiCheckCircleOutline,
  mdiAlertCircleOutline, mdiChevronLeft, mdiChevronRight, mdiAlarmBell
} from '@mdi/js'
import { mapGetters, mapState } from 'vuex'
import { playSounds } from '@/utils/sounds'

export default {
  name: 'MainLayout',
  data () {
    return {
      playSounds,
      icons: {
        mdiPlusCircle,
        mdiCarEmergency,
        mdiClipboardTextClock,
        mdiCarCog,
        mdiOfficeBuildingCog,
        mdiAlert,
        mdiAccountCircle,
        mdiChevronDown,
        mdiAccountCog,
        mdiWater,
        mdiCheckCircleOutline,
        mdiAlertCircleOutline,
        mdiChevronLeft,
        mdiChevronRight,
        mdiAlarmBell
      },
      miniVariant: false,
      drawer: true,
      fixed: false
    }
  },
  computed: {
    ...mapGetters('emergencias', ['globalesTonosSonido']),
    globalesTonosSonidoSinServicio () {
      return this.globalesTonosSonido.filter((globalTonos) => {
        return globalTonos.globalTonoId !== 1
      })
    },
    items () {
      return this.$store.state.main.menus
    },
    title () {
      if (this.$store.state.main.title) {
        return this.$store.state.main.title
      }
      return 'Sistema de Despacho'
    },
    ...mapState('main', ['usuario', 'webSocketConnection']),
    ...mapGetters('main', ['perfilActivo']),
    urlImagenPerfil () {
      if (!this.usuario) return null
      else if (this.usuario.imagen) return null
      const usuarioImagen = this.usuario.imagen
      if (usuarioImagen) {
        return `${process.env.MIX_OLD_APP_URL}/registro_nacional/src/update/bombero/${usuarioImagen}`
      } else {
        return false
      }
    },
    darkTheme: {
      set (dark) {
        this.$vuetify.theme.dark = dark
        this.$store.dispatch('main/setTheme', dark)
      },
      get () {
        return this.$vuetify.theme.dark
      }
    }
  }
}
</script>

<style>
.user-name{
  font-size: small;
}

.user-cargo{
  font-size: smaller;
  white-space: nowrap;
}
</style>

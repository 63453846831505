import Echo from 'laravel-echo'
import axios from './axios'

window.Pusher = require('pusher-js')

let laravelEcho = null

export function createEcho () {
  laravelEcho = new Echo({
    broadcaster: 'pusher',
    key: process.env.VUE_APP_PUSHER_APP_KEY,
    wsHost: process.env.VUE_APP_PUSHER_HOST,
    wsPort: process.env.VUE_APP_PUSHER_PORT,
    wssPort: process.env.VUE_APP_PUSHER_PORT,
    forceTLS: process.env.VUE_APP_PUSHER_FORCE_TLS === 'true',
    encrypted: true,
    disableStats: true,
    enabledTransports: ['ws', 'wss'],
    authorizer: (channel, options) => {
      return {
        authorize: (socketId, callback) => {
          axios.post('/broadcasting/auth', {
            socket_id: socketId,
            channel_name: channel.name
          })
            .then(response => {
              // eslint-disable-next-line node/no-callback-literal
              callback(false, response.data)
            })
            .catch(error => {
              // eslint-disable-next-line node/no-callback-literal
              callback(true, error)
            })
        }
      }
    }
  })
}

createEcho('')

export default laravelEcho

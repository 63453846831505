<template>
  <div>
    <LoginForm />
  </div>
</template>

<script>
import LoginForm from '@/components/common/LoginForm'
import { mapGetters } from 'vuex'

export default {
  name: 'LoginPage',
  components: {
    LoginForm
  },
  computed: {
    ...mapGetters('main', ['conPerfil'])
  },
  created () {
    if (this.conPerfil) {
      this.$router.push('/')
    }
  }
}
</script>

<style scoped>

</style>

export function inputOnlyNumber (event, type = 'integer') {
  const validKeyEvents = ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown', 'Delete', 'Backspace', 'Tab', 'Enter']
  if (!validKeyEvents.includes(event.code)) {
    if (type === 'integer') {
      if (event.key.search(/\+|-|\d/) === -1) {
        event.preventDefault()
      }
    } else if (type === 'float') {
      if (event.key.search(/\+|-|,|\.|\d/) === -1) {
        event.preventDefault()
      }
    }
  }
}

<template>
  <v-row justify="center">
    <v-col cols="12">
      <v-card>
        <v-card-text>
          <v-tabs v-model="tab">
            <v-tab key="vehiculo-servicio">
              Vehículos por Servicio
            </v-tab>
            <v-tab key="preguntas-servicio">
              Preguntas Por Servicio
            </v-tab>
            <v-tab key="tonos-radiales-configuracion">
              Configuración Tonos Radiales
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item key="vehiculo-servicio">
              <VehiculosPorServicioTab />
            </v-tab-item>
            <v-tab-item key="preguntas-servicio">
              <PreguntasPorServicioTab />
            </v-tab-item>
            <v-tab-item key="tonos-radiales-configuracion">
              <TonosTab />
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import VehiculosPorServicioTab from '@/components/AdminCuerpo/VehiculosPorServicioTab'
import PreguntasPorServicioTab from '@/components/AdminCuerpo/PreguntasPorServicioTab'
import TonosTab from '@/components/AdminCuerpo/TonosTab'

export default {
  name: 'EmergenciasActivas',
  components: {
    TonosTab,
    PreguntasPorServicioTab,
    VehiculosPorServicioTab
  },
  data () {
    return {
      tab: 0
    }
  },
  watch: {
    tab (val) {
      this.$router.replace(
        {
          query: Object.assign({ ...this.$route.query }, { tab: val })
        },
        () => {}
      )
    }
  },
  mounted () {
    const tab = this.$route.query.tab
    if (tab && parseInt(tab) < 3) {
      this.tab = parseInt(tab)
    }
  }

}
</script>

<template>
  <v-row justify="center">
    <v-col cols="12">
      <v-card>
        <v-form
          ref="formNuevoDespacho"
          @submit.prevent="createNuevoDespacho"
        >
          <v-card
            flat
            class="pt-2"
          >
            <v-row>
              <v-col cols="6">
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        v-model="despacho.telefono"
                        v-mask="'+## # #### ####'"
                        :prepend-icon="icons.mdiPhone"
                        label="Teléfono Contacto (*)"
                        :rules="[(v) => !!v || 'Requerido']"
                      >
                        <template #append-outer>
                          <v-btn
                            color="primary"
                            fab
                            small
                            elevation="0"
                          >
                            <v-icon>
                              {{ icons.mdiMapMarkerRadius }}
                            </v-icon>
                          </v-btn>
                        </template>
                      </v-text-field>
                    </v-col>
                  </v-row>
                  <v-row v-show="false">
                    <v-col cols="12">
                      <v-btn-toggle
                        v-model="despacho.tipoUbicacionId"
                        mandatory
                      >
                        <v-btn :value="1">
                          Dirección
                        </v-btn>
                        <v-btn :value="2">
                          Intersección
                        </v-btn>
                        <v-btn :value="3">
                          Ruta/Km
                        </v-btn>
                      </v-btn-toggle>
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="despacho.tipoUbicacionId === 1"
                    class="mt-0"
                  >
                    <v-col cols="12">
                      <GmapAutocomplete
                        class="introInput"
                        @place_changed="setPlace"
                        @keydown="preventOnEnter"
                      >
                        <template #default="slotProps">
                          <v-text-field
                            ref="input"
                            v-model="despacho.direccion"
                            placeholder=""
                            label="Dirección (*)"
                            :rules="[(v) => !!v || 'Requerido']"
                            :messages="`Lat: ${despacho.lat}, Lng: ${despacho.lng}`"
                            @keydown="preventOnEnter"
                            @listeners="slotProps.listeners"
                            @attrs="slotProps.attrs"
                          />
                        </template>
                      </GmapAutocomplete>
                    </v-col>
                  </v-row>
                  <v-row
                    v-else-if="despacho.tipoUbicacionId === 2"
                    class="align-baseline"
                  >
                    <v-col cols="5">
                      <v-text-field
                        v-model="despacho.calle1"
                        label="Calle 1"
                        :rules="[(v) => !!v || 'Requerido']"
                      />
                    </v-col>
                    <v-col cols="2">
                      con
                    </v-col>
                    <v-col cols="5">
                      <v-text-field
                        v-model="despacho.calle2"
                        label="Calle 2"
                        :rules="[(v) => !!v || 'Requerido']"
                      />
                    </v-col>
                  </v-row>
                  <v-row
                    v-else-if="despacho.tipoUbicacionId === 3"
                    class="align-baseline"
                  >
                    <v-col cols="6">
                      <v-text-field
                        v-model="despacho.ruta"
                        label="Ruta"
                        :rules="[(v) => !!v || 'Requerido']"
                      />
                    </v-col>
                    <v-col cols="6">
                      <v-text-field
                        v-model="despacho.rutaKm"
                        label="Kilometro"
                        :rules="[(v) => !!v || 'Requerido']"
                      />
                    </v-col>
                  </v-row>
                  <v-row class="mt-0">
                    <v-col cols="12">
                      <v-textarea
                        v-model="despacho.descripcionUbicacion"
                        rows="2"
                        label="Descripción Ubicación"
                        hide-details
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                        v-model="despacho.detalleEmergencia"
                        rows="2"
                        label="Detalle Emergencia"
                        hide-details
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
              <v-col
                cols="6"
                class="pl-0 pb-0"
              >
                <GmapMap
                  ref="gmap"
                  :zoom="map.zoom"
                  :center="map.center"
                  style="width: inherit; height: 400px"
                >
                  <GmapMarker
                    :position="{ lat: despacho.lat, lng: despacho.lng}"
                    :draggable="true"
                    @update:position="setMarker($event)"
                  />
                  <GmapMarkerCia
                    v-for="(compannia, index) in compannias"
                    :key="`compannia-${index}`"
                    :compannia="compannia"
                  />
                </GmapMap>
              </v-col>
            </v-row>
          </v-card>
          <v-row>
            <v-col>
              <v-divider />
            </v-col>
          </v-row>
          <v-card flat>
            <v-card-text>
              <v-row>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="despacho.tipoServicioCuerpoId"
                    label="Código Acto Servicio (*)"
                    :items="tiposServicioCuerpo"
                    item-value="tipoServicioCuerpoId"
                    :item-text="(item) => `${item.codigo} - ${item.descripcion}`"
                    :rules="[(v) => !!v || 'Requerido']"
                  >
                    <template #append-outer>
                      <v-btn
                        type="submit"
                        color="success"
                        elevation="0"
                      >
                        <v-icon left>
                          {{ icons.mdiCheckCircle }}
                        </v-icon>
                        Confirmar
                      </v-btn>
                    </template>
                  </v-autocomplete>
                  <v-divider />
                  <TiposServicioFiltradosTableSection
                    :tipos-servicio-ids-respuestas-preguntas="tiposServicioIdsRespuestasPreguntas"
                    @select-codigo-acto-servicio="selectCodigoActoServicio"
                  />
                </v-col>
                <v-divider vertical />
                <v-col
                  cols="6"
                  class="pa-0"
                >
                  <PreguntasEmergenciaSection
                    :edit="true"
                    :create="true"
                    :respuestas-preguntas.sync="despacho.respuestasPreguntasCuerpo"
                    :tipos-servicio-ids-respuestas-preguntas="tiposServicioIdsRespuestasPreguntas"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-form>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import { mdiMapMarkerRadius, mdiFire, mdiCheckCircle, mdiPhone } from '@mdi/js'
import { getGoogleMapsAPI } from 'gmap-vue'
import { mapGetters, mapState } from 'vuex'
import GmapMarkerCia from '@/components/gmaps/GmapMarkerCia'
import PreguntasEmergenciaSection from '@/components/PreguntasEmergenciaSection'
import TiposServicioFiltradosTableSection from '@/components/TiposServicioFiltradosTableSection'

export default {
  name: 'NuevaEmergenciaPage',
  components: { TiposServicioFiltradosTableSection, PreguntasEmergenciaSection, GmapMarkerCia },
  data () {
    return {
      icons: {
        mdiMapMarkerRadius,
        mdiFire,
        mdiCheckCircle,
        mdiPhone
      },
      place: '',
      map: {
        center: {
          lat: -33.436260,
          lng: -70.643320
        },
        zoom: 13
      },
      despacho: {
        tipoServicioCuerpoId: null,
        telefono: '+56',
        descripcionUbicacion: '',
        detalleEmergencia: '',
        tipoUbicacionId: 1,
        direccion: '',
        calle1: '',
        calle2: '',
        ruta: '',
        rutaKm: 0,
        lat: -33.436260,
        lng: -70.643320,
        respuestasPreguntasCuerpo: []
      }
    }
  },
  computed: {
    preguntasVisibles () {
      return this.preguntas
    },
    google: getGoogleMapsAPI,
    ...mapGetters('main', ['perfilActivo']),
    ...mapState('emergencias', ['tiposServicioCuerpo', 'preguntas']),
    ...mapState('main', ['compannias']),
    tiposServicioIdsRespuestasPreguntas () {
      const tiposServicioCuerpoIds = this.tiposServicioCuerpo.map(({ tipoServicioCuerpoId }) => tipoServicioCuerpoId)
      const respuestasPreguntas = this.despacho.respuestasPreguntasCuerpo
      return this.preguntas.reduce((acc, { preguntaId, respuestas }) => {
        const respuestaIdCurrentPregunta = respuestasPreguntas.find((respuestaPregunta) => {
          return respuestaPregunta.preguntaId === preguntaId
        })?.respuestaId
        if (!respuestaIdCurrentPregunta) return acc
        const respuestaCurrentPregunta = respuestas.find(({ respuestaId }) => respuestaId === respuestaIdCurrentPregunta)
        return acc.filter(val => {
          return respuestaCurrentPregunta.tiposServicioCuerpoIds.includes(val)
        })
      }, tiposServicioCuerpoIds)
    }
  },
  mounted () {
    this.setInitCenter()
    this.addTrafficLayer()
  },
  methods: {
    setInitCenter () {
      const lat = this.perfilActivo.cuerpoLat
      const lng = this.perfilActivo.cuerpoLng
      this.map.center = {
        lat,
        lng
      }
      this.despacho.lat = lat
      this.despacho.lng = lng
    },
    preventOnEnter (event) {
      if (event.key === 'Enter') {
        event.preventDefault()
      }
    },
    addTrafficLayer () {
      const map = this.$refs.gmap
      map.$mapPromise.then((map) => {
        const trafficLayer = new this.google.maps.TrafficLayer()
        trafficLayer.setMap(map)
      })
    },
    setMarker ({ lat, lng }) {
      this.despacho.lat = lat
      this.despacho.lng = lng
      const geocoder = new this.google.maps.Geocoder()
      geocoder.geocode({ location: { lat, lng } })
        .then((response) => {
          this.despacho.direccion = response.results[0].formatted_address
        })
    },
    setPlace (place) {
      if (!Object.prototype.hasOwnProperty.call(place, 'place_id')) {
        this.place = null
      } else {
        this.place = place
        this.despacho.direccion = this.place.formatted_address
        this.despacho.lat = this.place.geometry.location.lat()
        this.despacho.lng = this.place.geometry.location.lng()
      }
    },
    selectCodigoActoServicio ({ tipoServicioCuerpoId }) {
      this.despacho.tipoServicioCuerpoId = tipoServicioCuerpoId
    },
    createNuevoDespacho () {
      const valid = this.$refs.formNuevoDespacho.validate()
      if (!valid) { return }
      this.$store.dispatch('emergencias/storeDespacho', this.despacho)
        .then((despachoId) => {
          this.$router.push(`/emergencia-activa/${despachoId}`)
        })
    }
  }
}
</script>

<style>
</style>

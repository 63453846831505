<template>
  <v-card>
    <v-card-title>
      Mapa
    </v-card-title>
    <v-card-text>
      <v-switch
        :false-value="0"
        :true-value="1"
        :label="selectorOrigin === 0? 'Primera Cia': 'Segunda Cia'"
        :value="selectorOrigin"
        @change="(v) => $emit('update:selector-origin', v)"
      />
      <GmapMap
        ref="maps"
        :zoom="13"
        :center="origin"
        style="width: inherit; height: 400px"
      >
        <DirectionsRenderer
          :origin="origin"
          :destination="destination"
          travel-mode="DRIVING"
        />
      </GmapMap>
    </v-card-text>
  </v-card>
</template>

<script>
import DirectionsRenderer from '@/components/DirectionsRenderer'

export default {
  name: 'MapRouteComponent',
  components: { DirectionsRenderer },
  props: {
    selectorOrigin: {
      type: Number,
      default: 0
    },
    origin: {
      type: Object,
      default: () => {
        return { lat: 0, lng: 0 }
      }
    },
    destination: {
      type: Object,
      default: () => {
        return { lat: 0, lng: 0 }
      }
    }
  },
  data () {
    return {
      service: null,
      renderer: null
    }
  }
}
</script>

<style scoped>

</style>

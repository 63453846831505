<template>
  <v-card flat>
    <v-card-title>
      Material Mayor
      <v-spacer />
      <v-tooltip
        top
        color="black"
      >
        <template #activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            tile
            depressed
            color="warning"
            v-on="on"
            @click="autoSeleccionarVehiculos(1)"
          >
            <v-icon>
              {{ icons.mdiAutoFix }}
            </v-icon>
          </v-btn>
        </template>
        Autoseleccionar vehículos por tipo de servicio y tiempo de respuesta.
        <ul>
          <li>Un click: preselección (amarillo)</li>
          <li>Doble click: selección (rojo)</li>
        </ul>
        <br>
        Recomendaciones [Tipo Vehículo (seleccionados/recomendados): Denominaciones Recomendadas]:
        <ul>
          <li
            v-for="(cantidadRecomendado) in vehiculosRecomendadosPorTipoVehiculo"
            :key="cantidadRecomendado.tipoVehiculoBomberilId"
          >
            {{ `${cantidadRecomendado.tipoVehiculoBomberil} (${cantidadRecomendado.cantidadSeleccionados}/${cantidadRecomendado.cantidad})` }}:
            {{ cantidadRecomendado.vehiculosRecomendados.map(({denominacion}) => denominacion).join(', ') }}
          </li>
        </ul>
      </v-tooltip>
      <v-tooltip
        top
        color="black"
      >
        <template #activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            tile
            depressed
            color="secondary"
            :loading="loadingMatrix"
            v-on="on"
            @click="updateMatrix"
          >
            <v-icon>
              {{ icons.mdiWebRefresh }}
            </v-icon>
          </v-btn>
        </template>
        Actualizar Tiempos y Distancias para llegar a la emergencia.
      </v-tooltip>
      <v-tooltip
        top
        color="black"
      >
        <template #activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            tile
            depressed
            color="primary"
            :disabled="!vehiculosDespachoChanged"
            v-on="on"
            @click="updateDespacho('vehiculosDespacho')"
          >
            <v-icon left>
              {{ icons.mdiCheck }}
            </v-icon>
            Confirmar Despachos
          </v-btn>
        </template>
        Confirma el despacho, envía notificaciones y sincroniza con servidor
      </v-tooltip>
      <v-tooltip
        top
        color="black"
      >
        <template #activator="{ on, attrs}">
          <v-btn
            icon
            v-bind="attrs"
            :disabled="!vehiculosDespachoChanged"
            v-on="on"
            @click="revertChange('vehiculosDespacho')"
          >
            <v-icon>
              {{ icons.mdiUndo }}
            </v-icon>
          </v-btn>
        </template>
        Vuelve al estado original de la información
      </v-tooltip>
      <v-tooltip
        right
        color="black"
      >
        <template #activator="{ on, attrs }">
          <v-btn
            v-bind="attrs"
            icon
            v-on="on"
          >
            <v-icon>
              {{ icons.mdiHelpCircleOutline }}
            </v-icon>
          </v-btn>
        </template>
        <ul>
          <li>
            Un click para notificar pre-despacho (amarillo)
          </li>
          <li>
            Dos clicks para notificar despacho (rojo)
          </li>
          <li>
            Ya seleccionado, un click para desmarcar (verde)
          </li>
        </ul>
      </v-tooltip>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          class="pt-0"
        >
          <v-simple-table dense>
            <template #default>
              <thead>
                <tr>
                  <th class="text-center table-fit-content px-0">
                    Compañía
                  </th>
                  <th class="text-left">
                    Vehículos
                  </th>
                </tr>
              </thead>
              <tbody class="table-no-hover">
                <tr
                  v-for="compannia in vehiculosPorCompannia"
                  :key="`compannia-vehiculos-${compannia.companniaId}`"
                >
                  <td class="text-center table-fit-content px-0">
                    {{ compannia.numero }}
                  </td>
                  <td class="px-0">
                    <v-tooltip
                      v-for="vehiculo in compannia.vehiculos"
                      :key="vehiculo.vehiculoId"
                      top
                      color="black"
                    >
                      <template #activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          :value="vehiculo.vehiculoId"
                          outlined
                          class="ma-2 px-3"
                          large
                          :color="colorVehiculoSeleccionado(vehiculo.vehiculoId)"
                          :class="{parpadea: vehiculoEnSeleccionados(vehiculo.vehiculoId)}"
                          v-on="on"
                          @dblclick="changeVehiculosSeleccionados(vehiculo.vehiculoId, 2)"
                          @click="changeVehiculosSeleccionados(vehiculo.vehiculoId, 1)"
                        >
                          <v-row align="center">
                            <v-col>
                              {{ vehiculo.denominacion }}
                            </v-col>
                            <v-divider vertical />
                            <v-col>
                              <v-row>
                                <v-col class="py-0">
                                  <span>{{ vehiculo.matrix.distance.text }}</span>
                                </v-col>
                              </v-row>
                              <v-row>
                                <v-col class="py-0">
                                  <span>{{ vehiculo.matrix.duration.text }}</span>
                                </v-col>
                              </v-row>
                            </v-col>
                          </v-row>
                        </v-btn>
                      </template>
                      {{ vehiculo.tipoVehiculoBomberil }}
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiCheck, mdiHelp, mdiHelpCircleOutline, mdiUndo, mdiAutoFix, mdiWebRefresh } from '@mdi/js'
import { mapState } from 'vuex'

export default {
  name: 'MaterialMayorSection',
  props: {
    compannias: {
      type: Array,
      default: () => []
    },
    vehiculosMatrix: {
      type: Array,
      default: () => []
    },
    vehiculosSeleccionados: {
      type: Array,
      default: () => []
    },
    despachoLocal: {
      type: Object,
      default: () => {
        return {}
      }
    },
    despachoGuardado: {
      type: Object,
      default: () => {
        return {}
      }
    },
    tipoServicioCuerpoId: {
      type: Number,
      default: null
    },
    loadingMatrix: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      icons: {
        mdiCheck,
        mdiHelp,
        mdiHelpCircleOutline,
        mdiUndo,
        mdiAutoFix,
        mdiWebRefresh
      }
    }
  },
  computed: {
    vehiculosDespachoChanged () {
      return JSON.stringify(this.despachoLocal.vehiculosDespacho) !== JSON.stringify(this.despachoGuardado.vehiculosDespacho)
    },
    vehiculosPorCompannia () {
      return this.compannias.map((compannia) => {
        const vehiculos = this.vehiculosMatrix.filter(({ companniaId }) => companniaId === compannia.companniaId)
        return {
          ...compannia,
          vehiculos
        }
      })
    },
    ...mapState('emergencias', ['cantidadTiposVehiculosPorTipoServicio', 'tiposVehiculoBomberil']),
    vehiculosRecomendadosPorTipoVehiculo () {
      return this.cantidadTiposVehiculosPorTipoServicio
        .filter(({ tipoServicioCuerpoId }) => this.tipoServicioCuerpoId === tipoServicioCuerpoId)
        .map((cantidadTiposVehiculos) => {
          const tipoVehiculoBomberilObj = this.tiposVehiculoBomberil.find(({ tipoVehiculoBomberilId }) => {
            return tipoVehiculoBomberilId === cantidadTiposVehiculos.tipoVehiculoBomberilId
          })
          const tipoVehiculoBomberil = tipoVehiculoBomberilObj.tipoVehiculoBomberil
          const seleccionadosTipoVehiculo = this.vehiculosSeleccionados.filter(({ tipoVehiculoBomberilId }) => {
            return tipoVehiculoBomberilId === cantidadTiposVehiculos.tipoVehiculoBomberilId
          })
          const vehiculosRecomendados = this.vehiculosMatrix.filter(({ tipoVehiculoBomberilId }) => {
            return tipoVehiculoBomberilId === cantidadTiposVehiculos.tipoVehiculoBomberilId
          }).sort((a, b) => {
            return a.matrix.duration.value > b.matrix.duration.value
          }).slice(0, cantidadTiposVehiculos.cantidad)
          return {
            ...cantidadTiposVehiculos,
            tipoVehiculoBomberil,
            cantidadSeleccionados: seleccionadosTipoVehiculo.length,
            vehiculosRecomendados
          }
        })
    }
  },
  methods: {
    updateMatrix () {
      this.$emit('update-matrix')
    },
    autoSeleccionarVehiculos (clicks) {
      this.vehiculosRecomendadosPorTipoVehiculo.forEach(({ cantidad, cantidadSeleccionados, vehiculosRecomendados }) => {
        if (cantidad <= cantidadSeleccionados) return
        const cantidadAutoSeleccion = cantidad - cantidadSeleccionados
        vehiculosRecomendados.slice(0, cantidadAutoSeleccion).forEach(({ vehiculoId }) => {
          this.changeVehiculosSeleccionados(vehiculoId, clicks)
        })
      })
    },
    changeVehiculosSeleccionados (vehiculoId, clicks) {
      this.$emit('change-vehiculos-seleccionados', { vehiculoId, clicks })
    },
    revertChange (change) {
      this.$emit('revert-change', change)
    },
    updateDespacho (change) {
      this.$emit('update-despacho', change)
    },
    colorVehiculoSeleccionado (vehiculoId) {
      const vehiculo = this.despachoLocal.vehiculosDespacho.find(vehiculo => vehiculo.vehiculoId === vehiculoId)
      if (!vehiculo || vehiculo.estadoVehiculoDespacho !== 'activo') return 'success'
      if (vehiculo.fechaHoraDespacho !== null) return 'error'
      if (vehiculo.fechaHoraPredespacho !== null) return 'warning'
    },
    vehiculoEnSeleccionados (vehiculoId) {
      return this.vehiculosSeleccionados.some(vehiculo => vehiculo.vehiculoId === vehiculoId && vehiculo.estadoVehiculoDespacho !== 'innactivo')
    }
  }
}
</script>

<style scoped>

</style>

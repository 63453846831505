<template>
  <v-dialog
    :value="value"
    width="1200"
    @input="$emit('input', $event)"
  >
    <CardFormHelper
      :errors="form.errors"
      :show-message.sync="form.showMessage"
      :is-loading="form.isLoading"
      :message="form.message"
      :was-successful="form.wasSuccessful"
      :message-timer="form.messageTimer"
      @click-ready="$emit('input', false)"
    >
      <template #top>
        <v-card-title>
          {{ editar? 'Editar': 'Crear' }} Conductor
          <v-spacer />
          <v-icon @click="$emit('input', false)">
            {{ icons.mdiClose }}
          </v-icon>
        </v-card-title>
      </template>
      <template #bottom>
        <v-card-text>
          <v-form
            ref="saveConductorForm"
            @submit.prevent="saveConductor"
          >
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-if="editar"
                  label="Bombero"
                  :value="data.bomberoNombreCompleto"
                  disabled
                />
                <v-autocomplete
                  v-else
                  :search-input.sync="searchBomberos.rutNombreBombero"
                  :items="searchBomberos.items"
                  append
                  :loading="searchBomberos.loading"
                  no-filter
                  return-object
                  :item-disabled="(item) => !!item.conductor"
                  item-value="informacionBomberilId"
                  label="Bombero (Nombre o Rut sin puntos) (*)"
                  :rules="[(v) => !!v || 'Requerido']"
                  @input="selectBombero($event)"
                >
                  <template #selection="{item}">
                    {{ item.bomberoNombreCompleto }}
                  </template>
                  <template #item="{item}">
                    {{ item.bomberoNombreCompleto }} - {{ item.rut }} - {{ item.cargo }}
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                cols="6"
                md="3"
              >
                <v-select
                  v-model="data.enCompanniaId"
                  label="En Compañía"
                  :items="enCompanniaLista"
                  item-text="numero"
                  item-value="companniaId"
                />
              </v-col>
              <v-col
                cols="6"
                md="3"
              >
                <v-switch
                  v-model="data.activo"
                  label="Activo"
                />
              </v-col>
              <v-col cols="12">
                <v-simple-table>
                  <thead>
                    <tr>
                      <th>
                        Tipo Vehículo
                      </th>
                      <th>
                        Vehículos
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(tipoVehiculoBomberil, index) in vehiculosPorTipo"
                      :key="`vehiculo-por-tipo-${index}`"
                    >
                      <td>
                        {{ tipoVehiculoBomberil.tipoVehiculoBomberil }}
                      </td>
                      <td class="flex">
                        <v-btn-toggle
                          v-model="data.vehiculosIds"
                          multiple
                          dense
                          tile
                          color="primary"
                        >
                          <v-btn
                            v-for="(vehiculo) in tipoVehiculoBomberil.vehiculos"
                            :key="`vehiculo-por-tipo-${index}-vehiculo-${vehiculo.vehiculoId}`"
                            :value="vehiculo.vehiculoId"
                          >
                            {{ vehiculo.denominacion }}
                          </v-btn>
                        </v-btn-toggle>
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </v-col>
            </v-row>
            <v-row justify="end">
              <v-col cols="auto">
                <v-btn
                  color="primary"
                  type="submit"
                >
                  <v-icon left>
                    {{ icons.mdiContentSave }}
                  </v-icon>
                  Guardar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </template>
    </CardFormHelper>
  </v-dialog>
</template>

<script>
import { mdiContentSave, mdiClose } from '@mdi/js'
import CardFormHelper from '@/components/common/CardFormHelper'

export default {
  name: 'EditConductorDialog',
  components: { CardFormHelper },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    conductor: {
      type: Object,
      default: null
    },
    vehiculos: {
      type: Array,
      default: () => []
    },
    tiposVehiculoBomberil: {
      type: Array,
      default: () => []
    },
    enCompanniaLista: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      icons: {
        mdiContentSave,
        mdiClose
      },
      form: {
        valid: true,
        isLoading: false,
        showMessage: false,
        errors: {},
        message: '',
        wasSuccessful: false,
        messageTimer: 3000
      },
      searchBomberos: {
        items: [],
        loading: false,
        abortController: null,
        rutNombreBombero: ''
      },
      data: {
        bomberoNombreCompleto: '',
        companniaId: null,
        informacionBomberilId: 0,
        vehiculosIds: [],
        activo: true,
        enCompanniaId: null
      }
    }
  },
  computed: {
    editar () {
      return !!this.conductor
    },
    vehiculosPorTipo () {
      return this.tiposVehiculoBomberil.reduce((acc, tipoVehiculoBomberil) => {
        const vehiculos = this.vehiculos.filter(({ tipoVehiculoBomberilId }) => {
          return tipoVehiculoBomberilId === tipoVehiculoBomberil.tipoVehiculoBomberilId
        })
        if (vehiculos.length > 0) {
          const tipoVehiculoBomberilAcc = {
            ...tipoVehiculoBomberil,
            vehiculos
          }
          acc.push(tipoVehiculoBomberilAcc)
        }
        return acc
      }, [])
    }
  },
  watch: {
    'searchBomberos.rutNombreBombero' (val) {
      this.getSearchBombero(val)
    },
    value (val) {
      if (val && this.editar) {
        this.searchBomberos.rutNombreBombero = this.conductor.bomberoNombreCompleto
        this.data.bomberoNombreCompleto = this.conductor.bomberoNombreCompleto
        this.data.informacionBomberilId = this.conductor.informacionBomberilId
        this.data.companniaId = this.conductor.companniaId
        this.data.vehiculosIds = this.conductor.vehiculosIds
        this.data.activo = this.conductor.activo
        this.data.enCompanniaId = this.conductor.enCompanniaId
      } else {
        this.data.activo = true
      }
    }
  },
  methods: {
    getSearchBombero (rutNombreBombero) {
      if (this.searchBomberos.abortController) {
        this.searchBomberos.abortController.abort()
      }
      if (!rutNombreBombero) {
        this.searchBomberos.items = []
        return
      }
      this.searchBomberos.loading = true
      const params = {
        rutNombreBombero
      }
      this.searchBomberos.abortController = new AbortController()
      this.$axios.get('/admin-carros/buscar-bombero', {
        params,
        signal: this.searchBomberos.abortController.signal
      })
        .then(({ data }) => {
          this.searchBomberos.items = data.bomberos
          this.searchBomberos.loading = false
        })
    },
    selectBombero ({ informacionBomberilId, companniaId, bomberoNombreCompleto, rut }) {
      this.data.informacionBomberilId = informacionBomberilId
      this.data.companniaId = companniaId
      this.data.bomberoNombreCompleto = bomberoNombreCompleto
      this.data.rut = rut
    },
    saveConductor () {
      const valid = this.$refs.saveConductorForm.validate()
      if (!valid) return
      const data = this.data
      let action = null
      this.form.loading = true
      if (this.editar) {
        const putData = {
          conductorId: this.conductor.conductorId,
          ...data
        }
        action = this.$store.dispatch('emergencias/updateConductorVehiculos', putData)
      } else {
        action = this.$store.dispatch('emergencias/storeConductorVehiculos', data)
      }
      action
        .then(({ data }) => {
          this.form.wasSuccessful = true
          this.form.message = data.message
          setTimeout(() => {
            this.$emit('input', false)
          }, this.form.messageTimer)
        }).catch(error => {
          this.form.wasSuccessful = false
          if (error.response) {
            this.form.errors = error.response.data.errors
            this.form.message = error.response.data.message
            this.form.isLoading = false
          } else {
            this.form.message = error.message
            this.form.errors = error.errors
          }
        }).finally(() => {
          this.form.showMessage = true
          this.form.isLoading = false
        })
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <v-card>
    <v-snackbar
      v-model="snackbar.open"
      tile
      :color="snackbar.color"
      :timeout="snackbar.timeout"
    >
      {{ snackbar.text }}

      <template #action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="snackbar.open = false"
        >
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
    <v-menu
      v-model="selectedChip.open"
      :activator="selectedChip.element"
      offset-y
      top
    >
      <v-card color="black">
        <v-card-subtitle class="pa-1 white--text">
          <ul>
            <li>
              <strong>Tono: </strong>{{ selectedChip.tono.tono }}
            </li>
            <li>
              <strong>Frecuencia: </strong>{{ selectedChip.tono.frequency }}
            </li>
            <li>
              <strong>Tipo: </strong>{{ selectedChip.tono.type }}
            </li>
            <li>
              <strong>Volumen: </strong>{{ selectedChip.tono.volume }}
            </li>
            <li>
              <strong>Duración: </strong>{{ selectedChip.tono.duration }}
            </li>
          </ul>
        </v-card-subtitle>
      </v-card>
    </v-menu>
    <v-card-text class="pa-0">
      <v-simple-table>
        <thead>
          <tr>
            <th colspan="2">
              Servicio
            </th>
            <v-divider
              style="height: 30px"
              inset
              vertical
            />
            <th colspan="3">
              Tonos
            </th>
          </tr>
          <tr>
            <th>
              Código
            </th>
            <th>
              Servicio
            </th>
            <v-divider
              style="height: 30px"
              inset
              vertical
            />
            <th>
              Seleccionados
            </th>
            <th>
              Línea de tiempo
            </th>
            <th>
              Prueba
            </th>
            <th>
              Edición
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="tipoServicioTono in tiposServicioTonoLocal"
            :key="`tipo-servicio-tono-${tipoServicioTono.tipoServicioCuerpoId}`"
          >
            <td>
              {{ tipoServicioTono.codigo }}
            </td>
            <td>
              {{ tipoServicioTono.descripcion }}
            </td>
            <v-divider
              style="height: 30px"
              inset
              vertical
            />
            <td>
              <v-select
                v-model="tipoServicioTono.tonosIds"
                :items="tonos"
                multiple
                item-text="tono"
                item-value="tonoId"
                dense
              />
            </td>
            <td>
              <v-card outlined>
                <v-card-text class="pa-0">
                  <draggable
                    :list="tipoServicioTono.tonosIds"
                    :group="{ name: `draggable-tonos-tipo-servicio-tono-${tipoServicioTono.tipoServicioCuerpoId}`}"
                    class="draggable-min"
                    @start="dragging = true"
                    @end="dragging = false"
                  >
                    <v-chip
                      v-for="tonoId in tipoServicioTono.tonosIds"
                      :key="`tono-id${tonoId}-tipo-servicio-tono-${tipoServicioTono.tipoServicioCuerpoId}`"
                      draggable
                      color="primary"
                      class="ma-1"
                      label
                      @click="showChipDescription($event, tonoId)"
                    >
                      {{ tonoNombre(tonoId) }}
                    </v-chip>
                  </draggable>
                </v-card-text>
              </v-card>
            </td>
            <td>
              <v-btn
                fab
                color="primary"
                small
                depressed
                :disabled="tipoServicioTono.tonosIds.length === 0"
                @click="playTonos(tipoServicioTono.tonosIds)"
              >
                <v-icon>
                  {{ icons.mdiPlay }}
                </v-icon>
              </v-btn>
            </td>
            <td>
              <v-btn
                fab
                color="primary"
                small
                depressed
                :disabled="tipoServicioCuerpoIdEqualGuardado(tipoServicioTono)"
                :loading="tipoServicioTono.loading"
                @click="saveTipoServicioCuerpoTonos(tipoServicioTono)"
              >
                <v-icon>
                  {{ icons.mdiContentSaveAll }}
                </v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import draggable from 'vuedraggable'
import { mdiContentSaveAll, mdiPlay } from '@mdi/js'
import { playSounds } from '@/utils/sounds'

export default {
  name: 'TonosServicioTab',
  components: {
    draggable
  },
  data () {
    return {
      playSounds,
      icons: {
        mdiPlay,
        mdiContentSaveAll
      },
      snackbar: {
        open: false,
        color: 'success',
        timeout: 3000,
        text: ''
      },
      dragging: false,
      tiposServicioTonoLocal: [],
      selectedChip: {
        open: false,
        element: null,
        tono: {
          tonoId: null,
          tono: '',
          frequency: 600,
          type: 'sine',
          volume: 10,
          duration: 500
        }
      }
    }
  },
  computed: {
    ...mapState('emergencias', ['tiposServicioCuerpo', 'tonos', 'tiposServicioCuerpoTonos'])
  },
  mounted () {
    this.tiposServicioTonoLocal = this.tiposServicioCuerpo.map((tipoServicio) => {
      const tipoServicioTonos = this.tiposServicioCuerpoTonos.find((tipoServicioTonos) => {
        return tipoServicioTonos.tipoServicioCuerpoId === tipoServicio.tipoServicioCuerpoId
      })
      const tonosIds = tipoServicioTonos ? tipoServicioTonos.tonosIds : []
      return {
        tipoServicioCuerpoId: tipoServicio.tipoServicioCuerpoId,
        codigo: tipoServicio.codigo,
        descripcion: tipoServicio.descripcion,
        tonosIds: [...tonosIds],
        loading: false
      }
    })
  },
  methods: {
    tipoServicioCuerpoIdEqualGuardado ({ tipoServicioCuerpoId, tonosIds }) {
      const tipoServicioTonosGuardados = this.tiposServicioCuerpoTonos.find((tipoServicioTono) => {
        return tipoServicioTono.tipoServicioCuerpoId === tipoServicioCuerpoId
      })
      if (!tipoServicioTonosGuardados) {
        return false
      }
      return JSON.stringify(tipoServicioTonosGuardados.tonosIds) === JSON.stringify(tonosIds)
    },
    saveTipoServicioCuerpoTonos (tipoServicioCuerpoTono) {
      const { tipoServicioCuerpoId, tonosIds } = tipoServicioCuerpoTono
      tipoServicioCuerpoTono.loading = true
      const data = {
        tipoServicioCuerpoId,
        tonosIds
      }
      this.$store.dispatch('emergencias/updateTipoServicioTonos', data)
        .then(({ data }) => {
          this.snackbar.text = data.message
          this.snackbar.color = 'success'
        })
        .catch((error) => {
          let message = ''
          if (error.response) {
            message = error.response.data.message
          } else if (error.request) {
            message = 'Problemas de conexión o servidor no responde'
          } else {
            message = 'Error, contactar administrador'
          }
          this.snackbar.text = message
          this.snackbar.color = 'error'
        })
        .finally(() => {
          this.snackbar.open = true
          tipoServicioCuerpoTono.loading = false
        })
    },
    tonoNombre (tonoId) {
      return this.tonos.find((tono) => tono.tonoId === tonoId)?.tono
    },
    showChipDescription (nativeEvent, tonoId) {
      const open = () => {
        this.selectedChip.tono = this.tonos.find((tono) => tono.tonoId === tonoId)
        this.selectedChip.element = nativeEvent.target
        requestAnimationFrame(() => requestAnimationFrame(() => {
          this.selectedChip.open = true
        }))
      }

      if (this.selectedChip.open) {
        this.selectedChip.open = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
        open()
      }

      nativeEvent.stopPropagation()
    },
    playTonos (tonosIds) {
      const tonos = tonosIds.map((tonoId) => {
        return this.tonos.find((tono) => tono.tonoId === tonoId)
      })
      this.playSounds(tonos)
    }
  }
}
</script>

<style scoped>
.draggable-min {
  width: 100%;
  min-height: 30px;
  height: 100%;
  min-width: 200px;
}

</style>

<template>
  <v-card flat>
    <v-card-title>
      Historial
      <v-spacer />
      <v-btn
        tile
        depressed
        color="primary"
        @click="openAgregarHistoria"
      >
        <v-icon>
          {{ icons.mdiPlus }}
        </v-icon>
      </v-btn>
      <v-menu
        v-model="data.open"
        :close-on-content-click="false"
        :activator="data.element"
        offset-x
      >
        <v-form
          ref="agregarHistoriaForm"
          @submit.prevent="agregarHistoria"
        >
          <v-card
            min-width="350px"
            flat
          >
            <v-toolbar>
              Agregar Antecedente al Historial
              <v-spacer />
              <v-btn
                icon
                @click="data.open=false"
              >
                <v-icon>
                  {{ icons.mdiClose }}
                </v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
              <v-textarea
                v-model="data.historia"
                label="Historia"
                rows="3"
                :rules="[((v) => !!v || 'Requerido'), (v)=> v.split(' ').length >=3 || 'Escribir al menos 3 palabras' ]"
              />
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                tile
                color="primary"
                type="submit"
              >
                <v-icon left>
                  {{ icons.mdiContentSaveAll }}
                </v-icon>
                Guardar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-menu>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="table.headers"
        sort-by="createdAt"
        :sort-desc="true"
        :items="historialDespacho"
        multi-sort
        dense
      >
        <template #[`item.createdAt`]="{item}">
          {{ new Date(item.createdAt).toLocaleString() }}
        </template>
        <template #[`item.tipoHistorialDespacho`]="{item}">
          {{ item.tipoHistorialDespacho.replace(/([A-Z])/g, ' $1')
            .replace(/^./, function(str){ return str.toUpperCase() }) }}
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiSend, mdiClose, mdiContentSaveAll, mdiPlus } from '@mdi/js'

export default {
  name: 'HistorialSection',
  props: {
    historialDespacho: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      icons: {
        mdiSend,
        mdiClose,
        mdiContentSaveAll,
        mdiPlus
      },
      table: {
        headers: [
          {
            text: 'Fecha',
            value: 'createdAt',
            sortable: true
          },
          { text: 'Acción', value: 'tipoHistorialDespacho', sortable: true },
          { text: 'Detalle', value: 'descripcion', sortable: true }
        ]
      },
      data: {
        element: null,
        open: false,
        historia: ''
      }
    }
  },
  methods: {
    agregarHistoria () {
      const valid = this.$refs.agregarHistoriaForm.validate()
      if (!valid) return
      this.$emit('agregar-historia', this.data.historia)
      this.data.open = false
    },
    openAgregarHistoria (event) {
      this.data.element = event.target
      requestAnimationFrame(() => requestAnimationFrame(() => {
        this.data.open = true
      }))
    }
  }
}
</script>

<style scoped>

</style>

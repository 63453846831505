<template>
  <v-card>
    <v-card-title class="headline">
      Input
    </v-card-title>
    <v-card-text>
      Origenes
      <ul>
        <li>
          Primera Cia Santiago
          <ul>
            <li>Latitud: {{ primeraCiaStgo.lat }}</li>
            <li>Longitud: {{ primeraCiaStgo.lng }}</li>
          </ul>
        </li>
        <li>
          Segunda Cia Santiago
          <ul>
            <li>Latitud: {{ segundaCiaStgo.lat }}</li>
            <li>Longitud: {{ segundaCiaStgo.lng }}</li>
          </ul>
        </li>
      </ul>
      Destino
      <ul>
        <li>
          Junta Nacional de Cuerpos de Bomberos
          <ul>
            <li>Latitud: {{ jncb.lat }}</li>
            <li>Longitud: {{ jncb.lng }}</li>
          </ul>
        </li>
      </ul>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'InputCard',
  props: {
    primeraCiaStgo: {
      type: Object,
      default: () => {}
    },
    segundaCiaStgo: {
      type: Object,
      default: () => {}
    },
    jncb: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style scoped>

</style>

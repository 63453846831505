import axios from '@/plugins/axios'
import laravelEcho, { createEcho } from '@/plugins/laravelEcho'

const state = () => ({
  title: '',
  usuario: JSON.parse(localStorage.getItem('usuario')) || {},
  perfiles: JSON.parse(localStorage.getItem('perfiles')) || [],
  token: localStorage.getItem('token') || '',
  informacionBomberilId: parseInt(localStorage.getItem('informacionBomberilId')) || '',
  regiones: JSON.parse(localStorage.getItem('regiones')) || [],
  provincias: JSON.parse(localStorage.getItem('provincias')) || [],
  comunas: JSON.parse(localStorage.getItem('comunas')) || [],
  cuerpos: JSON.parse(localStorage.getItem('cuerpos')) || [],
  compannias: JSON.parse(localStorage.getItem('compannias')) || [],
  darkTheme: localStorage.getItem('darkTheme') !== null ? localStorage.getItem('darkTheme') === 'true' : true,
  webSocketConnection: {
    status: 'init',
    message: 'Iniciando',
    fullMessage: 'Se está iniciando la conexión con el servidor.',
    icon: null,
    iconColor: '',
    dateLastConnection: null,
    dateLastChange: null
  },
  menus: [
    {
      icon: 'mdiPlusCircle',
      title: 'Nueva Emergencia',
      to: '/nueva-emergencia'
    },
    {
      icon: 'mdiCarEmergency',
      title: 'Emergencias Activas',
      to: '/emergencias-activas'
    },
    {
      icon: 'mdiClipboardTextClock',
      title: 'Historial de Emergencias',
      to: '/historial-emergencias'
    },
    {
      icon: 'mdiCarCog',
      title: 'Vehículos',
      to: '/admin-vehiculos'
    },
    {
      icon: 'mdiAccountCog',
      title: 'Conductores',
      to: '/admin-conductores'
    },
    {
      icon: 'mdiOfficeBuildingCog',
      title: 'Admin. Cuerpo',
      to: '/admin-cuerpo'
    },
    {
      icon: 'mdiWater',
      title: 'Fuentes de Agua',
      to: '/admin-fuentes-agua'
    }
  ]
})

const mutations = {
  setTitle (state, title) {
    state.title = title
    document.title = `${title} | Despacho Bomberos`
  },
  setDarkTheme (state, dark) {
    state.darkTheme = dark
    localStorage.setItem('darkTheme', dark)
  },
  setToken (state, token) {
    localStorage.setItem('token', token)
    state.token = token
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
  },
  destroyToken (state) {
    localStorage.removeItem('token')
    state.token = null
  },
  setUsuario (state, usuario) {
    localStorage.setItem('usuario', JSON.stringify(usuario))
    state.usuario = usuario
  },
  destroyUsuario (state) {
    localStorage.removeItem('usuario')
    state.usuario = { }
  },
  setInformacionBomberilId (state, informacionBomberilId) {
    localStorage.setItem('informacionBomberilId', informacionBomberilId)
    state.informacionBomberilId = parseInt(informacionBomberilId)
  },
  destroyInformacionBomberilId (state) {
    localStorage.removeItem('informacionBomberilId')
    state.informacionBomberilId = null
  },
  setPerfiles (state, perfiles) {
    const newPerfiles = perfiles.map((perfil) => {
      return {
        ...perfil,
        cuerpoLat: parseFloat(perfil.cuerpoLat),
        cuerpoLng: parseFloat(perfil.cuerpoLng)
      }
    })
    localStorage.setItem('perfiles', JSON.stringify(newPerfiles))
    state.perfiles = newPerfiles
  },
  destroyPerfiles (state) {
    localStorage.removeItem('perfiles')
    state.perfiles = []
  },
  setRegiones (state, regiones) {
    localStorage.setItem('regiones', JSON.stringify(regiones))
    state.regiones = regiones
  },
  setProvincias (state, provincias) {
    localStorage.setItem('provincias', JSON.stringify(provincias))
    state.provincias = provincias
  },
  setComunas (state, comunas) {
    localStorage.setItem('comunas', JSON.stringify(comunas))
    state.comunas = comunas
  },
  setCuerpos (state, cuerpos) {
    localStorage.setItem('cuerpos', JSON.stringify(cuerpos))
    state.cuerpos = cuerpos
  },
  setCompannias (state, compannias) {
    localStorage.setItem('compannias', JSON.stringify(compannias))
    state.compannias = compannias
  },
  setWebSocketConnection (state, { event }) {
    const webSocketConnection = {
      status: event,
      message: '',
      fullMessage: '',
      icon: null,
      iconColor: '',
      dateLastConnection: state.webSocketConnection.dateLastConnection,
      dateLastChange: new Date()
    }
    switch (event) {
      case 'initialized':
        webSocketConnection.message = 'Iniciando'
        webSocketConnection.fullMessage = 'La conexión con el servidor central se está iniciando'
        webSocketConnection.icon = null
        webSocketConnection.iconColor = ''
        break
      case 'connecting':
        webSocketConnection.message = 'Conectando'
        webSocketConnection.fullMessage = 'Conectando con el servidor central'
        webSocketConnection.icon = null
        webSocketConnection.iconColor = ''
        break
      case 'connected':
        webSocketConnection.message = 'Conectado'
        webSocketConnection.fullMessage = 'Conectado al servidor central'
        webSocketConnection.dateLastConnection = new Date()
        webSocketConnection.icon = 'mdiCheckCircleOutline'
        webSocketConnection.iconColor = 'success'
        break
      case 'unavailable':
        webSocketConnection.message = 'No Disponible'
        webSocketConnection.fullMessage = 'No disponible, se reintentará nuevamente'
        webSocketConnection.icon = 'mdiAlertCircleOutline'
        webSocketConnection.iconColor = 'warning'
        break
      case 'failed':
        webSocketConnection.message = 'Error'
        webSocketConnection.fullMessage = 'Intento de conexión fallido, se reintentará nuevamente'
        webSocketConnection.icon = 'mdiAlertCircleOutline'
        webSocketConnection.iconColor = 'warning'
        break
      case 'disconnected':
        webSocketConnection.message = 'Desconectado'
        webSocketConnection.fullMessage = 'Se ha desconectado del servidor, se reintentará nuevamente'
        webSocketConnection.icon = 'mdiAlertCircleOutline'
        webSocketConnection.iconColor = 'warning'
        break
    }
    state.webSocketConnection = webSocketConnection
  }
}

const actions = {
  initWebSockets ({ commit }) {
    console.log('init main pusher')
    laravelEcho
      .connector
      .pusher
      .connection
      .bind('initialized', (message) => {
        commit('setWebSocketConnection', { event: 'initialized', message })
      })
      .bind('connecting', (message) => {
        commit('setWebSocketConnection', { event: 'connecting', message })
      })
      .bind('connected', (message) => {
        commit('setWebSocketConnection', { event: 'connected', message })
      })
      .bind('unavailable', (message) => {
        commit('setWebSocketConnection', { event: 'unavailable', message })
      })
      .bind('failed', (message) => {
        commit('setWebSocketConnection', { event: 'failed', message })
      })
      .bind('disconnected', (message) => {
        commit('setWebSocketConnection', { event: 'disconnected', message })
      })
  },
  login ({ commit, dispatch }, credentials) {
    return new Promise((resolve, reject) => {
      const data = {
        username: credentials.username,
        password: credentials.password
      }
      axios.post('/login', data).then(response => {
        const { data } = response
        commit('setToken', data.token)
        commit('setPerfiles', data.perfiles)
        commit('setUsuario', data.usuario)
        createEcho()
        if (data.perfiles.length === 1) {
          commit('setInformacionBomberilId', data.informacionBomberilId)
          dispatch('getInitPerfilInfo')
          dispatch('initWebSockets')
          dispatch('emergencias/initWebSockets', null, { root: true })
        }
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  logout ({ dispatch }) {
    return new Promise((resolve, reject) => {
      axios.post('/logout').then(response => {
        resolve(response)
      }).catch(error => {
        reject(error)
      }).finally(() => {
        dispatch('destroySession')
      })
    })
  },
  destroySession ({ commit }) {
    commit('destroyToken')
    commit('destroyPerfiles')
    commit('destroyUsuario')
    commit('destroyInformacionBomberilId')
  },
  seleccionarPerfil ({ state, commit, dispatch }, credentials) {
    return new Promise((resolve, reject) => {
      axios.post('/seleccionar-perfil', {
        informacionBomberilId: credentials.informacionBomberilId
      }).then(response => {
        commit('setInformacionBomberilId', credentials.informacionBomberilId)
        dispatch('getInitPerfilInfo')
        dispatch('initWebSockets')
        dispatch('emergencias/initWebSockets', null, { root: true })
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },
  getInitPerfilInfo ({ dispatch }) {
    dispatch('getCompannias')
    dispatch('emergencias/getInitInfo', true, { root: true })
  },
  getCompannias ({ getters, commit }, inParams) {
    const params = {
      cuerpoId: getters.perfilActivo.cuerpoId,
      ...inParams
    }
    axios.get('/compannias', { params })
      .then(({ data }) => {
        commit('setCompannias', data.compannias)
      })
  },
  syncPerfil ({ commit }) {
    axios.get('/sync-perfil')
      .then(({ data }) => {
        commit('setPerfiles', data.perfiles)
      })
  },
  setTheme ({ commit }, dark) {
    commit('setDarkTheme', dark)
  }
}

const getters = {
  conPerfil (state) {
    return !!(state.informacionBomberilId && state.usuario !== null)
  },
  perfilActivo (state, getters) {
    const perfil = {}
    if (getters.conPerfil) {
      return state.perfiles.find(({ informacionBomberilId }) => informacionBomberilId === state.informacionBomberilId)
    }
    return perfil
  }
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true
}

<template>
  <v-card>
    <v-card-subtitle>
      Seleccionar vehículos que deben estar dentro de los despachados automáticamente por tipo de servicio.
      <br>
      Luego el sistema sugerirá el vehículo disponible que menos se demore en llegar a la emergencia.
    </v-card-subtitle>
    <v-card-text>
      <v-speed-dial
        fixed
        bottom
        right
      >
        <template #activator>
          <v-btn
            fab
            color="primary"
            :disabled="!changeData"
            :loading="loadingSave"
            @click="save()"
          >
            <v-icon>
              {{ icons.mdiContentSaveAll }}
            </v-icon>
          </v-btn>
          <v-btn
            fab
            color="primary"
            :disabled="!changeData"
            @click="undo()"
          >
            <v-icon>
              {{ icons.mdiUndo }}
            </v-icon>
          </v-btn>
        </template>
      </v-speed-dial>
      <v-row>
        <v-col>
          <v-simple-table>
            <thead>
              <tr>
                <th colspan="2">
                  Servicio
                </th>
                <v-divider
                  style="height: 30px"
                  inset
                  vertical
                />
                <th :colspan="tiposVehiculoBomberilCuerpo.length+ 1">
                  Cantidad por Tipos Vehículos a Despachar
                </th>
              </tr>
              <tr>
                <th>
                  Código
                </th>
                <th>
                  Servicio
                </th>
                <v-divider
                  style="height: 30px"
                  inset
                  vertical
                />
                <th>
                  Total
                </th>
                <v-divider
                  style="height: 30px"
                  inset
                  vertical
                />
                <th
                  v-for="tipoVehiculoBomberil in tiposVehiculoBomberilCuerpo"
                  :key="`tipo-vehiculo-bomberil-${tipoVehiculoBomberil.tipoVehiculoBomberilId}`"
                >
                  {{ tipoVehiculoBomberil.tipoVehiculoBomberil }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="tipoServicioCuerpo in tiposServicioCuerpo"
                :key="`tipo-servicio-cuerpo-${tipoServicioCuerpo.tipoServicioCuerpoId}`"
              >
                <td>
                  {{ tipoServicioCuerpo.codigo }}
                </td>
                <td>
                  {{ tipoServicioCuerpo.descripcion }}
                </td>
                <v-divider
                  style="height: 55px"
                  inset
                  vertical
                />
                <td>
                  <span v-if="localData[tipoServicioCuerpo.tipoServicioCuerpoId] !== undefined">
                    {{ Object.values(localData[tipoServicioCuerpo.tipoServicioCuerpoId]).reduce((a, b) => parseInt(a) + parseInt(b)) }}
                  </span>
                  <span v-else>
                    0
                  </span>
                </td>
                <v-divider
                  style="height: 55px"
                  inset
                  vertical
                />
                <td
                  v-for="tipoVehiculoBomberil in tiposVehiculoBomberilCuerpo"
                  :id="`cantidad-tipo-vehiculo-bomberil-${tipoVehiculoBomberil.tipoVehiculoBomberilId}-por-tipo-servicio-cuerpo-${tipoServicioCuerpo.tipoServicioCuerpoId}`"
                  :key="`cantidad-tipo-vehiculo-bomberil-${tipoVehiculoBomberil.tipoVehiculoBomberilId}-por-tipo-servicio-cuerpo-${tipoServicioCuerpo.tipoServicioCuerpoId}`"
                >
                  <GNumberField
                    v-if="localData[tipoServicioCuerpo.tipoServicioCuerpoId] !== undefined && localData[tipoServicioCuerpo.tipoServicioCuerpoId][tipoVehiculoBomberil.tipoVehiculoBomberilId] !== undefined "
                    v-model="localData[tipoServicioCuerpo.tipoServicioCuerpoId][tipoVehiculoBomberil.tipoVehiculoBomberilId]"
                  />
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { mdiContentSaveAll, mdiUndo } from '@mdi/js'

export default {
  name: 'VehiculosPorServicioTab',
  data () {
    return {
      icons: {
        mdiContentSaveAll,
        mdiUndo
      },
      localData: [],
      loadingSave: false
    }
  },
  computed: {
    ...mapState('emergencias', ['vehiculos', 'tiposServicioCuerpo', 'cantidadTiposVehiculosPorTipoServicio']),
    ...mapGetters('emergencias', ['tiposVehiculoBomberilCuerpo', 'cantidadTiposVehiculosPorTipoServicioComplete']),
    changeData () {
      return JSON.stringify(this.cantidadTiposVehiculosPorTipoServicioComplete) !== JSON.stringify(this.localData)
    }
  },
  watch: {
    cantidadTiposVehiculosPorTipoServicioComplete () {
      this.localData = JSON.parse(JSON.stringify(this.cantidadTiposVehiculosPorTipoServicioComplete))
    }
  },
  mounted () {
    this.localData = JSON.parse(JSON.stringify(this.cantidadTiposVehiculosPorTipoServicioComplete))
  },
  methods: {
    undo () {
      this.localData = JSON.parse(JSON.stringify(this.cantidadTiposVehiculosPorTipoServicioComplete))
    },
    save () {
      const cantidadTiposVehiculosPorTipoServicio = this.tiposServicioCuerpo.reduce((acc, { tipoServicioCuerpoId }) => {
        this.tiposVehiculoBomberilCuerpo.forEach(({ tipoVehiculoBomberilId }) => {
          acc.push({
            tipoServicioCuerpoId,
            tipoVehiculoBomberilId,
            cantidad: parseInt(this.localData[tipoServicioCuerpoId][tipoVehiculoBomberilId])
          })
        })
        return acc
      }, [])
      this.loadingSave = true
      this.$store.dispatch('emergencias/updateCantidadTiposVehiculosPorTipoServicio', cantidadTiposVehiculosPorTipoServicio)
        .finally(() => {
          this.loadingSave = false
        })
    }
  }
}
</script>

<style scoped>

</style>

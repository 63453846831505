<template>
  <v-card flat>
    <v-card-title>
      Historial
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="table.headers"
        sort-by="createdAt"
        :sort-desc="true"
        :items="historialDespacho"
        multi-sort
        dense
      >
        <template #[`item.createdAt`]="{item}">
          {{ new Date(item.createdAt).toLocaleString() }}
        </template>
        <template #[`item.tipoHistorialDespacho`]="{item}">
          {{ item.tipoHistorialDespacho.replace(/([A-Z])/g, ' $1')
            .replace(/^./, function(str){ return str.toUpperCase() }) }}
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  name: 'HistorialSection',
  props: {
    historialDespacho: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      table: {
        headers: [
          {
            text: 'Fecha',
            value: 'createdAt',
            sortable: true
          },
          { text: 'Acción', value: 'tipoHistorialDespacho', sortable: true },
          { text: 'Detalle', value: 'descripcion', sortable: true }
        ]
      }
    }
  }
}
</script>

<style scoped>

</style>

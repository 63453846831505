<template>
  <v-card
    flat
  >
    <v-card-title class="py-1 px-0">
      Codigos Filtrados por Preguntas
    </v-card-title>
    <v-card-text class="px-0 pb-0">
      <v-data-table
        :items="tiposServiciosFiltrados"
        :headers="table.headers"
        :no-data-text="table.noDataText"
        dense
        @click:row="$emit('select-codigo-acto-servicio', $event)"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'TiposServicioFiltradosTableSection',
  props: {
    tiposServicioIdsRespuestasPreguntas: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      table: {
        headers: [
          { text: 'Código', value: 'codigo', sort: false },
          { text: 'Descripción', value: 'descripcion', sort: false }
        ],
        noDataText: 'Seleccione pregunta'
      }
    }
  },
  computed: {
    ...mapState('emergencias', ['tiposServicioCuerpo']),
    tiposServiciosFiltrados () {
      return this.tiposServicioCuerpo.filter(({ tipoServicioCuerpoId }) => {
        return this.tiposServicioIdsRespuestasPreguntas.includes(tipoServicioCuerpoId)
      })
    }
  }
}
</script>

<style scoped>

</style>

import Vue from 'vue'
import GmapVue from 'gmap-vue'

export default Vue.use(GmapVue, {
  load: {
    key: process.env.VUE_APP_GOOGLE_API_KEY,
    libraries: 'places',
    region: 'CL',
    language: 'es'
  },
  installComponents: true,
  dynamicLoad: false
})

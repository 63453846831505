import Vue from 'vue'
import VueRouter from 'vue-router'
import MainLayout from '@/components/layouts/MainLayout'
import EmergenciasActivas from '@/views/EmergenciasActivasPage'
import EmergenciaActiva from '@/views/EmergenciaActivaPage'
import NuevaEmergenciaPage from '@/views/NuevaEmergenciaPage'
import HistorialEmergenciasPage from '@/views/HistorialEmergenciasPage'
import AdminCuerpoPage from '@/views/AdminCuerpoPage'
import VehiculosPage from '@/views/VehiculosPage'
import ConductoresPage from '@/views/ConductoresPage'
import Demo from '@/views/Demo'
import LoginPage from '@/views/LoginPage'
import LogoutPage from '@/views/LogoutPage'
import FuentesAguaPage from '@/views/FuentesAguaPage'
import HistorialEmergenciaPage from '@/views/HistorialEmergenciaPage'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    component: LoginPage,
    meta: {
      title: 'Inicio Sesión',
      authRequired: false
    }
  },
  {
    path: '/logout',
    component: LogoutPage,
    meta: {
      title: 'Cerrando Sesión',
      authRequired: true
    }
  },
  {
    path: '/',
    component: MainLayout,
    redirect: '/emergencias-activas',
    children: [
      {
        path: 'demo',
        component: Demo,
        meta: {
          title: 'Demo',
          authRequired: true
        }
      },
      {
        path: 'emergencias-activas',
        component: EmergenciasActivas,
        meta: {
          title: 'Emergencias Activas',
          authRequired: true
        }
      },
      {
        path: 'nueva-emergencia',
        component: NuevaEmergenciaPage,
        meta: {
          title: 'Nueva Emergencia',
          authRequired: true
        }
      },
      {
        path: 'historial-emergencias',
        component: HistorialEmergenciasPage,
        meta: {
          title: 'Historial Emergencias',
          authRequired: true
        }
      },
      {
        path: 'admin-vehiculos',
        component: VehiculosPage,
        meta: {
          title: 'Administrador de Vehículos',
          authRequired: true
        }
      },
      {
        path: 'admin-conductores',
        component: ConductoresPage,
        meta: {
          title: 'Administrador de Conductores',
          authRequired: true
        }
      },
      {
        path: 'admin-cuerpo',
        component: AdminCuerpoPage,
        meta: {
          title: 'Administrador de Cuerpo',
          authRequired: true
        }
      },
      {
        path: 'admin-fuentes-agua',
        component: FuentesAguaPage,
        meta: {
          title: 'Administrar Fuentes de Agua',
          authRequired: true
        }
      },
      {
        path: 'emergencia-activa/:despachoId',
        component: EmergenciaActiva,
        meta: {
          title: 'Emergencia Activa',
          authRequired: true
        }
      },
      {
        path: 'historial-emergencia/:despachoId',
        component: HistorialEmergenciaPage,
        meta: {
          title: 'Historial Emergencia',
          authRequired: true
        }
      }
    ]
  }
]

const router = new VueRouter({
  mode: process.env.IS_ELECTRON ? 'hash' : 'history',
  base: process.env.BASE_URL,
  routes
})

export default router

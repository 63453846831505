<template>
  <v-text-field
    :value="value"
    v-bind="$attrs"
    type="number"
    :step="step"
    v-on="$listeners"
    @keydown="(e) => inputOnlyNumber(e, numberType)"
  />
</template>

<script>
import { inputOnlyNumber } from '../../utils/inputs'
export default {
  name: 'NumberField',
  inheritAttrs: false,
  props: {
    value: {
      type: [String, Number],
      default: 0
    },
    numberType: {
      type: String,
      default: 'integer'
    }
  },
  data () {
    return {
      inputOnlyNumber
    }
  },
  computed: {
    step () {
      if (Object.hasOwnProperty.call(this.$attrs, 'step')) return this.$attrs.step
      return this.numberType === 'float' ? 'any' : '1'
    }
  }
}
</script>

<style scoped>
</style>

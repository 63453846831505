<template>
  <v-row>
    <v-col
      cols="12"
      class="pb-0"
    >
      <GmapMap
        ref="gmap"
        :zoom="map.zoom"
        :center="{ lat: despachoLocalLat, lng: despachoLocalLng}"
        style="width: inherit; height: 350px"
        @zoom_changed="map.zoom=$event"
      >
        <GmapMarkerCia
          v-for="(compannia, index) in compannias"
          :key="`compannia-${index}`"
          :compannia="compannia"
        />
        <GmapMarker
          :position="{ lat: despachoLocalLat, lng: despachoLocalLng}"
        />
      </GmapMap>
    </v-col>
  </v-row>
</template>

<script>
import GmapMarkerCia from '@/components/gmaps/GmapMarkerCia'

export default {
  name: 'MapSection',
  components: {
    GmapMarkerCia
  },
  props: {
    despachoLocalLat: {
      type: Number,
      default: -33.436260
    },
    despachoLocalLng: {
      type: Number,
      default: -70.643320
    },
    compannias: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      map: {
        zoom: 13
      }
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <v-container class="px-0">
    <v-row
      v-if="Object.entries(errors).length && Object.entries(Object.values(errors)[0]).length"
      class="mx-0"
    >
      <v-col class="pa-0">
        <h4
          class="error--text"
        >
          Revise lo siguiente:
        </h4>
        <ul>
          <li
            v-for="(error, key) in displayErrors"
            :key="key"
          >
            {{ error }}
          </li>
        </ul>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'CardErrors',
  props: {
    errors: {
      type: [Object, Array],
      default: () => { return [[]] }
    }
  },
  computed: {
    displayErrors () {
      const { errors } = this
      return Object.values(errors).reduce((acc, val) => [...acc, ...val], [])
    }
  }
}
</script>

<style scoped>
</style>

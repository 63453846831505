<template>
  <v-row
    justify="center"
    align="center"
  >
    <v-col cols="12">
      <v-card>
        <v-card-title>
          Resultados Google Distance Matrix y Directions Service
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="3">
              <InputCard
                :primera-cia-stgo="primeraCiaStgo"
                :segunda-cia-stgo="segundaCiaStgo"
                :jncb="jncb"
              />
            </v-col>
            <v-col cols="3">
              <OutputCard
                :primera-cia-stgo-response="primeraCiaStgoResponse"
                :segunda-cia-stgo-response="segundaCiaStgoResponse"
                :jncb-response="jncbResponse"
              />
            </v-col>
            <v-col cols="3">
              <MapRouteComponent
                :origin="origin"
                :destination="jncb"
                :selector-origin.sync="selectorOrigin"
              />
            </v-col>
            <v-col>
              <ConfirmarDespachoCard
                :lat="jncb.lat"
                :lng="jncb.lng"
                :address="jncbResponse.address"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="4">
      <PruebaTonosCard />
    </v-col>
  </v-row>
</template>

<script>
import { getGoogleMapsAPI } from 'gmap-vue'
import ConfirmarDespachoCard from '@/components/ConfirmarEmergenciaCard'
import MapRouteComponent from '@/components/MapRouteComponent'
import PruebaTonosCard from '@/components/PruebaTonosCard'
import InputCard from '@/components/InputCard'
import OutputCard from '@/components/OutputCard'

export default {
  name: 'IndexPage',
  components: {
    ConfirmarDespachoCard,
    MapRouteComponent,
    PruebaTonosCard,
    OutputCard,
    InputCard
  },
  data () {
    return {
      selectorOrigin: 0,
      primeraCiaStgo: {
        lat: -33.436260,
        lng: -70.643320
      },
      segundaCiaStgo: {
        lat: -33.433420,
        lng: -70.645280
      },
      jncb: {
        lat: -33.440470,
        lng: -70.630950
      },
      primeraCiaStgoResponse: {
        durationValue: 0,
        durationText: '',
        distanceValue: 0,
        distanceText: '',
        address: ''
      },
      segundaCiaStgoResponse: {
        durationValue: 0,
        durationText: '',
        distanceValue: 0,
        distanceText: '',
        address: ''
      },
      jncbResponse: {
        address: ''
      },
      destination: {
        lat: 0,
        lng: 0
      }
    }
  },
  computed: {
    google: getGoogleMapsAPI,
    origin () {
      return this.selectorOrigin === 0 ? this.primeraCiaStgo : this.segundaCiaStgo
    }
  },
  mounted () {
    this.getMatrix()
  },
  methods: {
    getMatrix () {
      this.$gmapApiPromiseLazy().then(() => {
        const service = new this.google.maps.DistanceMatrixService()
        const origin1 = {
          lat: this.primeraCiaStgo.lat,
          lng: this.primeraCiaStgo.lng
        }
        const origin2 = {
          lat: this.segundaCiaStgo.lat,
          lng: this.segundaCiaStgo.lng
        }
        const request = {
          origins: [origin1, origin2],
          destinations: [this.jncb],
          travelMode: this.google.maps.TravelMode.DRIVING,
          unitSystem: this.google.maps.UnitSystem.METRIC,
          avoidHighways: false,
          avoidTolls: false
        }
        service.getDistanceMatrix(request).then((response) => {
          this.primeraCiaStgoResponse.address = response.originAddresses[0]
          this.segundaCiaStgoResponse.address = response.originAddresses[1]
          this.jncbResponse.address = response.destinationAddresses[0]
          const primeraCiaResponse = response.rows[0].elements[0]
          this.primeraCiaStgoResponse.durationText = primeraCiaResponse.duration.text
          this.primeraCiaStgoResponse.durationValue = primeraCiaResponse.duration.value
          this.primeraCiaStgoResponse.distanceText = primeraCiaResponse.distance.text
          this.primeraCiaStgoResponse.distanceValue = primeraCiaResponse.distance.value
          const segundaCiaResponse = response.rows[1].elements[0]
          this.segundaCiaStgoResponse.durationText = segundaCiaResponse.duration.text
          this.segundaCiaStgoResponse.durationValue = segundaCiaResponse.duration.value
          this.segundaCiaStgoResponse.distanceText = segundaCiaResponse.distance.text
          this.segundaCiaStgoResponse.distanceValue = segundaCiaResponse.distance.value
        })
      })
    }
  }
}
</script>

<template>
  <v-dialog
    :scrollable="false"
    :value="showDialog"
    max-width="500"
    persistent
  >
    <CardFormHelper
      v-if="perfiles.length === 0"
      :errors="loginCard.errors"
      :show-message.sync="loginCard.showMessage"
      :is-loading="loginCard.isLoading"
      :message="loginCard.message"
      :was-successful="loginCard.wasSuccessful"
      :message-timer="loginCard.messageTimer"
    >
      <template #top>
        <v-container>
          <v-row
            class="py-2 mx-0"
            justify="center"
          >
            <v-icon x-large>
              $logoBomberos
            </v-icon>
          </v-row>
        </v-container>
        <v-card-title class="justify-center pt-0">
          Sistema de Despacho de Bomberos de Chile
        </v-card-title>
        <v-card-subtitle class="text-center">
          Ingrese sus credenciales para iniciar sesión
        </v-card-subtitle>
      </template>
      <template #bottom>
        <v-form
          ref="loginForm"
          v-model="loginCard.valid"
          @submit.prevent="login"
        >
          <v-card-text class="py-0">
            <v-text-field
              v-model="username"
              autocapitalize="off"
              autocorrect="off"
              spellcheck="false"
              class="input"
              placeholder="Usuario"
              :rules="[(v) => !!v || 'Requerido']"
            />
            <v-text-field
              v-model="password"
              placeholder="Contraseña"
              :append-icon="showPassword ? mdiEyeOff : mdiEye"
              :type="showPassword ? 'text' : 'password'"
              :rules="[(v) => !!v || 'Requerido']"
              @click:append="() => (showPassword = !showPassword)"
            >
              Contraseña
            </v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-container>
              <v-col class="py-0">
                <v-row class="py-2">
                  <v-btn
                    block
                    class="button is-primary"
                    color="success"
                    small
                    type="submit"
                  >
                    Ingresar
                    <v-icon
                      right
                    >
                      {{ mdiLogin }}
                    </v-icon>
                  </v-btn>
                </v-row>
                <v-row class="py-2">
                  <v-btn
                    block
                    color="warning"
                    href="https://app.bomberos.cl/clogin/"
                    small
                    target="_blank"
                  >
                    Recuperar Contraseña
                    <v-icon
                      right
                    >
                      {{ mdiLockReset }}
                    </v-icon>
                  </v-btn>
                </v-row>
              </v-col>
            </v-container>
          </v-card-actions>
        </v-form>
      </template>
    </CardFormHelper>
    <CardFormHelper
      v-if="perfiles.length > 0"
      :errors="perfilCard.errors"
      :show-message.sync="perfilCard.showMessage"
      :is-loading="perfilCard.isLoading"
      :message="perfilCard.message"
      :was-successful="perfilCard.wasSuccessful"
      :message-timer="perfilCard.messageTimer"
    >
      <template #top>
        <v-card-title class="headline">
          Perfil
        </v-card-title>
      </template>
      <template #bottom>
        <v-card-text>
          <v-form
            ref="perfilesForm"
            v-model="perfilCard.valid"
            @submit.prevent="seleccionarPerfil"
          >
            Seleccione el Perfil con el que desea Ingresar
            <v-select
              v-model="informacionBomberilId"
              :items="perfiles"
              item-text="cargo"
              item-value="informacionBomberilId"
              label="Seleccione..."
              :rules="[(v) => !!v || 'Requerido']"
            />
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="green darken-1"
                text
                type="submit"
              >
                Ingresar
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </template>
    </CardFormHelper>
  </v-dialog>
</template>

<script>
import CardFormHelper from './CardFormHelper'
import { mdiLogin, mdiLockReset, mdiMicrosoftOutlook, mdiEye, mdiEyeOff, mdiArrowLeft } from '@mdi/js'

export default {
  name: 'LoginForm',
  components: {
    CardFormHelper
  },
  data () {
    return {
      mdiLockReset,
      mdiLogin,
      mdiMicrosoftOutlook,
      mdiEye,
      mdiEyeOff,
      mdiArrowLeft,
      username: '',
      password: '',
      showPassword: false,
      informacionBomberilId: 0,
      loginCard: {
        valid: true,
        isLoading: false,
        showMessage: false,
        errors: {},
        message: '',
        wasSuccessful: false,
        messageTimer: 3000
      },
      perfilCard: {
        valid: true,
        isLoading: false,
        showMessage: false,
        errors: {},
        message: '',
        wasSuccessful: false,
        messageTimer: 3000
      }
    }
  },
  computed: {
    perfiles () {
      return this.$store.state.main.perfiles
    },
    conPerfil () {
      return this.$store.getters['main/conPerfil']
    },
    showDialog () {
      return ((!this.conPerfil) && !this.$route.path.includes('login-token')) || this.$route.path === '/login'
    }
  },
  watch: {
    showDialog () {
      this.cleanData()
    }
  },
  methods: {
    async login () {
      this.$refs.loginForm.validate()
      if (!this.loginCard.valid) return
      this.loginCard.isLoading = true
      await this.$store.dispatch('main/login', {
        username: this.username,
        password: this.password
      }).then(({ data }) => {
        if (data.perfiles.length === 1) {
          this.$router.push('/emergencias-activas')
        }
      }).catch(error => {
        if (error.response) {
          this.loginCard.errors = error.response.data.errors
          this.loginCard.message = error.response.data.message
          this.loginCard.wasSuccessful = false
          this.loginCard.isLoading = false
        } else {
          this.loginCard.message = error.message
          this.loginCard.errors = error.errors
        }
      }).finally(() => {
        this.loginCard.showMessage = true
        this.loginCard.isLoading = false
      })
    },
    cleanData () {
      this.username = ''
      this.password = ''
      this.informacionBomberilId = 0
      this.loginCard = {
        isLoading: false,
        showMessage: false,
        errors: [],
        message: '',
        wasSuccessful: false
      }
    },
    async seleccionarPerfil () {
      this.$refs.perfilesForm.validate()
      if (!this.perfilCard.valid) return
      this.perfilCard.isLoading = true
      this.$store.dispatch('main/seleccionarPerfil', {
        informacionBomberilId: this.informacionBomberilId
      }).then(() => {
        this.cleanData()
        this.$router.push('/emergencias-activas')
      }).finally(() => {
        this.perfilCard.isLoading = false
      })
    }
  }
}
</script>

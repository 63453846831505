<template>
  <v-card>
    <v-card-title>
      Confirmar Emergencia
    </v-card-title>
    <v-card-text>
      <strong>
        Información Emergencia
      </strong>
      <br>
      Latitud: {{ lat }}
      <br>
      Longitud: {{ lng }}
      <br>
      Dirección: {{ address }}
      <v-select
        v-model="codigoActo"
        :items="actosServicio"
        item-text="acto"
        item-value="codigo"
        label="Código Servicio"
      />
      <v-btn
        @click="confirmarDespacho"
      >
        Confirmar Emergencia
      </v-btn>
      {{ message }}
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'ConfirmarDespachoCard',
  props: {
    address: {
      type: String,
      default: ''
    },
    lat: {
      type: Number,
      default: 0
    },
    lng: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      codigoActo: '',
      actosServicio: [
        { codigo: '10-30', acto: '10-30 Incendio' }
      ],
      message: ''
    }
  },
  computed: {
    acto () {
      const acto = this.actosServicio.find(acto => acto.codigo === this.codigoActo)
      return acto ? acto.acto : ''
    }
  },
  methods: {
    confirmarDespacho () {
      const { address, lat, lng, codigoActo, acto } = this
      const data = { direccion: address, lat, lng, codigoActo, acto }
      this.$axios.$post('/confirmar-emergencia', data)
        .then((response) => {
          this.message = response.message
        })
    }
  }
}
</script>

<style scoped>

</style>

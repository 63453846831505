<template>
  <v-row justify="center">
    <v-col cols="12">
      <v-card>
        <v-card-text>
          <v-expansion-panels
            flat
            :value="0"
          >
            <v-expansion-panel>
              <v-expansion-panel-header>
                <span class="text-uppercase">
                  Mapa
                </span>
              </v-expansion-panel-header>
              <v-expansion-panel-content class="pa-0">
                <GmapMap
                  ref="gmap"
                  :zoom="13"
                  :center="map.center"
                  style="width: inherit; height: 400px"
                >
                  <GmapMarkerCia
                    v-for="(compannia, index) in compannias"
                    :key="`compannia-${index}`"
                    :compannia="compannia"
                  />
                  <span
                    v-for="(despacho, index) in despachos"
                    :key="index"
                  >
                    <GmapMarkerVehiculo
                      v-for="(vehiculo, indexVehiculo) in vehiculosSeleccionadosDespacho(despacho)"
                      :key="`vehiculo-${indexVehiculo}`"
                      :vehiculo="vehiculo"
                    />
                    <GmapMarker
                      :position="{lat: despacho.lat, lng: despacho.lng}"
                      @update:position="setMarker($event)"
                    />
                  </span>
                </GmapMap>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
          <v-data-table
            :items="despachos"
            :headers="table.headers"
          >
            <template #[`item.horaInicio`]="{item}">
              {{ new Date(item.fechaHoraInicio).toLocaleString() }}
            </template>
            <template #[`item.codigo`]="{item}">
              {{ getCodigoTipoServicioCuerpo(item.tipoServicioCuerpoId) }}
            </template>
            <template #[`item.vehiculos`]="{item}">
              <span :set="vehiculosDespacho = vehiculosSeleccionadosDespacho(item)">
                <span
                  v-for="(vehiculo, index) in vehiculosDespacho"
                  :key="index"
                  :class="{ 'error--text': vehiculo.estadoId === 2, 'warning--text': vehiculo.estadoId === 1}"
                >
                  {{ vehiculo.denominacion }}{{ index < vehiculosDespacho.length - 1 ? ', ' : ' ' }}
                </span>
              </span>
            </template>
            <template #[`item.controlada`]="{item}">
              <v-icon
                :color="item.controlada? 'success': 'error'"
                :class="{parpadea: !item.controlada}"
              >
                {{ item.controlada? icons.mdiCheck: icons.mdiAlarmLight }}
              </v-icon>
            </template>
            <template #[`item.ubicacion`]="{item}">
              {{ getUbicacion(item) }}
            </template>
            <template #[`item.ver`]="{item}">
              <v-btn
                fab
                color="primary"
                small
                @click="$router.push(`/emergencia-activa/${item.despachoId}`)"
              >
                <v-icon>
                  {{ icons.mdiEye }}
                </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { mdiFireTruck, mdiPoliceStation, mdiCheck, mdiAlarmLight, mdiEye } from '@mdi/js'
import GmapMarkerVehiculo from '@/components/gmaps/GmapMarkerVehiculo'
import GmapMarkerCia from '@/components/gmaps/GmapMarkerCia'
import { getGoogleMapsAPI } from 'gmap-vue'

export default {
  name: 'EmergenciasActivasPage',
  components: { GmapMarkerVehiculo, GmapMarkerCia },
  data () {
    return {
      icons: {
        mdiCheck,
        mdiAlarmLight,
        mdiEye
      },
      tab: 'lista',
      map: {
        center: {
          lat: -33.436260,
          lng: -70.643320
        },
        zoom: 13,
        zoomGrifos: 10
      },
      vehiculoMarkerIcon: {
        path: mdiFireTruck,
        scale: 1.5,
        strokeWeight: 0.4,
        strokeColor: 'black',
        strokeOpacity: 1,
        fillOpacity: 1
      },
      companniaMarkerIcon: {
        path: mdiPoliceStation,
        scale: 1.5,
        strokeWeight: 0.4,
        strokeColor: 'black',
        strokeOpacity: 1,
        fillColor: 'blue',
        fillOpacity: 1
      },
      table: {
        headers: [
          { text: 'Hora de Inicio', value: 'horaInicio' },
          { text: 'Código', value: 'codigo' },
          { text: 'Controlada', value: 'controlada', align: 'center' },
          { text: 'Vehículos', value: 'vehiculos' },
          { text: 'Ubicación', value: 'ubicacion' },
          { text: 'Ver', value: 'ver', sortable: false, align: 'center' }
        ]
      }
    }
  },
  computed: {
    ...mapState('emergencias', {
      tiposServicioCuerpo: state => state.tiposServicioCuerpo,
      vehiculos: state => state.vehiculos
    }),
    ...mapState('main', ['compannias']),
    ...mapGetters('emergencias', {
      despachos: 'despachosActivosCuerpo'
    }),
    ...mapGetters('main', ['perfilActivo']),
    google: getGoogleMapsAPI
  },
  mounted () {
    this.setInitCenter()
    this.addTrafficLayer()
  },
  methods: {
    setInitCenter () {
      const lat = this.perfilActivo.cuerpoLat
      const lng = this.perfilActivo.cuerpoLng
      this.map.center = {
        lat,
        lng
      }
    },
    addTrafficLayer () {
      const map = this.$refs.gmap
      map.$mapPromise.then((map) => {
        const trafficLayer = new this.google.maps.TrafficLayer()
        trafficLayer.setMap(map)
      })
    },
    vehiculosSeleccionadosDespacho (despacho) {
      return despacho.vehiculosDespacho.map((vehiculoSeleccionado) => {
        const vehiculo = this.vehiculos.find(({ vehiculoId }) => vehiculoId === vehiculoSeleccionado.vehiculoId)
        return {
          ...vehiculoSeleccionado,
          ...vehiculo
        }
      })
    },
    getCodigoTipoServicioCuerpo (searchTipoServicioCuerpoId) {
      return this.tiposServicioCuerpo.find(({ tipoServicioCuerpoId }) => tipoServicioCuerpoId === searchTipoServicioCuerpoId)?.codigo
    },
    getUbicacion ({ tipoUbicacionId, direccion, calle1, calle2, ruta, rutaKm }) {
      switch (tipoUbicacionId) {
        case 1:
          return direccion
        case 2:
          return `${calle1} con ${calle2}`
        case 'ruta':
          return `${ruta} con ${rutaKm}`
      }
    }
  }

}
</script>

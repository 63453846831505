import { v4 as uuidv4 } from 'uuid'
import axios from '@/plugins/axios'
import laravelEcho from '@/plugins/laravelEcho'
import { playSounds } from '@/utils/sounds'
import { listenUpdateDespachoUtil, updateDespachoUtil } from '@/utils/despacho'

const state = () => ({
  despachos: JSON.parse(localStorage.getItem('despachos')) || [],
  preguntas: JSON.parse(localStorage.getItem('preguntas')) || [],
  tiposServicioCuerpo: JSON.parse(localStorage.getItem('tiposServicioCuerpo')) || [],
  vehiculos: JSON.parse(localStorage.getItem('vehiculos')) || [],
  conductoresVehiculos: JSON.parse(localStorage.getItem('conductoresVehiculos')) || [],
  grifos: JSON.parse(localStorage.getItem('grifos')) || [],
  fuentesAgua: JSON.parse(localStorage.getItem('fuentesAgua')) || [],
  tiposVehiculoBomberil: JSON.parse(localStorage.getItem('tiposVehiculoBomberil')) || [],
  cantidadTiposVehiculosPorTipoServicio: JSON.parse(localStorage.getItem('cantidadTiposVehiculosPorTipoServicio')) || [],
  tonos: JSON.parse(localStorage.getItem('tonos')) || [],
  tiposServicioCuerpoTonos: JSON.parse(localStorage.getItem('tiposServicioCuerpoTonos')) || [],
  companniasTonos: JSON.parse(localStorage.getItem('companniasTonos')) || [],
  globalesTonos: JSON.parse(localStorage.getItem('globalesTonos')) || []
})

const mutations = {
  storeDespacho (state, newDespacho) {
    state.despachos.push(newDespacho)
    localStorage.setItem('despachos', JSON.stringify(state.despachos))
  },
  updateDespacho (state, despacho) {
    const currentDespacho = state.despachos.find(({ despachoId }) => despachoId === despacho.despachoId)
    Object.assign(currentDespacho, despacho)
    localStorage.setItem('despachos', JSON.stringify(state.despachos))
  },
  setGrifos (state, grifos) {
    const grifosFormatted = grifos.map((grifo) => {
      return {
        ...grifo,
        lat: parseFloat(grifo.lat),
        lng: parseFloat(grifo.lng),
        diamGrifo: parseFloat(grifo.diamGrifo),
        diamTub: parseFloat(grifo.diamTub)
      }
    })
    state.grifos = grifosFormatted
    localStorage.setItem('grifos', JSON.stringify(grifosFormatted))
  },
  setFuentesAgua (state, fuentesAgua) {
    state.fuentesAgua = fuentesAgua
    localStorage.setItem('fuentesAgua', JSON.stringify(fuentesAgua))
  },
  setTiposServicioCuerpo (state, tiposServicioCuerpo) {
    localStorage.setItem('tiposServicioCuerpo', JSON.stringify(tiposServicioCuerpo))
    state.tiposServicioCuerpo = tiposServicioCuerpo
  },
  setVehiculos (state, vehiculos) {
    localStorage.setItem('vehiculos', JSON.stringify(vehiculos))
    state.vehiculos = vehiculos
  },
  setDespachos (state, despachos) {
    localStorage.setItem('despachos', JSON.stringify(despachos))
    state.despachos = despachos
  },
  setTiposVehiculoBomberil (state, tiposVehiculoBomberil) {
    localStorage.setItem('tiposVehiculoBomberil', JSON.stringify(tiposVehiculoBomberil))
    state.tiposVehiculoBomberil = tiposVehiculoBomberil
  },
  setConductoresVehiculos (state, conductoresVehiculos) {
    localStorage.setItem('conductoresVehiculos', JSON.stringify(conductoresVehiculos))
    state.conductoresVehiculos = conductoresVehiculos
  },
  setCantidadTiposVehiculosPorTipoServicio (state, cantidadTiposVehiculosPorTipoServicio) {
    localStorage.setItem('cantidadTiposVehiculosPorTipoServicio', JSON.stringify(cantidadTiposVehiculosPorTipoServicio))
    state.cantidadTiposVehiculosPorTipoServicio = cantidadTiposVehiculosPorTipoServicio
  },
  setConductorVehiculos (state, conductorVehiculos) {
    state.conductoresVehiculos.push(conductorVehiculos)
    localStorage.setItem('conductoresVehiculos', JSON.stringify(state.conductoresVehiculos))
  },
  setPreguntas (state, preguntas) {
    state.preguntas = preguntas
    localStorage.setItem('preguntas', JSON.stringify(state.preguntas))
  },
  updateConductorVehiculos (state, conductorVehiculos) {
    const currentConductorVehiculos = state.conductoresVehiculos.find(({ conductorId }) => conductorId === conductorVehiculos.conductorId)
    Object.assign(currentConductorVehiculos, conductorVehiculos)
    localStorage.setItem('conductoresVehiculos', JSON.stringify(state.conductoresVehiculos))
  },
  updateConductorVehiculosEnCompannia (state, payload) {
    const currentConductorVehiculos = state.conductoresVehiculos.find((conductor) => conductor.informacionBomberilId === payload.informacionBomberilId)
    currentConductorVehiculos.enCompanniaId = payload.enCompanniaId
    localStorage.setItem('conductoresVehiculos', JSON.stringify(state.conductoresVehiculos))
  },
  storeTono (state, tono) {
    state.tonos.push(tono)
    localStorage.setItem('tonos', JSON.stringify(state.tonos))
  },
  updateTono (state, tono) {
    const currentTono = state.tonos.find(({ tonoId }) => {
      return tonoId === tono.tonoId
    })
    Object.assign(currentTono, tono)
    localStorage.setItem('tonos', JSON.stringify(state.tonos))
  },
  setTonos (state, tonos) {
    state.tonos = tonos
    localStorage.setItem('tonos', JSON.stringify(state.tonos))
  },
  setTiposServicioTonos (state, tiposServicioCuerpoTonos) {
    state.tiposServicioCuerpoTonos = tiposServicioCuerpoTonos
    localStorage.setItem('tiposServicioCuerpoTonos', JSON.stringify(state.tiposServicioCuerpoTonos))
  },
  updateTiposServicioTonos (state, { tipoServicioCuerpoId, tonosIds }) {
    const tipoServicioCuerpoTonos = {
      tipoServicioCuerpoId,
      tonosIds
    }
    const currentTipoServicioCuerpoTonos = state.tiposServicioCuerpoTonos.find((tipoServicioCuerpoTonos) => {
      return tipoServicioCuerpoId === tipoServicioCuerpoTonos.tipoServicioCuerpoId
    })
    Object.assign(currentTipoServicioCuerpoTonos, tipoServicioCuerpoTonos)
    localStorage.setItem('tiposServicioCuerpoTonos', JSON.stringify(state.tiposServicioCuerpoTonos))
  },
  setCompanniasTonos (state, companniasTonos) {
    state.companniasTonos = companniasTonos
    localStorage.setItem('companniasTonos', JSON.stringify(state.companniasTonos))
  },
  updateCompanniasTonos (state, { companniaId, tonosIds }) {
    const companniaTonos = {
      companniaId,
      tonosIds
    }
    const currentCompanniaTonos = state.companniasTonos.find((companniaTonos) => {
      return companniaId === companniaTonos.companniaId
    })
    Object.assign(currentCompanniaTonos, companniaTonos)
    localStorage.setItem('companniasTonos', JSON.stringify(state.companniasTonos))
  },
  setGlobalesTonos (state, globalesTonos) {
    state.globalesTonos = globalesTonos
    localStorage.setItem('globalesTonos', JSON.stringify(state.globalesTonos))
  },
  updateGlobalesTonos (state, { globalTonoId, tonosIds }) {
    const globalTonos = {
      globalTonoId,
      tonosIds
    }
    const currentGlobalTonos = state.globalesTonos.find((globalTonos) => {
      return globalTonoId === globalTonos.globalTonoId
    })
    Object.assign(currentGlobalTonos, globalTonos)
    localStorage.setItem('globalesTonos', JSON.stringify(state.globalesTonos))
  },
  updateUbicacionVehiculo (state, { vehiculoId, lat, lng }) {
    console.log({ vehiculoId, lat, lng })
    state.vehiculos.forEach((vehiculo) => {
      if (vehiculo.vehiculoId === parseInt(vehiculoId)) {
        vehiculo.lat = parseFloat(lat)
        vehiculo.lng = parseFloat(lng)
      }
    })
    localStorage.setItem('vehiculos', JSON.stringify(state.vehiculos))
  }
}

const actions = {
  initWebSockets ({ state, commit, rootGetters }) {
    const cuerpoId = rootGetters['main/perfilActivo'].cuerpoId
    laravelEcho
      .private(`en.compannia.operador.cuerpo.${cuerpoId}`)
      .listen('BroadcastEnCompanniaUpdatedEvent', (e) => {
        const { enCompanniaId, informacionBomberilId } = e
        commit('updateConductorVehiculosEnCompannia', { informacionBomberilId, enCompanniaId })
      })
    laravelEcho
      .private(`vehiculo.ubicacion.cuerpo.${cuerpoId}`)
      .listen('.updatedUbicacion', (vehiculoUbicacion) => {
        commit('updateUbicacionVehiculo', vehiculoUbicacion)
      })
    laravelEcho
      .private(`despacho.cuerpo.${cuerpoId}`)
      .listen('.stored', ({ despacho }) => {
        const existsDespacho = state.despachos.some(({ despachoId }) => despacho.despachoId === despachoId)
        if (existsDespacho) return
        commit('storeDespacho', despacho)
      })
      .listen('.updated', ({ historialDespacho }) => {
        const { despachoId } = historialDespacho
        const currentStoredDespacho = state.despachos.find((despacho) => despachoId === despacho.despachoId)
        const storedDespacho = listenUpdateDespachoUtil(currentStoredDespacho, historialDespacho)
        commit('updateDespacho', storedDespacho)
      })
  },
  storeDespacho ({ state, commit, getters, rootGetters }, nuevoDespacho) {
    const fechaHoraInicio = new Date().toISOString()
    const despachoId = uuidv4()
    const cuerpoId = rootGetters['main/perfilActivo'].cuerpoId
    const despacho = {
      despachoId,
      ...nuevoDespacho,
      vehiculosDespacho: [],
      historialDespacho: [],
      fechaHoraInicio,
      controlada: false,
      cuerpoId,
      fechaHoraTermino: null
    }
    const tonosAtencion = getters.globalesTonosSonido.find((globalTonosSonido) => {
      return globalTonosSonido.globalTonoId === 1
    })
    if (tonosAtencion) {
      playSounds(tonosAtencion.tonos)
    }
    commit('storeDespacho', despacho)
    axios.post('/despacho', despacho)
      .then(({ data }) => {
        console.log(data)
      })
    return despachoId
  },
  getDespacho ({ state }, getDespachoId) {
    return JSON.parse(JSON.stringify(state.despachos.find(({ despachoId }) => getDespachoId === despachoId)))
  },
  updateDespacho ({ state, commit, rootState }, payload) {
    const despachoId = payload.despacho.despachoId
    const informacionBombrilId = rootState.main.informacionBomberilId
    const currentStoredDespacho = state.despachos.find((currentDespacho) => currentDespacho.despachoId === despachoId)
    const { storedDespacho, historialDespacho } = updateDespachoUtil(payload, currentStoredDespacho, informacionBombrilId)
    commit('updateDespacho', storedDespacho)
    return axios.put(`/despacho/${despachoId}`, historialDespacho)
      .then((response) => {
        return { ...response, storedDespacho }
      })
      .catch(() => {
        return { storedDespacho }
      })
  },
  getDespachos ({ state, commit, rootGetters }, force = true) {
    if (!force && state.tiposVehiculoBomberil.length > 0) return
    const params = {
      cuerpoId: rootGetters['main/perfilActivo'].cuerpoId
    }
    axios.get('/despacho', { params })
      .then(({ data }) => {
        const despachos = data.despachos.map((despacho) => {
          return {
            ...despacho,
            lat: parseFloat(despacho.lat),
            lng: parseFloat(despacho.lng)
          }
        })
        commit('setDespachos', despachos)
      })
  },
  getFuentesAgua ({ state, commit, rootGetters }, force = true) {
    if (!force && state.grifos.length > 0) return
    const params = {
      cuerpoId: rootGetters['main/perfilActivo'].cuerpoId
    }
    axios.get('/fuentes-agua', { params })
      .then(({ data }) => {
        commit('setGrifos', data.grifos)
        commit('setFuentesAgua', data.fuentesAgua)
      })
  },
  getTiposServicioCuerpo ({ state, commit, rootGetters }, force = true) {
    if (!force && state.tiposServicioCuerpo.length > 0) return
    const params = {
      cuerpoId: rootGetters['main/perfilActivo'].cuerpoId
    }
    axios.get('/tipos-servicio-cuerpo', { params })
      .then(({ data }) => {
        commit('setTiposServicioCuerpo', data.tiposServicioCuerpo)
      })
  },
  getVehiculos ({ state, commit, rootGetters }, force = true) {
    if (!force && state.vehiculos.length > 0) return
    const params = {
      cuerpoId: rootGetters['main/perfilActivo'].cuerpoId
    }
    axios.get('/vehiculos', { params })
      .then(({ data }) => {
        commit('setVehiculos', data.vehiculos)
      })
  },
  getTiposVehiculoBomberil ({ state, commit }, force = true) {
    if (!force && state.tiposVehiculoBomberil.length > 0) return
    axios.get('/tipos-vehiculo-bomberil')
      .then(({ data }) => {
        commit('setTiposVehiculoBomberil', data.tiposVehiculoBomberil)
      })
  },
  getConductoresVehiculos ({ state, commit }, force = true) {
    if (!force && state.conductoresVehiculos.length > 0) return
    axios.get('/admin-conductores')
      .then(({ data }) => {
        commit('setConductoresVehiculos', data.conductoresVehiculos)
      })
  },
  getInitInfo ({ dispatch }, force = true) {
    dispatch('getDespachos', force)
    dispatch('getTiposVehiculoBomberil', force)
    dispatch('getVehiculos', force)
    dispatch('getTiposServicioCuerpo', force)
    dispatch('getFuentesAgua', force)
    dispatch('getConductoresVehiculos', force)
    dispatch('getCantidadTiposVehiculosPorTipoServicio', force)
    dispatch('getPreguntasCuerpo', force)
    dispatch('getTonos', force)
    dispatch('getTiposServicioTonos', force)
    dispatch('getCompanniasTonos', force)
    dispatch('getGlobalesTonos', force)
  },
  storeConductorVehiculos ({ commit }, postData) {
    return axios.post('/admin-conductores', postData)
      .then((response) => {
        const conductorVehiculo = {
          conductorId: response.data.conductorId,
          ...postData
        }
        commit('setConductorVehiculos', conductorVehiculo)
        return response
      })
  },
  storePreguntasCuerpo ({ commit }, preguntas) {
    return axios.post('/admin-preguntas', { preguntas })
      .then((response) => {
        commit('setPreguntas', preguntas)
        response.data.preguntas = JSON.parse(JSON.stringify(preguntas))
        return response
      })
  },
  updateConductorVehiculos ({ commit }, data) {
    const putData = {
      vehiculosIds: data.vehiculosIds,
      activo: data.activo,
      enCompanniaId: data.enCompanniaId
    }
    return axios.put(`/admin-conductores/${data.conductorId}`, putData)
      .then((response) => {
        commit('updateConductorVehiculos', data)
        return response
      })
  },
  updateConductorVehiculosEnCompannia ({ commit }, data) {
    const putData = {
      enCompanniaId: data.enCompanniaId
    }
    return axios.put(`/admin-conductores/en-compannia/${data.conductorId}`, putData)
      .then((response) => {
        commit('updateConductorVehiculosEnCompannia', data)
        return response
      })
  },
  getCantidadTiposVehiculosPorTipoServicio ({ state, commit, rootGetters }, force = true) {
    if (!force && state.vehiculos.length > 0) return
    const params = {
      cuerpoId: rootGetters['main/perfilActivo'].cuerpoId
    }
    axios.get('/cantidad-tipos-vehiculos-tipos-servicio-cuerpo', { params })
      .then(({ data }) => {
        commit('setCantidadTiposVehiculosPorTipoServicio', data.cantidadTiposVehiculosPorTipoServicio)
      })
  },
  getPreguntasCuerpo ({ state, commit }, force = true) {
    if (!force && state.vehiculos.length > 0) return
    return axios.get('/admin-preguntas')
      .then((response) => {
        commit('setPreguntas', response.data.preguntas)
        return response
      })
  },
  updateCantidadTiposVehiculosPorTipoServicio ({ commit }, cantidadTiposVehiculosPorTipoServicio) {
    const putData = {
      cantidadTiposVehiculosPorTipoServicio
    }
    return axios.put('/cantidad-tipos-vehiculos-tipos-servicio-cuerpo', putData)
      .then((response) => {
        commit('setCantidadTiposVehiculosPorTipoServicio', response.data.cantidadTiposVehiculosPorTipoServicio)
        return response
      })
  },
  getTonos ({ state, commit }, force = true) {
    if (!force && state.tonos.length > 0) return
    axios.get('/admin-tonos')
      .then(({ data }) => {
        commit('setTonos', data.tonos)
      })
  },
  saveTono ({ commit, rootGetters }, tono) {
    const cuerpoId = rootGetters['main/perfilActivo'].cuerpoId
    const postData = {
      tono: {
        ...tono,
        cuerpoId
      }
    }
    if (tono.tonoId) {
      return axios.put(`/admin-tonos/${tono.tonoId}`, postData)
        .then((response) => {
          commit('updateTono', response.data.tono)
          return response
        })
    } else {
      return axios.post('/admin-tonos', postData)
        .then((response) => {
          commit('storeTono', response.data.tono)
          return response
        })
    }
  },
  getTiposServicioTonos ({ state, commit }, force = true) {
    if (!force && state.tiposServicioCuerpoTonos.length > 0) return
    return axios.get('/admin-tipo-servicio-cuerpo-tonos')
      .then((response) => {
        const { data } = response
        commit('setTiposServicioTonos', data.tiposServicioCuerpoTonos)
        return response
      })
  },
  updateTipoServicioTonos ({ state, commit }, payload) {
    const { tipoServicioCuerpoId, tonosIds } = payload
    return axios.put(`/admin-tipo-servicio-cuerpo-tonos/${tipoServicioCuerpoId}`, { tonosIds })
      .then((response) => {
        const { data } = response
        commit('updateTiposServicioTonos', data)
        return response
      })
  },
  getCompanniasTonos ({ state, commit }, force = true) {
    if (!force && state.companniasTonos.length > 0) return
    return axios.get('/admin-compannias-tonos')
      .then((response) => {
        const { data } = response
        commit('setCompanniasTonos', data.companniasTonos)
        return response
      })
  },
  updateCompanniaTonos ({ state, commit }, payload) {
    const { companniaId, tonosIds } = payload
    return axios.put(`/admin-compannias-tonos/${companniaId}`, { tonosIds })
      .then((response) => {
        const { data } = response
        commit('updateCompanniasTonos', data)
        return response
      })
  },
  getGlobalesTonos ({ state, commit }, force = true) {
    if (!force && state.globalesTonos.length > 0) return
    return axios.get('/admin-globales-tonos')
      .then((response) => {
        const { data } = response
        commit('setGlobalesTonos', data.globalesTonos)
        return response
      })
  },
  updateGlobalTonos ({ state, commit }, payload) {
    const { globalTonoId, tonosIds } = payload
    return axios.put(`/admin-globales-tonos/${globalTonoId}`, { tonosIds })
      .then((response) => {
        const { data } = response
        commit('updateGlobalesTonos', data)
        return response
      })
  }
}

const getters = {
  despachosActivosCuerpo (state, getters, rootState, rootGetters) {
    const curretCuerpoId = rootGetters['main/perfilActivo'].cuerpoId
    return state.despachos.filter(({ cuerpoId, fechaHoraTermino }) => cuerpoId === curretCuerpoId && fechaHoraTermino === null)
      .sort((a, b) => {
        return new Date(a.fechaHoraInicio).getTime() < new Date(b.fechaHoraInicio).getTime()
      })
  },
  tiposVehiculoBomberilCuerpo (state) {
    return state.tiposVehiculoBomberil.filter(({ tipoVehiculoBomberilId }) => {
      return state.vehiculos.some((vehiculo) => {
        return vehiculo.tipoVehiculoBomberilId === tipoVehiculoBomberilId
      })
    })
  },
  cantidadTiposVehiculosPorTipoServicioComplete (state, getters) {
    const cantidadTipoServicioTipoVehiculo = {}
    state.tiposServicioCuerpo.forEach(({ tipoServicioCuerpoId }) => {
      const cantidadTipoVehiculo = {}
      getters.tiposVehiculoBomberilCuerpo.forEach(({ tipoVehiculoBomberilId }) => {
        const cantidadObject = state.cantidadTiposVehiculosPorTipoServicio.find((cantidadTVPTS) => {
          return cantidadTVPTS.tipoServicioCuerpoId === tipoServicioCuerpoId &&
            cantidadTVPTS.tipoVehiculoBomberilId === tipoVehiculoBomberilId
        })
        cantidadTipoVehiculo[tipoVehiculoBomberilId] = cantidadObject ? cantidadObject.cantidad : 0
      })
      cantidadTipoServicioTipoVehiculo[tipoServicioCuerpoId] = cantidadTipoVehiculo
    })
    return cantidadTipoServicioTipoVehiculo
  },
  globalesTonosSonido (state) {
    return state.globalesTonos.reduce((acc, globalTono) => {
      if (globalTono.tonosIds.length > 0) {
        const tonos = globalTono.tonosIds.map((tonoId) => {
          return state.tonos.find((tono) => tono.tonoId === tonoId)
        })
        if (tonos.length > 0) {
          const globalTonosSonido = {
            ...globalTono,
            tonos
          }
          acc.push(globalTonosSonido)
        }
      }
      return acc
    }, [])
  }
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true
}
